import React, { useState } from 'react';
import PopOver from './PopOver';
import RetroButton from '../Form/RetroButton';
import { copyToClipboard } from '../../utils/utility';

const InvitePopOver = props => {
    const { boardURL, inviteCodes, closePopOver } = props;
    const [isTeam, setIsTeam] = useState(true);

    const copyBoardLink = () => {
        const urlPrefix = isTeam ? `/join?token=${inviteCodes.team}&isTeam=true` : `/join?token=${inviteCodes.guest}&isTeam=false`;
        copyToClipboard(boardURL + urlPrefix);
    };

    return <PopOver placement="bottom" closePopOver={closePopOver}>
        <div className="member-choice__row mt-10">
            <input
                id="team"
                type="radio"
                value="team"
                checked={isTeam}
                aria-label="join as team"
                onChange={() => setIsTeam(true)}
            />
            <label htmlFor="team">Join as Team Member</label>
            <p>Allow people to participate in this retrospective, join your team, and gain access to all of your team retrospectives data.</p>
        </div>
        <div className="member-choice__row">
            <input
                id="guest"
                type="radio"
                value="guest"
                checked={!isTeam}
                aria-label="join as guest"
                onChange={() => setIsTeam(false)}
            />
            <label htmlFor="guest">Join as Guest</label>
            <p>Guest won't have access to any other team retrospectives except this board.</p>
        </div>
        <div className="popOver__row">
            <RetroButton onClick={copyBoardLink} aria-label="copy invite link">
                Copy Invite Link
            </RetroButton>
        </div>
    </PopOver>;
};

export default InvitePopOver;