import React from 'react';
import FeatureBox from './FeatureBox';
import { FaUser } from 'react-icons/fa';
import { RiTeamFill } from 'react-icons/ri';
import RangeSlider from '../Form/RangeSlider';
import ToggleSwitch from '../Form/ToggleSwitch';
import RetroTextButton from '../Form/RetroTextButton';
import { getPlanCost, getFullPlanCost } from '../../utils/price';
import MultipleTeamDiscount from '../Pricing/MultipleTeamDiscount';

import '../../styles/subscriptionInfo.css';

const SubscriptionInfo = props => {
    const {
        isTeam,
        isAnnual,
        noOfTeams,
        setIsTeam,
        setNoOfTeams,
        togglePlanDuration
    } = props;

    return <div className="subscription-info__container" data-fade-in>
        <div className="plan-duration">
            <ToggleSwitch
                option2="Annual"
                option1="Monthly"
                spacing={false}
                value={isAnnual}
                switchKey="subscription-plan"
                onChange={togglePlanDuration}
            />
        </div>
        {isTeam && <MultipleTeamDiscount isAnnual={isAnnual} noOfTeams={noOfTeams} />}
        <div className="icon__container">
            {isTeam ? <RiTeamFill data-fade-in /> : <FaUser data-fade-in />}
        </div>
        <h2 className="plan__description">{isTeam ? 'Team Plan' : 'Single User Plan'}</h2>
        {isTeam && <div className="plan__team-size" data-fade-in>{`(${noOfTeams} Team${noOfTeams > 1 ? 's' : ''})`}</div>}
        <div className="plan__cost">
            {(isAnnual || noOfTeams > 2) && <div className="previous-price" data-fade-in>{getFullPlanCost({ isAnnual, isTeam, noOfTeams })}</div>}
            {`$${getPlanCost({ isAnnual, isTeam, noOfTeams })}`}
        </div>
        <div className="plan__term">{isAnnual ? '/ year' : '/ month'}</div>
        {isTeam && <div className="plan__team-count" data-fade-in>
            <RangeSlider start={1} end={10} step={1} value={noOfTeams} onChange={e => setNoOfTeams(e.target.value)} />
        </div>}
        <FeatureBox isTeam={isTeam} />
        <RetroTextButton
            aria-label="switch to team"
            onClick={() => setIsTeam(!isTeam)}
        >
            {`Switch to ${isTeam ? 'Single User' : 'Teams'}`}
        </RetroTextButton>
    </div>;
};

export default SubscriptionInfo;