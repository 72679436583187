import React from 'react';
import { FaCheckCircle, FaStar } from 'react-icons/fa';

const FeatureBox = props => {
    const { isTeam, showPlan, planName, isComparison } = props;
    return <div className="plan__features--container">
        <h2>{showPlan ? planName : 'Features'}</h2>
        <hr className="feature__divider" />
        <ul>
            <li><FaCheckCircle /> Unlimited Retro Boards</li>
            {isTeam && <li data-fade-in>{isComparison ? <FaStar className="star" /> : <FaCheckCircle />} Unlimited Team Members</li>}
            {isTeam && <li data-fade-in>{isComparison ? <FaStar className="star" /> : <FaCheckCircle />}  Real-time Collaboration</li>}
            <li><FaCheckCircle /> Dot Voting</li>
            <li><FaCheckCircle /> Action Plans</li>
            {/* <li><FaCheckCircle /> Custom Templates</li> */}
            {isTeam && <li>{isComparison ? <FaStar className="star" /> : <FaCheckCircle />}  Team Appreciations</li>}
            <li><FaCheckCircle /> Idea Grouping</li>
            {/* <li><FaCheckCircle /> Slack Integration</li> */}
            <li><FaCheckCircle /> Guided Facilitation</li>
        </ul>
    </div>
};

export default FeatureBox;