import React, { useEffect, useRef, useState } from 'react';
import Card from './Card';
import map from 'lodash/map';
import get from 'lodash/get';
import VoteDisplay from './VoteDisplay';
import MergedCards from './MergedCards';
import EndOfRetroCard from './EndOfRetroCard';
import { sync } from '../../services/firebase';
import { onValue, ref } from 'firebase/database';
import { HiViewGridAdd } from 'react-icons/hi';
import { GET } from '../../services/HTTPHandler';
import AppreciationCard from './AppreciationCard';
import CardGroupStatusBar from './CardGroupStatusBar';
import ActionItemSideDrawer from '../SideDrawer/ActionItemSideDrawer';
import '../../styles/discuss.css';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

const Discuss = props => {
    const {
        members,
        boardID,
        isHidden,
        teamInfo,
        isLastStep,
        actionItems,
        activeIndex,
        setAllTopics,
        setEndOfRetro,
        votingReEnabled,
        setCompletedVotes,
        setVotingReEnabled,
        setDiscussionPercentDivision
    } = props;

    const buttonRef = useRef(null);

    const [userVotes, setUserVotes] = useState([]);
    const [sortedCards, setSortedCards] = useState([]);
    const [totalCardsCount, setTotalCardsCount] = useState(0);
    const [appreciationCards, setAppreciationCards] = useState([]);
    const [componentHydrated, setComponentHydrated] = useState(false);
    const [showActionItemsDrawer, setShowActionItemsDrawer] = useState(false);

    const getCards = appreciations => {
        return map(appreciations, (cards, forUser) => ({ cards, forUser }));
    };

    const getIndividualVotes = cards => {
        return map(cards, card => get(card, 'vote.voteFromAllUsers', 0));
    };

    useEffect(() => {
        if (!isHidden && (!componentHydrated || votingReEnabled)) {
            GET('/activity/discuss', { boardID })
                .then(response => {
                    const payload = get(response, ['data'], {});
                    setSortedCards(payload.sortedCards);
                    setUserVotes(getIndividualVotes(payload.sortedCards));
                    setAllTopics(payload.totalCardsCount);
                    setTotalCardsCount(payload.totalCardsCount);
                    setAppreciationCards(payload.appreciationCards);
                    setDiscussionPercentDivision(40 / payload.totalCardsCount);
                    setVotingReEnabled(false);
                    setComponentHydrated(true);
                })
                .catch(err => console.log(err));
            onValue(ref(sync, `boards/${boardID}/columns/4/cards`), data => {
                if (data.val())
                    setAppreciationCards(getCards(data.val()));
            });
        }
    }, [isHidden]);

    useEffect(() => {
        activeIndex === totalCardsCount && isLastStep ? setEndOfRetro(true) : setEndOfRetro(false);
    });

    useEffect(() => {
        if (userVotes.length)
            setCompletedVotes(userVotes.slice(0, activeIndex).reduce(reducer));
    }, [activeIndex]);

    useEffect(() => {
        if (!showActionItemsDrawer)
            buttonRef.current.blur();
    }, [showActionItemsDrawer]);

    const getStatusBarPercent = (statusBar, type) => {
        if (statusBar) {
            const sum = statusBar.reduce((a, b) => a + b, 0);
            const percent = {
                0: (statusBar[0] / sum) * 100,
                1: (statusBar[1] / sum) * 100,
                2: (statusBar[2] / sum) * 100,
                3: (statusBar[3] / sum) * 100,
            };
            switch (type) {
                case 'success': return percent[0];
                case 'warning': return percent[0] + percent[1];
                case 'error': return percent[0] + percent[1] + percent[2];
                default: return;
            }
        }
    };

    return (
        <div className="discuss__container" data-hide={isHidden} data-fade-in>
            <div className="card-group__staging-area">
                <div className="card-group__rotation-area">
                    {map(sortedCards, (card, i) =>
                        <div
                            key={i}
                            className="card-group__conatiner"
                            aria-hidden={i !== activeIndex}
                        >
                            <div className="card-group__title">
                                {card.groupTitle}
                            </div>
                            <CardGroupStatusBar
                                success={getStatusBarPercent(card.statusBar, 'success')}
                                warning={getStatusBarPercent(card.statusBar, 'warning')}
                                error={getStatusBarPercent(card.statusBar, 'error')}
                            />
                            <VoteDisplay voteCount={get(card, 'vote.voteFromAllUsers', 0)} />
                            {!card.mergedCards ? <Card
                                key={card.timestamp}
                                boardID={boardID}
                                members={members}
                                draggable={false}
                                votingMode={false}
                                author={card.owner}
                                isMergedCard={false}
                                content={card.content}
                                maxVoteReached={false}
                                cardTheme={card.cardTheme}
                            /> : <MergedCards
                                boardID={boardID}
                                draggable={false}
                                members={members}
                                key={card.baseKey}
                                votingMode={false}
                                discussMode={true}
                                isMergedCard={true}
                                author={card.owner}
                                cards={card.children}
                                expandOverride={true}
                                maxVoteReached={false}
                                baseKey={card.baseKey}
                            />}
                        </div>
                    )}
                    {map(appreciationCards, (userCards, i) =>
                        <div
                            key={i}
                            className="card-group__conatiner"
                            aria-hidden={i !== activeIndex % sortedCards.length}
                        >
                            <AppreciationCard
                                isDiscussion
                                boardID={boardID}
                                members={members}
                                userCards={userCards.cards}
                                key={Object.keys(userCards.cards || {})[0]}
                                forUser={members[userCards.forUser] || {}}
                            />
                        </div>
                    )}
                    {totalCardsCount === activeIndex &&
                        <EndOfRetroCard isHidden={totalCardsCount === activeIndex} />
                    }
                </div>
            </div>
            <div className="action-item__sidebar">
                <button
                    ref={buttonRef}
                    data-tooltip-content="Add Action Item"
                    className="addActionItem tooltip expand"
                    onClick={() => setShowActionItemsDrawer(!showActionItemsDrawer)}
                >
                    <HiViewGridAdd />
                </button>
            </div>
            <ActionItemSideDrawer
                members={members}
                boardID={boardID}
                teamInfo={teamInfo}
                actionItems={actionItems}
                active={showActionItemsDrawer}
                setShowActionItemsDrawer={setShowActionItemsDrawer}
            />
        </div>
    );
};

export default Discuss;