import React from 'react';
import classNames from 'classnames';
import { CgClose } from 'react-icons/cg';

import '../../styles/sideDrawer.css';

const SideDrawer = props => {
    const { active, title, containerClickHandler, children } = props;
    const drawerClasses = classNames('side-drawer', { 'active': active });

    return <>
        <div
            data-hide={!active}
            onClick={containerClickHandler}
            className="side-drawer__container"
        />
        <div className={drawerClasses}>
            <button className="size-drawer__close" onClick={containerClickHandler}>
                <CgClose />
            </button>
            <h2 aria-label={title}>{title}</h2>
            <hr className="side-drawer__divider" />
            <div className="side-drawer__content">
                {children}
            </div>
        </div>
    </>;
};

export default SideDrawer;