import get from 'lodash/get';
import { FiCheck } from 'react-icons/fi';
import { TiCancel } from 'react-icons/ti';
import { getFirstName } from '../../utils/user';
import { POST } from '../../services/HTTPHandler';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FcCancel, FcCheckmark } from 'react-icons/fc';

const NotificationsPopOver = props => {
    const { notifications, closePopOver } = props;
    const isEmpty = Object.entries(notifications).length === 0;

    const accessControl = (notification, options) => {
        POST('/board/accessControl', { notification, options });
    };

    const getNotificationMessage = notification => {
        if (!get(notification, ['resolution', 'resolved'], false)) {
            return `${getFirstName(get(notification, ['user', 'displayName']), '')} is requesting access to ${get(notification, ['for', 'name'], '')}`;
        }
        switch (get(notification, ['resolution', 'action'], '')) {
            case 'deny': return `${getFirstName(get(notification, ['user', 'displayName']), '')} has been denied access to ${get(notification, ['for', 'name'], '')}`;
            case 'grant': return `${getFirstName(get(notification, ['user', 'displayName']), '')} has been granted access to ${get(notification, ['for', 'name'], '')}`;
            case 'addToTeam': return `${getFirstName(get(notification, ['user', 'displayName']), '')} has been added as a Team member`;
            default: return;
        }
    }

    return <ul>
        {!isEmpty ? Object.entries(notifications).map(([key, notification]) => (
            <li className="popOver__row" key={key}>
                <div className="notifications__from">
                    <img src={get(notification, ['user', 'photoURL'], '')} alt="User" />
                </div>
                <div className="notifications__message">
                    {getNotificationMessage(notification)}
                </div>
                <div className="notifications__actions">
                    <button
                        className="tooltip expand"
                        data-tooltip-content="Add To Team"
                        disabled={get(notification, ['resolution', 'resolved'], false)}
                        onClick={() => accessControl(notification, { action: 'addToTeam', id: key })}
                    >
                        <AiOutlineUsergroupAdd />
                    </button>
                    <button
                        className="tooltip expand"
                        data-tooltip-content="Grant Board Access"
                        disabled={get(notification, ['resolution', 'resolved'], false)}
                        onClick={() => accessControl(notification, { action: 'grant', id: key })}
                    >
                        {get(notification, ['resolution', 'resolved'], false) ? <FiCheck /> : <FcCheckmark />}
                    </button>
                    <button
                        className="tooltip expand"
                        data-tooltip-content="Deny Access"
                        disabled={get(notification, ['resolution', 'resolved'], false)}
                        onClick={() => accessControl(notification, { action: 'deny', id: key })}
                    >
                        {get(notification, ['resolution', 'resolved'], false) ? <TiCancel /> : <FcCancel />}
                    </button>
                </div>
            </li>
        )) : <div className="empty-notifications__wrapper">
            <img
                alt="Empty Notifications"
                className="empty-notifications"
                src="https://raw.githubusercontent.com/arvindrk/my-retro/master/empty.gif"
            />
            <div className="empty-notification__text">
                You are all caught up!
            </div>
        </div>}
    </ul>;
};

export default NotificationsPopOver;