import get from 'lodash/get';
import alertify from 'alertifyjs';
import React, { useState } from 'react';
import ToggleSwitch from '../Form/ToggleSwitch';
import { POST } from '../../services/HTTPHandler';

import '../../styles/actionItemRow.css';

const ActionItemRow = props => {
    const { item, isEndOfRetro, setBoardUpdatedAfterCompletion } = props;

    const [isComplete, setIsComplete] = useState(item.markAsComplete);

    const toggleActionItem = key => {
        POST('/actionItem/markAsComplete', { item, key, isComplete: !isComplete })
            .then(() => {
                if (isEndOfRetro)
                    setBoardUpdatedAfterCompletion(true);
                setIsComplete(!isComplete);
            }).catch(err => alertify.notify('Failed to update Vote', 'error', 3, () => console.log(err.message)));
    };

    return <div className="action-item__row" data-fade-in>
        <div className="action-item__info">
            <img src={get(item, ['assignTo', 'photoURL'], '')} alt="profile" />
            <div className="action-item__info-display">
                <span>{item.actionItem}</span>
                <span className="action-item__belongs-to">{get(item, ['assignTo', 'name'])}</span>
            </div>
        </div>
        <ToggleSwitch
            option2="Done"
            option1="To do"
            spacing={true}
            value={isComplete}
            onChange={toggleActionItem}
            switchKey={item.actionItemKey}
        />
    </div>;
};

export default ActionItemRow;