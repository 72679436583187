import React from 'react';
// import times from 'lodash/times';
import Template from '../Dashboard/Template';
// import ColumnInput from '../Form/ColumnInput';
import './columnForm.css';

const ColumnForm = props => {
	const { showForm, isDefault, defaultTemplate } = props;
	// const [columnCount, setColumnCount] = useState(1);

	return <>
		<form>
			{showForm &&
				<>
					{/* {times(columnCount, () => <ColumnInput />)}
					<div className="input-row">
						<Checkbox
							value={hasAppreciationsColumn}
							onChange={setHasAppreciationsColumn}
							aria-label="Include Appreciations Column"
						/>
					</div>
					<div className="add-column">
						<button onClick={() => setColumnCount(columnCount + 1)}>Add Column</button>
					</div> */}
					<div className="coming-soon__container">
						<p>More Templates and Create your own template feature coming soon!</p>
					</div>
				</>
			}
		</form>
		{isDefault && <Template template={defaultTemplate} />}
	</>
};

export default ColumnForm;