import classNames from 'classnames';
import get from 'lodash/get';
import { ImSad } from 'react-icons/im';
import React, { useState } from 'react';
import Link from '../components/Form/Link';
import { POST } from '../services/HTTPHandler';
import Navbar from '../components/Navigation/Navbar';
import RetroButton from '../components/Form/RetroButton';
import FormDropdown from '../components/Form/FormDropdown';
import BorderlessInput from '../components/Form/BorderlessInput';

import '../styles/cancel.css';

const feedbackReasons = ['Too Expensive', 'Missing Functionality', 'I no longer use this product', 'Using other product', 'Other'];

const Cancel = () => {
    const [reason, setReason] = useState('');
    const [interval, setInterval] = useState('');
    const [cancelStep, setCancelStep] = useState(0);
    const [dropdownError, setDropdownError] = useState(false);
    const [additionalFeedback, setAdditionalFeedback] = useState('');
    const [subscriptionEndTimeStamp, setSubscriptionEndTimeStamp] = useState(0);
    const [additionalFeedbackError, setAdditionalFeedbackError] = useState(false);

    const getDropdownOptions = options => options.map((option, i) => ({ label: option, value: i }));

    const feedbackCancelHandler = () => {
        if (reason === '') {
            setDropdownError(true);
            return;
        }
        if (reason >= 3 && additionalFeedback === '') {
            setAdditionalFeedbackError(true);
            return;
        }
        POST('/cancel/feedback', { reason: feedbackReasons[reason], additionalFeedback })
            .then((res) => {
                setInterval(get(res, ['data', 'interval'], ''));
                setSubscriptionEndTimeStamp(get(res, ['data', 'subscriptionEndTimeStamp'], ''));
                get(res, ['data', 'userStatus', 'exitDiscountAccepted'], false) ? setCancelStep(3) : setCancelStep(1);
            })
            .catch(err => console.log(err));
    };

    const resetForm = () => {
        setDropdownError(false);
        setAdditionalFeedbackError(false);
    };

    const confirmCancellation = cancelNow => {
        POST('/cancel/confirm', { cancelNow })
            .then(() => setCancelStep(4))
            .catch(err => console.log(err));
    };

    const acceptDiscount = () => {
        POST('/cancel/acceptDiscount')
            .then(() => setCancelStep(2))
            .catch(err => console.log(err));
    };

    return <>
        <Navbar redirectLogoToDashboard />
        <div className="cancel__container">
            <div className="card__wrapper side__padding" data-hide={cancelStep !== 0} data-fade-in>
                <h2>Please tell us why</h2>
                <FormDropdown
                    onFocus={resetForm}
                    selectedOption={reason}
                    aria-label="feedback dropdown"
                    label="--Reason for Cancellation--"
                    options={getDropdownOptions(feedbackReasons)}
                    onDropdownChange={e => setReason(e.target.value)}
                />
                <div className="wrap-input2 mt-20">
                    <BorderlessInput
                        className={classNames('input2', 'textarea-theme', 'actionItemInput', {
                            'has-val': additionalFeedback !== ''
                        })}
                        onFocus={resetForm}
                        value={additionalFeedback}
                        aria-label="additional feedback"
                        setValue={setAdditionalFeedback}
                        placeholder="Enter additional feedback"
                        handleSubmit={() => (1)}
                    />
                </div>
                {(dropdownError || additionalFeedbackError) && <div className="row">
                    <span className="error">{additionalFeedbackError ? 'Please enter other reason' : 'Please choose a reason'}</span>
                </div>}
                <div className="row space-evenly">
                    <Link
                        linkAsButton
                        href="/dashboard"
                        aria-label="Keep your subscription"
                    >
                        Keep my subscription
                    </Link>
                    <RetroButton
                        theme="danger"
                        aria-label="cancel subscription"
                        onClick={feedbackCancelHandler}
                    >
                        Cancel Subscription
                    </RetroButton>
                </div>
            </div>
            <div className="card__wrapper side__padding" data-hide={cancelStep !== 1} data-fade-in>
                <h2>Special One-time Offer</h2>
                <div className="row">
                    {interval === 'month' ?
                        <div className="discount__container">
                            <div className="discount__banner">Save 50%</div>
                            <div>Get 50% off on your next four invoices.</div>
                        </div> :
                        <div className="discount__container">
                            <div className="discount__banner">Save 20%</div>
                            <div>Get 2 months free on your annual plan.</div>
                        </div>
                    }
                </div>
                <div className="row space-evenly">
                    <RetroButton
                        aria-label="cancel subscription"
                        onClick={() => setCancelStep(3)}
                    >
                        Cancel Subscription
                    </RetroButton>
                    <RetroButton
                        theme="blue"
                        aria-label="cancel subscription"
                        onClick={acceptDiscount}
                    >
                        Accept Discount
                    </RetroButton>
                </div>
            </div>
            <div className="card__wrapper side__padding" data-hide={cancelStep !== 2} data-fade-in>
                <h2>Discount Applied</h2>
                <div className="row">
                    {interval === 'month' ? 'Congratulations! You will only be charged 50% of your subscription value for the next 4 months.' : 'Congratulations! You recieved 2 months subscription for FREE!'}
                </div>
                <div className="row">
                    <Link
                        theme="blue"
                        linkAsButton
                        href="/dashboard"
                        aria-label="navigate to dashboard"
                    >
                        Go to Dashboard
                    </Link>
                </div>
            </div>
            <div className="card__wrapper" data-hide={cancelStep !== 3} data-fade-in>
                <h2>Cancellation Type</h2>
                <div className="row">
                    Do you want to cancel at the end of you subscription cycle or immediately?
                </div>
                <div className="row space-evenly">
                    <RetroButton
                        aria-label="cancel subscription immediately"
                        onClick={() => confirmCancellation(true)}
                    >
                        Cancel Now
                    </RetroButton>
                    <RetroButton
                        size="xl"
                        aria-label="cancel subscription"
                        onClick={() => confirmCancellation(false)}
                    >
                        Cancel at {new Date(subscriptionEndTimeStamp * 1000).toDateString()}
                    </RetroButton>
                    <Link
                        theme="blue"
                        linkAsButton
                        href="/dashboard"
                        className="cancel__reverse"
                        aria-label="navigate to dashboard"
                    >
                        Keep Subscription
                    </Link>
                </div>
            </div>
            <div className="card__wrapper side__padding" data-hide={cancelStep !== 4} data-fade-in>
                <h2>Order Cancelled</h2>
                <ImSad className="font-14" />
                <div className="row">
                    We are sorry to see your go!
                </div>
                <div className="row">
                    <Link
                        theme="blue"
                        linkAsButton
                        href="/dashboard"
                        aria-label="navigate to dashboard"
                    >
                        Go to Dashboard
                    </Link>
                </div>
            </div>
        </div>
    </>;
};

export default Cancel;