import React, { useEffect, useState } from 'react';
import Navbar from './Navigation/Navbar';
import RetroButton from './Form/RetroButton';
import { GET, POST } from '../services/HTTPHandler';

const UnAuthorizedUser = props => {
	const { boardID } = props;

	const [requested, setRequested] = useState(false);

	useEffect(() => {
		GET('/unauthorized/requestStatus', { boardID })
			.then(res => setRequested(res.data));
	}, []);

	const requestAccess = () => {
		POST('/unauthorized/requestAccess', { boardID })
			.then(() => setRequested(true))
			.catch(err => console.log(err));
	};

	return <>
		<Navbar redirectLogoToDashboard />
		<div className="unauthorized__container">
			<div className="unauthorized__staging">
				<img src="https://i.pinimg.com/originals/ac/06/47/ac064781d562d0963f62ab456c0f2f01.png" alt="Unauthorized User" />
				<div className="request-access">
					{requested && <span>Access Requested, board owner notified!</span>}
					<RetroButton
						className="mg-25"
						disabled={requested}
						onClick={requestAccess}
						aria-label="Request Board Access"
					>
						{!requested ? 'Request Access' : 'Requested'}
					</RetroButton>
				</div>
			</div>
		</div>
	</>
};

export default UnAuthorizedUser;