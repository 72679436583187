import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import alertify from 'alertifyjs';
import { BiLink } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { Navigate } from 'react-router-dom';
import AlertModal from '../Modals/AlertModal';
import { POST } from '../../services/HTTPHandler';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { getProfilePicture } from '../../utils/user';
import { copyToClipboard } from '../../utils/utility';
import CreateBoardModal from '../Modals/CreateBoardModal';

import '../../styles/retroCollection.css';

const RetroCollection = (props) => {
    const {
        title,
        boards,
        teamID,
        onSubmit,
        teamRoom,
        teamName,
        boardEmpty,
        errorStatus,
        redirectLink,
        displayModal,
        boardToDelete,
        teamsDropdown,
        setErrorStatus,
        userStatus = {},
        setDisplayModal,
        defaultTemplate,
        onSuccessLoader,
        setBoardToDelete,
        redirectOnSubmit,
        removeBoardFromDash,
        getTeamMembersCount,
    } = props;

    const copyBoardLink = boardURL => {
        copyToClipboard(boardURL);
    };

    const [showDeleteBoardModal, setShowDeleteBoardModal] = useState(false);

    const deleteIconHandler = boardId => {
        setBoardToDelete(boardId);
        setShowDeleteBoardModal(true);
    };

    const deleteBoard = () => {
        POST('/board/delete', { boardID: boardToDelete })
            .then(() => {
                alertify.notify('Board Deleted', 'error', 3);
                setShowDeleteBoardModal(false);
                setBoardToDelete('');
                removeBoardFromDash(boardToDelete);
            });
    };

    useEffect(() => {
        if (!showDeleteBoardModal)
            setBoardToDelete('');
    }, [showDeleteBoardModal]);

    return (
        redirectOnSubmit ? <Navigate to={redirectLink} replace /> :
            <>
                <div className="dashboard-title">
                    {title}
                    <button
                        aria-label="create boards"
                        data-tooltip-content="Create Board"
                        onClick={() => setDisplayModal(true)}
                        className="dashboard-title__create tooltip expand tooltip-right"
                    >
                        <AiOutlinePlusCircle />
                    </button>
                    {boardEmpty  && <span className="first-board__notification">{"<--- Create new board here"}</span>}
                    {/* {(!boardEmpty && Object.keys(boards).length >= 4) &&
                        <Link
                            href="/boards/all"
                            aria-label="all boards"
                            className="dashboard-title__see-all"
                        >
                            See all
                        </Link>} */}
                </div>
                <div className="boards-container">
                    {!boardEmpty ? map(boards, boardWithID => {
                        const board = get(boardWithID, ['boardData', 'board'], {});
                        const belongsTo = get(boardWithID, ['boardData', 'belongsTo'], '');
                        const membersCount = getTeamMembersCount(belongsTo);
                        const boardOwner = get(boardWithID, ['boardData', 'owner'], '');
                        const boardTeamIcon = getProfilePicture(boardOwner.teamName, '');
                        const { completedActionItems = 0, totalActionItems = 0 } = get(boardWithID, 'boardData.actionItemsInfo', {});
                        const progressPercent = (completedActionItems / totalActionItems) * 100;
                        return (<div key={board.createdOn} className="board__card">
                            <a href={"/retro/" + board.boardID}>
                                <div className="board__image">
                                    <div className="board__image--title">
                                        <h5>{board.name}</h5>
                                        <div className="action-progress action-progress--hover">
                                            <div className="action-progress--fill" style={{ width: progressPercent + '%' }} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="board__description">
                                <h5> {board.name} </h5>
                                <p> Action Items Completed  - {completedActionItems}/{totalActionItems} </p>
                                <div className="action-progress"><div className="action-progress--fill" style={{ width: progressPercent + '%' }} /> </div>
                                <p> Created on {new Date(board.createdOn).toDateString()} </p>
                            </div>
                            <div className="board__icons--container">
                                <button
                                    aria-label="team members count"
                                    className="board__icons tooltip expand"
                                    data-tooltip-content={`${membersCount} Member${membersCount > 1 ? 's' : ''}`}
                                >
                                    <FiUsers />
                                </button>
                                <button
                                    aria-label="copy board link"
                                    data-tooltip-content="Copy Board URL"
                                    onClick={() => copyBoardLink(board.boardURL)}
                                    className="board__icons tooltip expand tooltip-top"
                                >
                                    <BiLink />
                                </button>
                                <button
                                    aria-label="delete board"
                                    data-tooltip-content="Delete Board"
                                    onClick={() => deleteIconHandler(board.boardID)}
                                    className="board__icons tooltip expand tooltip-top"
                                >
                                    <MdDelete />
                                </button>
                                <div className="board__icons board__icons--delete">
                                    <img src={boardTeamIcon} alt="user profile" />
                                </div>
                            </div>
                        </div>
                        );
                    }) :
                        <div className="board__empty--wrapper">
                            <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/EmptyBoards.webp" alt="empty boards" />
                        </div>
                    }
                </div>
                <CreateBoardModal
                    teamID={teamID}
                    onSubmit={onSubmit}
                    teamRoom={teamRoom}
                    teamName={teamName}
                    errorStatus={errorStatus}
                    displayModal={displayModal}
                    teamsDropdown={teamsDropdown}
                    setErrorStatus={setErrorStatus}
                    onSuccessLoader={onSuccessLoader}
                    defaultTemplate={defaultTemplate}
                    closeModal={() => setDisplayModal(!displayModal)}
                    isTeamMaxedOut={userStatus.ownedTeamCount >= userStatus.allowedTeamCount}
                />
                <AlertModal
                    title="Delete Board"
                    successTheme="danger"
                    successMessage="Delete"
                    failureMessage="Cancel"
                    successHandler={deleteBoard}
                    displayModal={showDeleteBoardModal}
                    failureButtonAriaLabel="cancel and close modal"
                    successButtonAriaLabel="confirm board deletion"
                    closeModal={() => setShowDeleteBoardModal(false)}
                    failureHandler={() => setShowDeleteBoardModal(false)}
                    message="You're about to delete a retrospective and all comments, votes and action items created. Are you sure you wish to continue?"
                />
            </>
    );
};

export default RetroCollection;