import React from 'react';
import { FaUserClock } from 'react-icons/fa';
import FeatureBox from '../Checkout/FeatureBox';
import RetroButton from '../Form/RetroButton';

const FreeTrialContainer = props => {
    const { closeModal } = props;
    return <div className="free-trial__container">
        <div className="icon__container">
            <FaUserClock data-fade-in />
        </div>
        <h2 className="plan__description">Free Trial</h2>
        <div className="plan__cost">
            $0
        </div>
        <p className="plan__term">For the first 14 days</p>
        <FeatureBox isTeam />
        {closeModal && <RetroButton
            onClick={closeModal}
            aria-label="Continue Trial"
        >
            Continue Trial
        </RetroButton>}
    </div>;
};

export default FreeTrialContainer;
