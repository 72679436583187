import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { GET } from '../services/HTTPHandler';
import Navbar from '../components/Navigation/Navbar';
import UserUpdate from '../components/Settings/UserUpdate';
import SettingsSidebar from '../components/Settings/SettingsSidebar';
// import SlackUpdate from '../components/settings/SlackUpdate';
import PasswordUpdate from '../components/Settings/PasswordUpdate';
import ManageSubscription from '../components/Settings/ManageSubscription';

const Settings = () => {
    const { type: settingsType } = useParams();
    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [currentSetting, setCurrentSetting] = useState(settingsType || 'user');

    useEffect(() => {
        getLatestSubscriptionData();
        document.title = 'Settings - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
        document.body.style.backgroundColor = '#fafafa';
    }, []);

    const getLatestSubscriptionData = () => {
        GET('/settings/subscription')
            .then(res => {
                setSubscriptionInfo(res.data);
                setIsPaymentLoading(false);
            })
            .catch(err => console.log(err));
    };

    return <>
        <Navbar redirectLogoToDashboard isSettingsPage={true} />
        <SettingsSidebar
            currentSetting={currentSetting}
            setCurrentSetting={setCurrentSetting}
        />
        {currentSetting === 'user' && <UserUpdate />}
        {/* {currentSetting === 'slack' && <SlackUpdate />} */}
        {currentSetting === 'password' && <PasswordUpdate />}
        {currentSetting === 'subscription' &&
            <ManageSubscription
                plan={subscriptionInfo.plan}
                card={subscriptionInfo.card}
                isPaymentLoading={isPaymentLoading}
                setIsPaymentLoading={setIsPaymentLoading}
                entitlements={subscriptionInfo.entitlements}
                hostedInvoiceURL={subscriptionInfo.hostedInvoiceURL}
                getLatestSubscriptionData={getLatestSubscriptionData}
            />
        }
    </>;
};

export default Settings;