import alertify from "alertifyjs";

const BASE_URL = 'https://retrospective.pro';

export const copyToClipboard = textToCopy => {
    navigator.clipboard.writeText(BASE_URL + textToCopy).then(function () {
        alertify.notify('Link Copied', 'success', 5, function () { console.log('Text copied: ', BASE_URL + textToCopy) });
    }, function (error) {
        alertify.notify('Failed to Copy', 'error', 3, function () { console.log(error) });
    });
};