import { useContext, useEffect, useReducer, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import alertify from 'alertifyjs';
import Loader from '../components/Loader';
import { sync } from "../services/firebase";
import { onValue, ref } from 'firebase/database';
import Teams from '../components/Dashboard/Teams';
import { GET, POST } from '../services/HTTPHandler';
import PricingModal from '../components/Modals/PricingModal';
import ActionItems from '../components/Dashboard/ActionItems';
import Appreciations from '../components/Dashboard/Appreciations';
import LandingNavbar from '../components/Navigation/LandingNavbar';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import RetroCollection from '../components/Dashboard/RetroCollection';

import '../styles/dashboard.css';

const getDefaultState = () => ({
	teams: {},
	boards: {},
	userStatus: {},
	actionItems: [],
	appreciations: {},
	teamsDropdown: [],
	defaultTemplate: {},
});

const getNewTeamOption = team => ({ label: team.name, value: team.teamID });

const reducer = (state, action) => {
	switch (action.type) {
		case 'hydrate':
			return {
				teams: action.payload.teams,
				boards: action.payload.boards,
				userStatus: action.payload.userStatus,
				actionItems: action.payload.actionItems,
				appreciations: action.payload.appreciations,
				teamsDropdown: action.payload.teamsDropdown,
				defaultTemplate: action.payload.defaultTemplate,
			};
		case 'updateTeams':
			return {
				boards: state.boards,
				actionItems: state.actionItems,
				appreciations: state.appreciations,
				userStatus: action.payload.userStatus,
				defaultTemplate: state.defaultTemplate,
				teams: { ...state.teams, [action.payload.teamID]: action.payload.team },
				teamsDropdown: [...state.teamsDropdown, getNewTeamOption(get(action, 'payload.team.teamInfo', {}))],
			};
		case 'createTeamBoard':
			return {
				actionItems: state.actionItems,
				appreciations: state.appreciations,
				userStatus: action.payload.userStatus,
				defaultTemplate: state.defaultTemplate,
				teams: { ...state.teams, [action.payload.teamID]: action.payload.team },
				boards: { ...state.boards, [action.payload.boardID]: action.payload.board },
				teamsDropdown: [...state.teamsDropdown, getNewTeamOption(get(action, 'payload.team.teamInfo', {}))],
			};
		case 'createBoard':
			return {
				teams: state.teams,
				userStatus: state.userStatus,
				actionItems: state.actionItems,
				appreciations: state.appreciations,
				teamsDropdown: state.teamsDropdown,
				defaultTemplate: state.defaultTemplate,
				boards: { ...state.boards, [action.payload.boardID]: action.payload.board },
			};
		case 'deleteBoard':
			return {
				teams: state.teams,
				userStatus: state.userStatus,
				boards: { ...action.payload },
				actionItems: state.actionItems,
				appreciations: state.appreciations,
				teamsDropdown: state.teamsDropdown,
				defaultTemplate: state.defaultTemplate,
			}
		default: throw new Error();
	}
};

const Dashboard = () => {
	document.title = 'Dashboard - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
	const user = useContext(CurrentUserContext);
	const [state, dispatch] = useReducer(reducer, getDefaultState());

	const [isLoading, setIsLoading] = useState(true);
	const [redirectLink, setRedirectLink] = useState('');
	const [notifications, setNotifications] = useState({});
	const [boardToDelete, setBoardToDelete] = useState('');
	const [showPricingModal, setShowPricingModal] = useState(false);
	const [displayTeamModal, setDisplayTeamModal] = useState(false);
	const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);
	const [displayBoardModal, setDisplayBoardModal] = useState(false);
	const [createTeamErrorStatus, setCreateTeamErrorStatus] = useState(-1);
	const [isActiveSubscription, setIsActiveSubscription] = useState(false);
	const [createBoardErrorStatus, setCreateBoardErrorStatus] = useState(-1);
	const [createTeamSuccessLoader, setCreateTeamSuccessLoader] = useState(false);
	const [createBoardSuccessLoader, setCreateBoardSuccessLoader] = useState(false);

	useEffect(() => {
		try {
			GET('/user/dashboard')
				.then(response => {
					const payload = get(response, ['data'], {});
					const status = get(payload, ['userStatus', 'status'], '');
					setShowPricingModal(status !== 'active');
					setIsActiveSubscription(status === 'active');
					dispatch({ type: 'hydrate', payload });
					setIsLoading(false);
				});

			onValue(ref(sync, `users/${user.uid}/notifications`), data => {
				const value = data.val();
				if (value)
					setNotifications(value);
			});
		} catch (error) {
			console.log(error.message);
			alertify.notify(error.message, 'error', 3, function () { console.log(error) });
		}
	}, []);

	const removeBoardFromDash = boardId => {
		const updatedBoards = {};
		map(Object.keys(state.boards), key => {
			if (key !== boardId)
				updatedBoards[key] = state.boards[key];
		});
		dispatch({ type: 'deleteBoard', payload: updatedBoards });
	};

	const createTeam = teamInfo => {
		setCreateTeamSuccessLoader(true);
		POST('/create/team', teamInfo)
			.then(res => {
				const response = get(res, ['data'], {});
				if (response.redirectOnSubmit) {
					setRedirectLink(response.teamRoomURL);
					setRedirectOnSubmit(true);
					setCreateTeamSuccessLoader(false);
				}
				else
					dispatch({ type: 'updateTeams', payload: response });
				setDisplayTeamModal(false);
			}).catch(err => {
				setCreateTeamSuccessLoader(false);
				setCreateTeamErrorStatus(get(err, ['response', 'status'], -1));
				alertify.notify('Cannot create team', 'error', 3, () => console.log('ERROR: ', err));
			});
	};

	const getRequestType = isNew => (isNew ? {
		url: 'new',
		type: 'createTeamBoard',
	} : {
		url: 'exist',
		type: 'createBoard',
	});

	const getTeamMembersCount = teamID => {
		return Object.keys(get(state, ['teams', teamID, 'teamInfo', 'members'], {})).length;
	};

	const createBoard = async (newBoard) => {
		setCreateBoardSuccessLoader(true);
		const request = getRequestType(get(newBoard, ['teamInfo', 'isNewTeam'], false));
		POST(`/create/board/${request.url}`, newBoard)
			.then(res => {
				const response = get(res, ['data'], {});
				if (response.redirectOnSubmit) {
					setRedirectLink(response.boardURL);
					setRedirectOnSubmit(true);
				}
				else
					dispatch({ type: request.type, payload: response });
				setDisplayBoardModal(false);
				setCreateBoardSuccessLoader(false);
			})
			.catch(err => {
				setCreateBoardErrorStatus(get(err, ['response', 'status'], -1));
				setCreateBoardSuccessLoader(false);
				alertify.notify('Cannot create board', 'error', 3, () => console.log('ERROR: ', err));
			});
	};

	return (isLoading ? <Loader /> :
		<>
			<LandingNavbar
				navigateToHome
				showNotification
				notifications={notifications}
				userStatus={state.userStatus}
				showUpgrade={!isActiveSubscription}
			/>
			<div className="dashboard-container">
				{get(state, ['userStatus', 'isTeam'], false) && <Teams
					teams={state.teams}
					onSubmit={createTeam}
					userStatus={state.userStatus}
					displayModal={displayTeamModal}
					errorStatus={createTeamErrorStatus}
					setDisplayModal={setDisplayTeamModal}
					setErrorStatus={setCreateTeamErrorStatus}
					onSuccessLoader={createTeamSuccessLoader}
					emptyTeams={Object.keys(get(state, 'teams', {})).length === 0}
				/>}
				<RetroCollection
					boards={state.boards}
					title="Latest Boards"
					onSubmit={createBoard}
					redirectLink={redirectLink}
					boardToDelete={boardToDelete}
					userStatus={state.userStatus}
					displayModal={displayBoardModal}
					setBoardToDelete={setBoardToDelete}
					teamsDropdown={state.teamsDropdown}
					redirectOnSubmit={redirectOnSubmit}
					errorStatus={createBoardErrorStatus}
					setDisplayModal={setDisplayBoardModal}
					defaultTemplate={state.defaultTemplate}
					getTeamMembersCount={getTeamMembersCount}
					removeBoardFromDash={removeBoardFromDash}
					setErrorStatus={setCreateBoardErrorStatus}
					onSuccessLoader={createBoardSuccessLoader}
					boardEmpty={Object.keys(get(state, 'boards', {})).length === 0}
				/>
				<ActionItems
					title="Latest Action Items"
					actionItems={state.actionItems}
				/>
				{Object.keys(state.appreciations).length !== 0 && <Appreciations
					appreciations={state.appreciations}
				/>}
			</div>
			<PricingModal
				userStatus={state.userStatus}
				displayModal={showPricingModal}
				closeModal={() => setShowPricingModal(false)}
			/>
		</>
	);
};

export default Dashboard;