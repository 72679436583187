import React, { useContext, useState } from 'react';
import Checkbox from '../Form/Checkbox';
import SideDrawer from './SideDrawer';
import { POST } from '../../services/HTTPHandler';
import SettingsInput from '../Form/SettingsInput';
import { RetroControllerContext } from '../../contexts/RetroControllerContext';

const BoardSettingsSideDrawer = props => {
    const { boardID, showSettings, setShowSettings } = props;
    const {
        showAuthor, setShowAuthor,
        votesPerUser, setVotesPerUser,
        oneVotePerCard, setOneVotePerCard,
        anonymousComments, setAnonymousComments
    } = useContext(RetroControllerContext);

    const [votePerUserError, setVotesPerUserError] = useState(false);

    const validateVoteCount = count => {
        return count > 0 || count === '';
    };

    const onChangeHandler = event => {
        if (validateVoteCount(event.target.value)) {
            setVotesPerUserError(false);
            setVotesPerUser(parseInt(event.target.value));
            postUpdate({ showAuthor, votesPerUser: parseInt(event.target.value), oneVotePerCard, anonymousComments });
        }
        else
            setVotesPerUserError(true);
    };

    const postUpdate = data => {
        POST('/board/updateBoardSettings', { boardID, ...data });
    };

    return <SideDrawer title="Board Settings" active={showSettings} containerClickHandler={() => setShowSettings(false)}>
        <div className="input-row">
            <Checkbox
                value={anonymousComments}
                label="Comment Anonymously"
                onChange={() => {
                    setAnonymousComments(!anonymousComments);
                    postUpdate({ showAuthor, votesPerUser, oneVotePerCard, anonymousComments: !anonymousComments });
                }}
            />
        </div>
        <div className="input-row">
            <Checkbox
                value={showAuthor}
                label="Show Card Author"
                onChange={() => {
                    setShowAuthor(!showAuthor);
                    postUpdate({ showAuthor: !showAuthor, votesPerUser, oneVotePerCard, anonymousComments });
                }}
            />
        </div>
        <div className="input-row">
            <Checkbox
                value={oneVotePerCard}
                label="Limit one vote per card"
                onChange={() => {
                    setOneVotePerCard(!oneVotePerCard);
                    postUpdate({ showAuthor, votesPerUser, oneVotePerCard: !oneVotePerCard, anonymousComments });
                }}
            />
        </div>
        <div className="input-row settings-input__wrapper max-votes__wrapper">
            <SettingsInput
                type="number"
                value={votesPerUser}
                onChange={onChangeHandler}
                isError={votePerUserError}
                label="Enter Max votes per user"
                className="board-settings__max-votes"
            />
            {votePerUserError && <span className="error">Votes can only be a positive number</span>}
        </div>
    </SideDrawer>;
};

export default BoardSettingsSideDrawer;