import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { emailRegex } from '../../utils/regex';

import './chipInput.css'

const ChipInput = props => {
    const { value, onChange, resetValue, emails, setEmails, emptyError } = props;
    const [emailError, setEmailError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isValid = email => {
        if (!emailRegex.test(email)) {
            setErrorMessage('Email is Invalid');
            setEmailError(true);
            return false;
        }
        if (emails.includes(email)) {
            setErrorMessage('Email already added');
            setEmailError(true);
            return false;
        }
        setEmailError(false);
        return true;
    };

    const handleKeyDown = event => {
        reset();
        if (['Enter', 'Tab'].includes(event.key)) {
            event.preventDefault();
            var email = value.trim();

            if (isValid(email)) {
                setEmails([...emails, email]);
                resetValue();
            }
        };
    };

    const handleDelete = emailToBeRemoved => {
        setEmails(emails.filter(email => email !== emailToBeRemoved));
    };

    const reset = () => {
        setErrorMessage('');
        setEmailError(false);
    };

    useEffect(() => {
        if (emptyError) {
            setErrorMessage('Email list cannot be empty');
            setEmailError(true);
        } else reset();
    }, [emptyError])

    return <>
        <div className="email__tag--wrapper">
            {emails.map(email => <span className="tag-item" key={email} data-fade-in>
                {email}
                <button type="button" onClick={() => handleDelete(email)}>
                    &times;
                </button>
            </span>)}
        </div>
        <div
            className={classNames('wrap-input2', 'validate-input', {
                'alert-validate': emailError
            })}
            data-validate={errorMessage}
        >
            <input
                required
                className={classNames('input2', 'input-theme', {
                    'has-val': value !== '',
                })}
                type="email"
                name="email"
                value={value}
                aria-label="email"
                onChange={onChange}
                onKeyDown={handleKeyDown}
            />
            <span className="focus-input2" data-placeholder="Enter E-mail (Tab seperated)" />
        </div>
    </>;
};

export default ChipInput;
