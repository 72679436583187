// This import loads the firebase namespace.
import * as firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import firebaseConfig from '../firebaseSecret.json';

const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

export const requestNotificationPermission = () => {
	return (async () => {
		const response = await Notification.requestPermission();
		switch (response) {
			case 'granted': return true;
			case 'denied': return false;
			default: return false;
		}
	})();
};

export const createToken = async () => {
	const user = getAuth(app).currentUser;
	const token = user && (await user.getIdToken());
	const payloadHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		}
	};
	return payloadHeader;
};

export const reAuthenticateUsers = async currentPassword => {
	const auth = getAuth(app);
	const user = auth.currentUser;
	return await signInWithEmailAndPassword(auth, user.email, currentPassword);
};

export const auth = getAuth(app);
export const sync = getDatabase(app);
