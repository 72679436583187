import React, { useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import InvitePopOver from '../PopOver/InvitePopOver';
import RetroButton from '../Form/RetroButton';
import MemberIcon from '../Retro/MemberIcon';
import '../../styles/Navigation/retroNav.css';

const RetroNav = props => {
    const { boardData, members } = props;

    const [showInvitePopOver, setShowInvitePopOver] = useState(false);

    return (
        <nav className="retro-nav">
            <div className="retro-navbar__logo-section">
                <a href="/dashboard">
                    <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/Logo.webp" alt="Logo" />
                </a>
                <div className="retro-navbar__board-title">
                    {get(boardData, ['board', 'name'], 'Unnamed Retrospective')}
                </div>
            </div>
            {members.length > 1 && <div className="retro-navbar__members-section">
                {map(members, member => <MemberIcon />)}
            </div>}
            <div className="retro-navbar__profile-section">
                <RetroButton
                    className="mg-25"
                    aria-label="Invite team members"
                    onClick={() => setShowInvitePopOver(!showInvitePopOver)}
                >
                    Invite
                </RetroButton>
                {showInvitePopOver &&
                    <InvitePopOver
                        inviteCodes={boardData.inviteCodes}
                        closePopOver={() => setShowInvitePopOver(false)}
                        boardURL={get(boardData, ['board', 'boardURL'], '')}
                    />}
            </div>
        </nav>
    );
};

export default RetroNav;