import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import Link from '../Form/Link';
import alertify from 'alertifyjs';
import startCase from 'lodash/startCase';
import RetroButton from '../Form/RetroButton';
import AlertModal from '../Modals/AlertModal';
import FeatureBox from '../Checkout/FeatureBox';
import { POST } from '../../services/HTTPHandler';
import { getCardTypeSVGIcon } from '../../utils/creditCard';
import { getDiscountCost, getPlanCost } from '../../utils/price';


import '../../styles/manageSubscription.css';

const ManageSubscription = props => {
    const {
        plan = {},
        card = {},
        isPaymentLoading,
        entitlements = {},
        setIsPaymentLoading,
        hostedInvoiceURL = '',
        getLatestSubscriptionData,
    } = props;
    const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const [isAddingNewCard, setIsAddingNewCard] = useState(false);
    const [showAnnualSwitchConfirmation, setShowAnnualSwitchConfirmation] = useState(false);
    const [showCancelSubscriptionReactivation, setShowCancelSubscriptionReactivation] = useState(false);
    const [showCancelSubscriptionConfirmation, setShowCancelSubscriptionConfirmation] = useState(false);

    useEffect(() => {
        let root = document.documentElement;
        const labelColor = plan.active ? '#26c484' : '#e5a15c';
        root.style.setProperty('--subscriptionStatusLabel', labelColor);
    }, [plan.active]);

    const getCurrentCycleFormatted = () => {
        const endDate = new Date(plan.billingCycleEnd * 1000);
        const startDate = new Date(plan.billingCycleStart * 1000);
        return `${startDate.getDate()} ${monthName[startDate.getMonth()]} - ${endDate.getDate()} ${monthName[endDate.getMonth()]}`;
    };

    const switchToAnnual = () => {
        if (isAddingNewCard)
            // eslint-disable-next-line no-restricted-globals
            location.assign(getRedirectURL());
        setIsPaymentLoading(true);
        POST('/order/switchToAnnual')
            .then(res => {
                // eslint-disable-next-line no-restricted-globals
                location.assign('/order/success');
            })
            .catch(err => console.log(err));
    };

    const getRedirectURL = () => {
        return entitlements.isTeam ? '/upgrade/team?isAnnual=true&noOfTeams=' + entitlements.allowedTeamCount : '/upgrade/single?isAnnual=true';
    };

    const cancelSubscription = () => {
        // eslint-disable-next-line no-restricted-globals
        location.assign('/order/cancel');
    };

    const reactivateSubscription = () => {
        if (isAddingNewCard)
            // eslint-disable-next-line no-restricted-globals
            location.assign(getRedirectURL());
        setIsPaymentLoading(true);
        POST('/order/reactivate')
            .then(() => {
                getLatestSubscriptionData();
                alertify.notify('Subscription Re-activated', 'success', 3)
            });
    };

    const getReactivationMessage = () => {
        if (plan.cancelAtCycleEnd)
            return 'You will only be charged at the end of this subscription cycle on your next invoice';
        return 'You will be starting a new subscription cycle beginning today.';
    };

    return <div className="settings__content--container">
        <div className="settings__form settings-padding">
            <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Current Subscription</h3>
                    <p className="mt-0">View the details of your current subscription plan.</p>
                </div>
                <div className="settings__row--value">
                    <div className="card__wrapper">
                        <div className="subscription-details__row">
                            <div className="subscription-details__row--label">
                                Plan
                            </div>
                            <div className="subscription-details__row--value">
                                <span className="plan__label">{startCase(plan.status)}</span>
                                {plan.discount && <span className="plan__label">{`${get(plan, ['discount', 'coupon', 'percent_off'], '')}% OFF`}</span>}
                                {entitlements.isTeam ? 'Team' : 'Single User'}
                            </div>
                        </div>
                        {!plan.active && <div className="subscription-details__row">
                            <div className="subscription-details__row--label">
                                Trial end date
                            </div>
                            <div className="subscription-details__row--value">
                                {new Date(plan.trialEndDate * 1000).toDateString()}
                            </div>
                        </div>}
                        <div className="subscription-details__row">
                            <div className="subscription-details__row--label">
                                Quantity
                            </div>
                            <div className="subscription-details__row--value">
                                {plan.quantity}
                            </div>
                        </div>
                        <div className="subscription-details__row">
                            <div className="subscription-details__row--label">
                                Billing interval
                            </div>
                            <div className="subscription-details__row--value">
                                {plan.billingInterval === 'month' ? 'Monthly' : 'Yearly'}
                            </div>
                        </div>
                        <div className="subscription-details__row">
                            <div className="subscription-details__row--label">
                                Current cycle
                            </div>
                            <div className="subscription-details__row--value">
                                {getCurrentCycleFormatted()}
                            </div>
                        </div>
                        {plan.cancelAtCycleEnd ? <div className="subscription-details__row">
                            <div className="subscription-details__row--label">
                                Cycle cancels at
                            </div>
                            <div className="subscription-details__row--value">
                                {new Date(plan.cancelAt * 1000).toDateString()}
                            </div>
                        </div> :
                            <div className="subscription-details__row">
                                <div className="subscription-details__row--label">
                                    Next invoice date
                                </div>
                                <div className="subscription-details__row--value">
                                    {new Date(plan.billingCycleEnd * 1000).toDateString()}
                                </div>
                            </div>}
                        {plan.active && <>
                            <div className="subscription-details__row">
                                <div className="subscription-details__row--label">
                                    Payment method
                                </div>
                                <div className="subscription-details__row--value">
                                    {getCardTypeSVGIcon(card.paymentBrand)} {`•••• ${card.paymentLast4}`}
                                </div>
                            </div>
                            <Link
                                openInNewTab
                                className="mt-20"
                                href={hostedInvoiceURL}
                                aria-label="view invoice in stripe"
                            >
                                View latest invoice in Stripe
                            </Link>
                        </>}
                    </div>
                </div>
            </div>
            {plan.billingInterval === 'month' && plan.active && <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Upgrade to Annual plan</h3>
                    <span className="plan__label save-up">FREE 2 MONTHS</span>
                    <p className="mt-0">Upgrade to annual plan to save 2 months subscription value.</p>
                </div>
                <div className="settings__row--value">
                    <RetroButton
                        onClick={() => setShowAnnualSwitchConfirmation(true)}
                        aria-label="switch to annual"
                    >
                        Switch to Annual
                    </RetroButton>
                    <AlertModal
                        showPrice
                        showCheckbox
                        successTheme="blue"
                        addAdditionalWarning
                        failureMessage="Cancel"
                        successMessage="Upgrade"
                        title="Switch to Annual plan"
                        checkboxValue={isAddingNewCard}
                        successHandler={switchToAnnual}
                        successLoader={isPaymentLoading}
                        checkboxMessage="Pay using a different card"
                        displayModal={showAnnualSwitchConfirmation}
                        failureButtonAriaLabel="cancel and close modal"
                        successButtonAriaLabel="upgrade to annual plan"
                        closeModal={() => setShowAnnualSwitchConfirmation(false)}
                        checkboxOnChange={() => setIsAddingNewCard(!isAddingNewCard)}
                        failureHandler={() => setShowAnnualSwitchConfirmation(false)}
                        price={getPlanCost({ isAnnual: true, isTeam: entitlements.isTeam, noOfTeams: entitlements.allowedTeamCount })}
                        discountPrice={getDiscountCost({ isAnnual: true, isTeam: entitlements.isTeam, noOfTeams: entitlements.allowedTeamCount })}
                        message="Save 2 months of subscription value by switching to a yearly plan. Please confirm to activate yearly plan."
                        additionalWarningMessage="Your invoice will be prorated. Any unused time in the current plan will be refunded as credits that is applied towards your next invoice"
                    />
                </div>
            </div>}
            {(!entitlements.isTeam || !plan.active) && <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Upgrade Subscription</h3>
                    <p className="mt-0">Upgrade to a team plan to unlock great features that would allow you to collaborate with your team in real-time.</p>
                </div>
                <div className="settings__row--value">
                    {!plan.active && <div className="plan__container">
                        <Link
                            theme="blue"
                            linkAsButton
                            href="/upgrade/single"
                            aria-label="upgrade to single user"
                        >
                            Activate your plan
                        </Link>
                        <FeatureBox
                            showPlan
                            planName="Single User plan"
                        />
                    </div>}
                    <div className="plan__container">
                        <Link
                            theme="blue"
                            linkAsButton
                            href="/upgrade/team"
                            aria-label="upgrade to team"
                        >
                            Switch to Teams
                        </Link>
                        <FeatureBox
                            isTeam
                            showPlan
                            isComparison
                            planName="Team plan"
                        />
                    </div>
                </div>
            </div>}
            {(entitlements.isTeam && plan.quantity < 10) && <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Add more teams</h3>
                    <p className="mt-0">You can add more teams to your plan.</p>
                </div>
                <div className="settings__row--value">
                    <RetroButton
                        theme="blue"
                        aria-label="cancel subscription"
                        onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            location.assign('/upgrade/team')
                        }}
                    >
                        Add Teams
                    </RetroButton>
                </div>
            </div>}
            {plan.active && !plan.cancelAt ? <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Cancel Subscription</h3>
                    <p className="mt-0">You can cancel anytime if you are unhappy with Retro.</p>
                </div>
                <div className="settings__row--value">
                    <RetroButton
                        theme="danger"
                        aria-label="cancel subscription"
                        onClick={() => setShowCancelSubscriptionConfirmation(true)}
                    >
                        Cancel Subscription
                    </RetroButton>
                    <AlertModal
                        successTheme="danger"
                        failureMessage="No, Abort"
                        successMessage="Yes, Cancel"
                        title="Retro Cancellation"
                        successHandler={cancelSubscription}
                        failureButtonAriaLabel="abort cancellation"
                        successButtonAriaLabel="proceed cancellation"
                        displayModal={showCancelSubscriptionConfirmation}
                        closeModal={() => setShowCancelSubscriptionConfirmation(false)}
                        failureHandler={() => setShowCancelSubscriptionConfirmation(false)}
                        message="Are you sure you want to cancel your Retro subcription? We'd hate to see you leave, please let us know if there is anything we could do to make your Retro experience better."
                    />
                </div>
            </div> :
                <>{plan.status !== 'trialing' && <div className="settings__row">
                    <div className="settings__row--description">
                        <h3 className="mt-0">Re-activate Subscription</h3>
                        <p className="mt-0">Reactivate subscription with the plan that was last active. Regain access to all retro boards</p>
                    </div>
                    <div className="settings__row--value">
                        <RetroButton
                            size="xl"
                            theme="blue"
                            aria-label="reactivate subscription"
                            onClick={() => setShowCancelSubscriptionReactivation(true)}
                        >
                            Re-activate Subscription
                        </RetroButton>
                        <AlertModal
                            showCheckbox
                            successTheme="blue"
                            failureMessage="Cancel"
                            successMessage="Reactivate"
                            failureButtonAriaLabel="cancel"
                            checkboxValue={isAddingNewCard}
                            successLoader={isPaymentLoading}
                            title="Subscription Reactivation"
                            showPrice={!plan.cancelAtCycleEnd}
                            message={getReactivationMessage()}
                            successHandler={reactivateSubscription}
                            checkboxMessage="Pay using a different card"
                            successButtonAriaLabel="subscription reactivation"
                            displayModal={showCancelSubscriptionReactivation}
                            checkboxOnChange={() => setIsAddingNewCard(!isAddingNewCard)}
                            closeModal={() => setShowCancelSubscriptionReactivation(false)}
                            failureHandler={() => setShowCancelSubscriptionReactivation(false)}
                            price={getPlanCost({ isAnnual: entitlements.isAnnual, isTeam: entitlements.isTeam, noOfTeams: entitlements.allowedTeamCount })}
                        />
                    </div>
                </div>}</>}

        </div>
    </div>;
};

export default ManageSubscription;