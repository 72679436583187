import React from 'react';
import { Navigate, useLocation } from "react-router-dom";

function PublicRoute(props) {
	const { component: Component, authenticated } = props;
	const { search }  = useLocation();
	// If already authenticated redirect to dashboard or previous URL from all incoming public URLs
	if(authenticated) {
		const redirectTo = new URLSearchParams(search).get('redirectTo') || 'dashboard';
		return <Navigate to={ '/' + redirectTo} replace />;
	}
	return <Component {...props} />;
}

export default PublicRoute;