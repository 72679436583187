import map from 'lodash/map';
import alertify from 'alertifyjs';
import React, { useState } from 'react';
import { GrUserAdmin } from 'react-icons/gr';
import { HiBadgeCheck } from 'react-icons/hi';
import AlertModal from '../Modals/AlertModal';
import { MdDeleteForever } from 'react-icons/md';
import { getFormattedName } from '../../utils/user';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import AddMemberModal from '../Modals/AddMemberModal';
import { DELETE, POST } from '../../services/HTTPHandler';

import '../../styles/membersArea.css'

const MembersArea = props => {
    const {
        user,
        teamID,
        members,
        teamName,
        teamAdmin,
        addTeamMember,
        updateTeamAdmin,
        showAddMemberModal,
        setShowAddMemberModal,
        removeUserFromMembersList,
    } = props;

    const [chosenUser, setChosenUser] = useState({});
    const [modalMessage, setModalMessage] = useState('');
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const getTooltipContent = (id, name) => {
        const content = teamAdmin === id ? 'Team Admin : ' : '';
        return content + getFormattedName(name);
    };

    const deleteButtonHandler = user => {
        setModalMessage(`Are you sure you want to delete user: ${getFormattedName(user.displayName)}`);
        setChosenUser(user);
        setShowDeleteModal(true);
    };

    const adminButtonHandler = user => {
        setModalMessage(`Are you sure you want to switch admin to: ${getFormattedName(user.displayName)}`);
        setChosenUser(user);
        setShowAdminModal(true);
    };

    const confirmDeleteMember = userToRemove => {
        DELETE('/team/removeMember', { teamID, userToRemove })
            .then(() => {
                setShowDeleteModal(false);
                alertify.notify('User removed', 'success', 3);
                removeUserFromMembersList(userToRemove);
            })
            .catch(err => alertify.notify('Error removing user', 'error', 3));
    };

    const switchAdmin = userToMakeAdmin => {
        POST('/team/switchAdmin', { teamID, userToMakeAdmin })
            .then(() => {
                setShowAdminModal(false);
                alertify.notify('Admin Switched', 'success', 3);
                updateTeamAdmin(userToMakeAdmin);
            })
            .catch(err => alertify.notify('Error removing user', 'error', 3));
    };

    return <>
        <div className="dashboard-title">
            {teamName} Members
            {user.uid === teamAdmin && <button
                aria-label="create boards"
                data-tooltip-content="Add Team Member"
                onClick={() => setShowAddMemberModal(true)}
                className="dashboard-title__create tooltip expand tooltip-right"
            >
                <AiOutlinePlusCircle />
            </button>}
        </div>
        <div className="members__container">
            {map(members, (member, i) => (
                <div key={i} className="member__wrapper expand tooltip" data-tooltip-content={getTooltipContent(member.uid, member.displayName)}>
                    {member.uid === teamAdmin && <HiBadgeCheck className="admin-badge" />}
                    <img src={member.photoURL} alt="team member" />
                    {user.uid === teamAdmin && <div className="member__action--icons">
                        <button
                            data-tooltip-content="Remove user"
                            data-hide={member.uid === teamAdmin}
                            onClick={() => deleteButtonHandler(member)}
                            className="expand tooltip tooltip-right member__delete-icon"
                        >
                            <MdDeleteForever />
                        </button>
                        <button
                            data-tooltip-content="Make Admin"
                            data-hide={member.uid === teamAdmin}
                            onClick={() => adminButtonHandler(member)}
                            className="expand tooltip tooltip-right member__admin-icon"
                        >
                            <GrUserAdmin />
                        </button>
                    </div>}
                </div>
            ))}
        </div>
        <AlertModal
            title="Delete Member"
            successTheme="danger"
            message={modalMessage}
            failureMessage="Cancel"
            successMessage="Remove User"
            displayModal={showDeleteModal}
            failureButtonAriaLabel="close modal"
            successButtonAriaLabel="delete member"
            closeModal={() => setShowDeleteModal(false)}
            failureHandler={() => setShowDeleteModal(false)}
            successHandler={() => confirmDeleteMember(chosenUser.uid)}
        />
        <AlertModal
            title="Switch Admin"
            successTheme="blue"
            message={modalMessage}
            failureMessage="Cancel"
            displayModal={showAdminModal}
            successMessage="Confirm Admin"
            failureButtonAriaLabel="close modal"
            successButtonAriaLabel="switch admin"
            closeModal={() => setShowAdminModal(false)}
            successHandler={() => switchAdmin(chosenUser.uid)}
            failureHandler={() => setShowAdminModal(false)}
        />
        <AddMemberModal
            successHandler={addTeamMember}
            displayModal={showAddMemberModal}
            closeModal={() => setShowAddMemberModal(false)}
        />
    </>;
};

export default MembersArea;