import React from 'react';
import './retroTextButton.css';
import classNames from 'classnames';

const RetroTextButton = props => {
	const { onClick, children, disabled, className, ...rest } = props;

	const classes = classNames("retro-text-button", className)

	return <button
		onClick={onClick}
		disabled={disabled}
		className={classes}
		{...rest}
	>
		{children}
	</button>
};

export default RetroTextButton;