import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { MdPlusOne } from 'react-icons/md';
import { getFormattedName, getProfilePicture } from '../../utils/user';

import '../../styles/appreciations.css'

const Appreciations = props => {
    const { appreciations } = props;

    return <>
        <div className="dashboard-title">
            Appreciations
        </div>
        <div className="appreciations__container">
            {map(appreciations, (appreciation = {}, key) => (
                <div key={key} className="appreciations__row">
                    <div className="appreciations__info">
                        <div
                            className="board-image tooltip expand tooltip-top"
                            data-tooltip-content={get(appreciation, ['board', 'boardName'], 'Board')}
                        >
                            <img src={getProfilePicture(get(appreciation, ['board', 'boardName'], 'Board'))} alt="board" />
                        </div>
                        <div className="appreciations__info-display">
                            <span>{appreciation.content}</span>
                            <span className="appreciations__belongs-to">{getFormattedName(get(appreciation, ['from', 'displayName'], ''))}</span>
                        </div>
                        {Object.keys(appreciation.upVote || {}).length !== 0 &&
                            <div
                                className="up-vote__container tooltip expand tooltip-top"
                                data-tooltip-content={`${Object.keys(appreciation.upVote || {}).length} Up Vote`}
                            >
                                <MdPlusOne />
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    </>;
};

export default Appreciations;