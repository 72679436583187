import React, { useContext, useState } from 'react';
import Card from './Card';
import get from 'lodash/get';
import map from 'lodash/map';
import classnames from 'classnames';
import MergedCards from './MergedCards';
import TextInput from '../Form/TextInput';
import FormDropdown from '../Form/FormDropdown';
import AppreciationCard from './AppreciationCard';
import '../../styles/column.css';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

const Column = (props) => {
    const {
        cards,
        index,
        teamID,
        onDrop,
        boardID,
        members,
        draggable,
        retroStep,
        onDragEnd,
        votingMode,
        onDragStart,
        onDragEnter,
        onDragLeave,
        columnTitle,
        columnTheme,
        handleSubmit,
        disableInput,
        membersOption,
        maxVoteReached,
        updateTotalVotes,
        columnDescription,
        isAppreciationColumn,
        ...rest
    } = props;

    const user = useContext(CurrentUserContext);
    const [chosenTeamMember, setChosenTeamMember] = useState('');
    const [appreciationsInputError, setAppreciationsInputError] = useState(false);
    const columnHeaderClasses = classnames('column__header', `column__theme--${columnTheme}`);

    const updateChosenTeamMember = event => {
        setChosenTeamMember(event.target.value);
        if (event.target.value !== '')
            setAppreciationsInputError(false);
    };

    return (
        <div className="column" data-hide={isAppreciationColumn && retroStep !== 0} data-fade-in>
            <div className={columnHeaderClasses}>
                <h3>{columnTitle}</h3>
            </div>
            <TextInput
                index={index}
                teamID={teamID}
                boardID={boardID}
                disabled={disableInput}
                columnTheme={columnTheme}
                label={columnDescription}
                handleSubmit={handleSubmit}
                className="column-card__input"
                chosenTeamMember={chosenTeamMember}
                isAppreciationColumn={isAppreciationColumn}
                appreciationsInputError={appreciationsInputError}
                setAppreciationsInputError={setAppreciationsInputError}
                {...rest}
            />
            {isAppreciationColumn ?
                <>
                    <div className="dropdown-container">
                        <FormDropdown
                            optionZero={false}
                            options={membersOption}
                            selectedOption={chosenTeamMember}
                            label="--Choose a Team Member--"
                            aria-label="choose a team member"
                            onDropdownChange={updateChosenTeamMember}
                        />
                    </div>
                    <div className="cards">
                        {map(cards, (userCards, forUser) =>
                            <AppreciationCard
                                members={members}
                                userCards={userCards}
                                retroStep={retroStep}
                                key={Object.keys(userCards)[0]}
                                forUser={members[forUser] || {}}
                            />
                        )}
                    </div>
                </> :
                <div className="cards" onDrop={onDrop} onDragOver={e => e.preventDefault()}>
                    {cards && Object.entries(cards).reverse().map(([key, card]) => {
                        return !card.mergedCards ? <Card
                            key={key}
                            cardKey={key}
                            colIndex={index}
                            boardID={boardID}
                            members={members}
                            author={card.owner}
                            isMergedCard={false}
                            retroStep={retroStep}
                            draggable={draggable}
                            content={card.content}
                            votingMode={votingMode}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            maxVoteReached={maxVoteReached}
                            isOwnwer={card.owner === user.uid}
                            updateTotalVotes={updateTotalVotes}
                            vote={get(card, ['vote', user.uid], {})}
                            cardTheme={card.cardTheme || columnTheme}
                            isAppreciationColumn={isAppreciationColumn}
                        /> : <MergedCards
                            key={key}
                            colIndex={index}
                            boardID={boardID}
                            members={members}
                            isMergedCard={true}
                            author={card.owner}
                            cards={card.children}
                            baseKey={card.baseKey}
                            votingMode={votingMode}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            maxVoteReached={maxVoteReached}
                            updateTotalVotes={updateTotalVotes}
                            vote={get(card, ['vote', user.uid], {})}
                            groupTitle={card.groupTitle || 'Edit Group Title'}
                        />
                    })}
                </div>}
        </div>
    );
};

export default Column;