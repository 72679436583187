import map from 'lodash/map';
import classNames from 'classnames';
import React, { useEffect } from "react";
import { PUT } from "../../services/HTTPHandler";
import { AiFillCheckCircle } from 'react-icons/ai';
import { getFormattedName } from '../../utils/user';

import '../../styles/memberActivity.css';

const MemberActivity = (props) => {
    const {
        teamID,
        boardID,
        members,
        retroStep,
        totalVotes,
        votesPerUser,
        membersOnline = {},
        membersTypingList = {},
        membersFinishedList = {},
        memberVotesRemainingPercent = {},
    } = props;

    const classnames = classNames("member-image__wrapper tooltip expand tooltip-top",
        {
            'voting-status': retroStep === 2,
        });

    useEffect(() => {
        const votesRemainingPercent = ((votesPerUser - totalVotes) / votesPerUser) * 100;
        PUT('/activity/memberVoteStatus', { teamID, boardID, votesRemainingPercent });
    }, [totalVotes, votesPerUser]);

    const getStyleObject = uid => ({ '--value': memberVotesRemainingPercent[uid] });

    return <div className="member-activity__container">
        {map(members,
            member =>
                <div
                    data-fade-in
                    key={member.uid}
                    className={classnames}
                    data-tooltip-content={getFormattedName(member.displayName)}
                >
                    {retroStep === 2 && <div
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={getStyleObject(member.uid)}
                        aria-valuenow={memberVotesRemainingPercent[member.uid]}
                    />}
                    <img src={member.photoURL} alt="member" />
                    {membersTypingList[member.uid] && retroStep === 0 && <div className="typing__overlay">
                        <div className="user-typing" />
                    </div>}
                    {!membersOnline[member.uid] && <div className="offline__overlay" />}
                    {membersFinishedList[member.uid] && retroStep === 0 &&
                        <div className="finished__overlay" >
                            <AiFillCheckCircle />
                        </div>
                    }
                </div>
        )}
    </div>;
};

export default MemberActivity;