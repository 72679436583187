import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import './timer.css';

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;


const COLOR_CODES = {
    info: {
        color: "green"
    },
    warning: {
        color: "orange",
        threshold: WARNING_THRESHOLD
    },
    alert: {
        color: "red",
        threshold: ALERT_THRESHOLD
    }
};

const Timer = props => {
    const {
        start,
        reset,
        timeLimit,
        className,
        setStartTimer,
        handleTimerDone,
        // addRunningMinute,
        handleResetTimer,
    } = props;
    let timePassed = 0;
    let timeLeft = timeLimit;
    // let timerInterval = null;
    let remainingPathColor = COLOR_CODES.info.color;
    const [remainingTime, setRemainingTime] = useState(0);
    const [timerRunning, setTimerRunning] = useState(false);
    const [alertThreshold, setAlertThreshold] = useState(5);
    const [timerInterval, setTimerInterval] = useState(null);
    const [wanrningThreshold, setWanrningThreshold] = useState(10);
    // const [runningMinuteAdded, setRunningMinuteAdded] = useState(false);

    const timerRingRef = useRef(null);
    const timerLabelRef = useRef(null);

    const playTimerSound = () => (new Audio('https://github.com/arvindrk/my-retro/raw/master/audio/timer_notification.mp3').play());

    const onTimesUp = (timerInterval) => {
        clearInterval(timerInterval);
        timePassed = 0;
        setTimerRunning(false);
        handleTimerDone();
        timerLabelRef.current.innerHTML = formatTime(0);
        timerRingRef.current.classList.remove(alert.color);
        timerRingRef.current.setAttribute("stroke-dasharray", "0 283");
    };

    const startTimer = time => {
        setAlertThreshold(parseInt(time) / 4);
        setWanrningThreshold(parseInt(time) / 3);
        const interval = setInterval(() => {
            setTimerRunning(true);
            timePassed = timePassed += 1;
            timeLeft = time - timePassed;
            timerLabelRef.current.innerHTML = formatTime(timeLeft);
            setCircleDasharray();
            setRemainingPathColor(timeLeft);
            setRemainingTime(timeLeft);
            if (timeLeft <= 0) {
                onTimesUp(timerInterval);
                playTimerSound();
            }
        }, 1000);
        setTimerInterval(interval);
        setStartTimer(false);
    };

    const restTimer = () => {
        onTimesUp(timerInterval);
        handleResetTimer(false);
    };

    const formatTime = time => {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
    };

    const setRemainingPathColor = timeLeft => {
        const { alert, warning, info } = COLOR_CODES;
        if (timeLeft <= alertThreshold) {
            timerRingRef.current.classList.remove(warning.color);
            timerRingRef.current.classList.add(alert.color);
        } else if (timeLeft <= wanrningThreshold) {
            timerRingRef.current.classList.remove(info.color);
            timerRingRef.current.classList.add(warning.color);
        } else {
            timerRingRef.current.classList.remove(alert.color);
            timerRingRef.current.classList.add(info.color);
        }
    };

    const calculateTimeFraction = addFlag => {
        const time = addFlag ? remainingTime + 60 : timeLimit;
        const rawTimeFraction = timeLeft / time;
        return rawTimeFraction - (1 / time) * (1 - rawTimeFraction);
    };

    const setCircleDasharray = addFlag => {
        const circleDasharray = `${(
            calculateTimeFraction(addFlag) * FULL_DASH_ARRAY
        ).toFixed(0)} 283`;
        timerRingRef.current.setAttribute("stroke-dasharray", circleDasharray);
    };

    // const addMinute = () => {
    //     clearInterval(timerInterval);
    //     startTimer(remainingTime + 60);
    //     setRunningMinuteAdded(true);
    //     // timerInterval = setInterval(() => {
    //     //     setTimerRunning(true);
    //     //     timePassed = timePassed += 1;
    //     //     timeLeft = remaingingTime - timePassed;
    //     //     timerLabelRef.current.innerHTML = formatTime(timeLeft);
    //     //     setCircleDasharray();
    //     //     setRemainingPathColor(timeLeft);

    //     //     if (timeLeft === 0) {
    //     //         onTimesUp();
    //     //     }
    //     // }, 1000);
    // };

    useEffect(() => {
        if (start && !timerRunning)
            startTimer(timeLimit);
        // if (addRunningMinute && !runningMinuteAdded)
        //     addMinute();
        if (reset && timerRunning)
            restTimer();
    });

    return (
        <div className={classnames("base-timer", className)}>
            <svg className="base-timer__svg" viewBox="0 0 100 100">
                <g className="base-timer__circle">
                    <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                    <path
                        ref={timerRingRef}
                        strokeDasharray="283"
                        className={`base-timer__path-remaining ${remainingPathColor}`}
                        d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
                    ></path>
                </g>
            </svg>
            <span ref={timerLabelRef} className="base-timer__label">{formatTime(timeLeft)}</span>
        </div>
    );
};

export default Timer;