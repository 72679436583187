import map from 'lodash/map';
import classnames from 'classnames';
import React, { useState } from 'react';
import ActionItemsContainer from './ActionItemsContainer';
import '../../styles/actionItems.css';

const ActionItems = props => {
    const { actionItems, title, headless } = props;
    const defaultTab = headless ? 'team-actions' : 'my-actions';
    const [activeTab, setActiveTab] = useState(defaultTab);

    const myClasses = classnames({ 'active': activeTab === 'my-actions' });
    const teamClasses = classnames({ 'active': activeTab === 'team-actions' });
    const containerClasses = classnames('action-item-box', {
        'spacing': headless
    });

    const getActionItems = items => {
        const response = [];
        map(items, board => {
            map(board, (actionItem, key) => response.push({ ...actionItem, key }));
        });
        return response;
    };

    return (
        <>
            <div className="dashboard-title">
                {title}
                {/* {!emptyActionItems && <div className="dashboard-title__see-all">See all</div>} */}
            </div>
            <div className={containerClasses}>
                {!headless && <ul className="action-tabs">
                    <li className={myClasses} onClick={() => setActiveTab('my-actions')}>My action items</li>
                    <li className={teamClasses} onClick={() => setActiveTab('team-actions')}>Team action's items</li>
                </ul>}
                <ActionItemsContainer
                    isHidden={activeTab === 'team-actions'}
                    actionItems={getActionItems(actionItems.user)}
                />
                <ActionItemsContainer
                    isHidden={activeTab === 'my-actions'}
                    actionItems={getActionItems(actionItems.team)}
                />
            </div>
        </>
    );
};

export default ActionItems;