import React from 'react';
import classNames from 'classnames';
import RetroButton from '../Form/RetroButton';
import { RiErrorWarningFill } from 'react-icons/ri';

const AlertModal = props => {
    const {
        price,
        title,
        message,
        showPrice,
        className,
        closeModal,
        displayModal,
        successTheme,
        showCheckbox,
        discountPrice,
        checkboxValue,
        successLoader,
        successHandler,
        failureHandler,
        failureMessage,
        successMessage,
        checkboxMessage,
        checkboxOnChange,
        addAdditionalWarning,
        failureButtonAriaLabel,
        successButtonAriaLabel,
        additionalWarningMessage,
    } = props;

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    return <div
        data-fade-in
        style={divStyle}
        className="modal"
        onClick={closeModal}
    >
        <div className="modal__alert" onClick={e => e.stopPropagation()}>
            <div className="modal__header">
                <div className="modal__back width0" />
                <h3 className="modal_title">{title}</h3>
                <div className="close">
                    <button className="modal__header--controls" onClick={closeModal}>&times;</button>
                </div>
            </div>
            <div className={classNames(className, "modal__form modal__form--alert")}>
                {showPrice && <div className="modal__price--container">
                    {discountPrice && <span className="modal__alert--savings">{`Save $${discountPrice}`}</span>}
                    <span className="modal__alert--price">{`$${price}`}</span>
                </div>}
                {message}
                {addAdditionalWarning && <span className="modal__alert--addition-wanring">
                    <div className="warning__icon"><RiErrorWarningFill /></div>
                    {additionalWarningMessage}
                </span>}
                {showCheckbox && <div className="modal__form--checkbox">
                    <input type="checkbox" id="checkbox-alert-label" value={checkboxValue} onChange={checkboxOnChange} />
                    <label htmlFor="checkbox-alert-label">{checkboxMessage}</label>
                </div>}
            </div>
            <div className="modal__alert--actions mt-20">
                <RetroButton
                    onClick={failureHandler}
                    aria-label={failureButtonAriaLabel}
                >
                    {failureMessage}
                </RetroButton>
                <RetroButton
                    theme={successTheme}
                    onClick={successHandler}
                    id="alert-modal__success"
                    aria-label={successButtonAriaLabel}
                >
                    {successLoader ? <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/button__loader.svg" alt="loader" /> : successMessage}
                </RetroButton>
            </div>
        </div >
    </div >;
};

export default AlertModal;