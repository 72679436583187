import React from 'react';
import './formDropdown.css';

const FormDropdown = (props) => {
    const { options = [], label, optionZero, optionZeroValue = '', onDropdownChange, selectedOption, isOptionZeroDisabled, ...rest } = props;

    return (
        <select
            id="options"
            name="options"
            value={selectedOption}
            className="retro-dropdown"
            onChange={onDropdownChange}
            {...rest}
        >
            <option value="">{label}</option>
            {optionZero && <option disabled={isOptionZeroDisabled} value={optionZeroValue}>{optionZero}</option>}
            {options.map((option, i) =>
                <option key={i} value={option.value}>{option.label}</option>
            )}
        </select>
    );
};

export default FormDropdown;