import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Checkbox from '../Form/Checkbox';
import { BiErrorAlt } from 'react-icons/bi';
import ColumnForm from '../Form/ColumnForm';
import { BsArrowLeft } from 'react-icons/bs';
import FormDropdown from '../Form/FormDropdown';

import './modal.css';

const DEFAULT_MAX_VOTE = 5;

const CreateBoardModal = props => {
    const {
        onSubmit,
        teamRoom,
        teamID: id,
        errorStatus,
        displayModal,
        teamsDropdown,
        isTeamMaxedOut,
        teamName: team,
        onSuccessLoader,
        defaultTemplate,
        setErrorStatus = () => (1),
    } = props;
    const [teamID, setTeamID] = useState(id || '');
    const [boardName, setBoardName] = useState('');
    const [teamOption, setTeamOption] = useState('');
    const [templateID, setTemplateID] = useState('');
    const [nameError, setNameError] = useState(false);
    const [teamError, setTeamError] = useState(false);
    const [showAuthor, setShowAuthor] = useState(false);
    const [teamName, setTeamName] = useState(team || '');
    const [teamNameError, setTeamNameError] = useState(false);
    // const [templateError, setTemplateError] = useState(false);
    // const [numberofColumns, setNumberofColumns] = useState(0);
    const [submitValue, setSubmitValue] = useState('Continue');
    const [oneVotePerCard, setOneVotePerCard] = useState(false);
    const [boardDescription, setBoardDescription] = useState('');
    const [dateAsBoardName, setDateAsBoardName] = useState(false);
    const [redirectOnSubmit, setRedirectOnSubmit] = useState(true);
    const [isFullFormFilled, setIsFullFormFilled] = useState(false);
    const [anonymousComments, setAnonymousComments] = useState(false);
    const [showColumnSettings, setShowColumnSettings] = useState(false);
    const [templateOption, setTemplateOption] = useState('defaultTemplate');
    const [hasAppreciationsColumn, setHasAppreciationsColumn] = useState(false);
    const [columnsDropdown, setColumnsDropdown] = useState([{ value: 'defaultTemplate', label: 'Default Template' }]);

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    const closeModal = e => {
        e.stopPropagation();
        // resetForm();
        props.closeModal();
    };

    const resetForm = () => {
        // Use Reducer here?
        setBoardName('');
        setErrorStatus(-1);
        setNameError(false);
        setTeamError(false);
        setShowAuthor(false);
        setBoardDescription('');
        setOneVotePerCard(false);
        setDateAsBoardName(false);
        setRedirectOnSubmit(true);
        setSubmitValue('Continue');
        setIsFullFormFilled(false);
        setAnonymousComments(false);
        setShowColumnSettings(false);
    };

    const validateTeam = () => {
        if (teamOption === '')
            return teamID !== '';
        else if (teamOption === 'create')
            return teamName.trim() !== '';
    };

    const validateBoardName = () => {
        return boardName.trim() !== '';
    }

    const validateBoard = () => {
        return validateBoardName() && validateTeam();
    };

    // const validateColumn = () => {
    //     return true;
    // }

    // const validateForm = () => {
    //     return isFullFormFilled && validateBoard() && validateColumn();
    // };

    const submitForm = () => {
        if (validateBoard()) {
            setNameError(false);
            const boardSettings = {
                showAuthor,
                oneVotePerCard,
                redirectOnSubmit,
                anonymousComments,
                votesPerUser: DEFAULT_MAX_VOTE,
            };
            const teamInfo = addTeam();
            onSubmit({
                teamInfo,
                boardName,
                boardSettings,
                boardDescription,
                templateChosen: templateOption,
            });
            resetForm();
        }
        // else if (validateBoard()) {
        //     setShowColumnSettings(true);
        //     setSubmitValue('Submit');
        //     setNameError(false);
        //     setTeamError(false);
        //     if (getSelectedTemplate() === 'defaultTemplate')
        //         setIsFullFormFilled(true);
        // }
        else {
            if (boardName === '')
                setNameError(true);
            if (!validateTeam())
                setTeamError(true);
        }
    };

    const addTeam = () => {
        if (teamID !== '') return { isNewTeam: false, teamID };
        else if (teamOption === 'create') return { isNewTeam: true, teamName };
    };

    const setDate = (event) => {
        setDateAsBoardName(event.target.checked);
        if (event.target.checked) {
            const date = new Date();
            const month = date.toLocaleString('default', { month: 'long' });
            setBoardName(`${month} ${date.getDate()}, ${date.getFullYear()}`);
            setNameError(false);
        } else
            setBoardName('');
    };

    const selectTeam = event => {
        setTeamError(false);
        if (event.target.value === 'create' || event.target.value === '') {
            setTeamID('');
            setTeamOption(event.target.value);
        }
        else {
            setTeamID(event.target.value);
            setTeamOption('');
        }
    };

    useEffect(() => {
        if (getSelectedTemplate() === 'defaultTemplate')
            setIsFullFormFilled(true);
    }, [])

    const selectTemplate = event => {
        if (event.target.value === 'create' || event.target.value === 'defaultTemplate' || event.target.value === '') {
            setTemplateID('');
            setTemplateOption(event.target.value);
            if (event.target.value === 'defaultTemplate')
                setIsFullFormFilled(true);
        }
        else {
            setTemplateID(event.target.value);
            setTemplateOption('');
        }
    };

    const getSelectedTemplate = () => {
        return templateOption === 'create' || templateOption === 'defaultTemplate' ? templateOption : templateID;
    };

    const goBackToBoard = () => {
        setShowColumnSettings(false);
        setSubmitValue('Continue');
    };

    const getModalContent = () => {
        switch (errorStatus) {
            case 402: return <>
                <BiErrorAlt className="error__icon" />
                <h3>Error, Subscription required!</h3>
                <p>Please upgrade your plan to add more retro boards.</p>
            </>;
            case 426: return <>
                <BiErrorAlt className="error__icon" />
                <h3>Error, Subscription capacity reached!</h3>
                <p>Please upgrade your plan to add more teams.</p>
            </>;
            case -1:
            default: return showColumnSettings ? (<>
                <div className="dropdown-container">
                    <FormDropdown
                        options={columnsDropdown}
                        optionZeroValue="create"
                        label="--Choose a Template--"
                        optionZero="Create a Template"
                        onDropdownChange={selectTemplate}
                        selectedOption={getSelectedTemplate()}
                    />
                </div>
                <ColumnForm
                    defaultTemplate={defaultTemplate}
                    showForm={templateOption === 'create'}
                    hasAppreciationsColumn={hasAppreciationsColumn}
                    isDefault={templateOption === 'defaultTemplate'}
                    setHasAppreciationsColumn={setHasAppreciationsColumn}
                />
            </>
            ) : (<>
                {teamRoom ? <div className="assigned-team">Assigned to - {team}</div> :
                    <div className="input-row">
                        <div className="dropdown-container">
                            <FormDropdown
                                options={teamsDropdown}
                                optionZeroValue="create"
                                label="--Choose a Team--"
                                optionZero="Create a Team"
                                onDropdownChange={selectTeam}
                                isOptionZeroDisabled={isTeamMaxedOut}
                                selectedOption={teamOption === 'create' ? teamOption : teamID}
                            />
                            {teamError && <div>ERROR</div>}
                        </div>
                        {teamOption === 'create' &&
                            <div
                                className={classnames('wrap-input2', 'validate-input', {
                                    'alert-validate': teamNameError
                                })}
                                data-validate="Team Name is Required"
                            >
                                <input
                                    className={classnames('input2', 'input-theme', {
                                        'has-val': teamName !== '',
                                    })}
                                    type="text"
                                    name="teamName"
                                    value={teamName}
                                    aria-label="Team Name"
                                    onFocus={() => setTeamNameError(false)}
                                    onBlur={() => { if (!validateTeam()) setTeamNameError(true) }}
                                    onChange={(e) => setTeamName(e.target.value)}
                                    required
                                />
                                <span className="focus-input2" data-placeholder="Team Name" />
                            </div>}
                    </div>}
                <div
                    className={classnames('wrap-input2', 'validate-input', {
                        'alert-validate': nameError
                    })}
                    data-validate="Board Name is Required"
                >
                    <input
                        className={classnames('input2', 'input-theme', {
                            'has-val': boardName !== '',
                        })}
                        type="text"
                        name="boardName"
                        value={boardName}
                        aria-label="Board name"
                        onFocus={() => setNameError(false)}
                        onBlur={() => { if (!validateBoardName()) setNameError(true) }}
                        onChange={(e) => setBoardName(e.target.value)}
                        required
                    />
                    <span className="focus-input2" data-placeholder="Board Name" />
                </div>
                <div className="input-row margin-b-20">
                    <input type="checkbox" value={dateAsBoardName} checked={dateAsBoardName} onChange={setDate} />
                    Set Board name to Today's Date
                </div>
                <div className="wrap-input2 validate-input">
                    <textarea
                        className={classnames('input2', 'textarea-theme', {
                            'has-val': boardDescription !== ''
                        })}
                        name="description"
                        value={boardDescription}
                        aria-label="Board description"
                        onChange={(e) => setBoardDescription(e.target.value)}
                    />
                    <span className="focus-input2" data-placeholder="Description" />
                </div>
                <div className="input-row">
                    <Checkbox
                        value={redirectOnSubmit}
                        onChange={setRedirectOnSubmit}
                        label="Start retro on submit"
                    />
                </div>
                <div className="input-row">
                    <Checkbox
                        value={anonymousComments}
                        onChange={setAnonymousComments}
                        label="Add comments anonymously"
                    />
                </div>
                <div className="input-row">
                    <Checkbox
                        value={oneVotePerCard}
                        label="One vote per card"
                        onChange={setOneVotePerCard}
                    />
                </div>
                <div className="input-row">
                    <Checkbox
                        value={showAuthor}
                        label="Show card Author"
                        onChange={setShowAuthor}
                    />
                </div>
            </>)
        }
    };

    return (
        <div
            data-fade-in
            className="modal"
            onClick={closeModal}
            style={divStyle} >
            <div className="modal__content modal__content__maxWidth" onClick={e => e.stopPropagation()}>
                <div className="modal__header">
                    <div className="modal__back">
                        {showColumnSettings &&
                            <button className="modal__header--controls" onClick={goBackToBoard}><BsArrowLeft /></button>}
                    </div>
                    <h3 className="modal_title">{showColumnSettings ? 'Column Settings' : 'Create Board'}</h3>
                    <div className="close">
                        <button className="modal__header--controls" onClick={closeModal}>&times;</button>
                    </div>
                </div>
                <div className="modal__form">
                    {getModalContent()}
                </div>
                <div className="modal__submit">
                    <button onClick={submitForm} aria-label="submit form">{onSuccessLoader ? <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/button__loader.svg" alt="loader" /> : submitValue}</button>
                </div>
            </div>
        </div >
    );
}
export default CreateBoardModal;