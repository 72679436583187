import React from 'react';
import SocialIcons from './SocialIcons';

const Footer = () => {
    return <footer>
        <div className="logo__section">
            <a href='#home' className="logo">
                <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/Logo.webp" alt="Logo" />
            </a>
            <SocialIcons />
        </div>
        <div className="links__section">
            <ul>
                <li><a href="#features">Features</a></li>
                <li><a href="#pricing">Pricing</a></li>
                <li><a href="#contact">Contact</a></li>
                <li><a target="_blank" href="/terms-and-conditions">Terms and conditions</a></li>
            </ul>
        </div>
    </footer>;
};

export default Footer;