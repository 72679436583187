import HTTP from 'axios';
import get from 'lodash/get';
import { createToken } from './firebase';

//TODO: Update token refresh every 10mins instead of new token on every request

export const POST = async (url, data, options) => {
	const token = get(options, ['skipToken'], false) ? {} : await createToken();
	return HTTP.post(`${process.env.REACT_APP_API_ENDPOINT}${url}`, data, token);
};

export const PUT = async (url, data) => {
	const token = await createToken();
	return HTTP.put(`${process.env.REACT_APP_API_ENDPOINT}${url}`, data, token);
};

export const GET = async (url, data) => {
	const token = await createToken();
	token['params'] = data ? data : {};
	return HTTP.get(`${process.env.REACT_APP_API_ENDPOINT}${url}`, token);
};

export const DELETE = async (url, data) => {
	const token = await createToken();
	token['params'] = data ? data : {};
	return HTTP.delete(`${process.env.REACT_APP_API_ENDPOINT}${url}`, token);
};