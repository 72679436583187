import React, { useState } from 'react';
import ToggleSwitch from './Form/ToggleSwitch';
import SingleUserPremiumContainer from './Pricing/SingleUserPremiumContainer';
import TeamPlanContainer from './Pricing/TeamPlanContainer';

const FreeTialExpired = () => {
    const [isAnnual, setIsAnnual] = useState(false);

    return <div className="free-trial__container--expired">
        <p className="free-trial__reminder--expired">
            Free Trial has Expired
        </p>
        <div className="plan-duration">
            <ToggleSwitch
                option2="Annual"
                option1="Monthly"
                spacing={false}
                value={isAnnual}
                switchKey="subscription-plan"
                onChange={() => setIsAnnual(!isAnnual)}
            />
        </div>
        <div className="pricingContainer">
            <SingleUserPremiumContainer isAnnual={isAnnual} />
            <TeamPlanContainer isAnnual={isAnnual} />
        </div>
    </div>;
};

export default FreeTialExpired;