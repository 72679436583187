import React from 'react';
import classNames from 'classnames';
import './toggleSwitch.css';

const ToggleSwitch = props => {
    const { switchKey, value, onChange, option1, option2, spacing } = props;

    const classes = classNames('switch-button', { 'space-out': spacing })

    return <div className={classes} data-option={option2}>
        <input
            id={switchKey}
            type="checkbox"
            checked={value}
            className="switch-button-checkbox"
            onChange={() => onChange(switchKey)}
        />
        <label className="switch-button-label" htmlFor={switchKey}>
            <span className="switch-button-label-span">{option1}</span>
        </label>
    </div>;
};

export default ToggleSwitch;