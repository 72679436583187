import React from 'react';

import '../../styles/discussionInfo.css'

const DiscussionInfo = props => {
    const { allTopics, allUsersTotalVotes, completedTopics, completedVotes } = props;
    return <div className="discussion-info__container flex-center">
        <span>Completed {completedTopics} out of {allTopics} topics ({Math.floor((completedTopics / allTopics) * 100)}%)</span>
        <span>Completed {completedVotes} out of {allUsersTotalVotes} votes ({Math.floor((completedVotes / allUsersTotalVotes) * 100)}%)</span>
    </div>;
};

export default DiscussionInfo;