import React, { useState } from 'react';
import ChipInput from '../Form/ChipInput';
import RetroButton from '../Form/RetroButton';
import { BsFillInfoCircleFill } from 'react-icons/bs';

const AddMemberModal = props => {
    const { closeModal, displayModal, successHandler } = props;
    const [emails, setEmails] = useState([]);
    const [memberEmail, setMemberEmail] = useState('');
    const [emptyError, setEmptyError] = useState(false);

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    const addMembers = () => {
        if (emails.length !== 0) {
            successHandler(emails);
            setEmails([]);
        } else setEmptyError(true);
    };

    return <div
        data-fade-in
        style={divStyle}
        className="modal"
        onClick={closeModal}
    >
        <div className="modal__alert" onClick={e => e.stopPropagation()}>
            <div className="modal__header">
                <div className="modal__back width0" />
                <h3 className="modal_title">Add Team Members</h3>
                <div className="close">
                    <button className="modal__header--controls" onClick={closeModal}>&times;</button>
                </div>
            </div>
            <div className="modal__form modal__form--alert">
                <ChipInput
                    emails={emails}
                    value={memberEmail}
                    setEmails={setEmails}
                    emptyError={emptyError}
                    resetValue={() => setMemberEmail('')}
                    onChange={e => setMemberEmail(e.target.value)}
                />
                <span className="email__info"><BsFillInfoCircleFill />Invite will be sent to emails that do not have a retro account</span>
            </div>
            <div className="modal__alert--actions mt-20">
                <RetroButton
                    onClick={closeModal}
                    aria-label="close modal"
                >
                    Cancel
                </RetroButton>
                <RetroButton
                    theme="blue"
                    onClick={addMembers}
                    aria-label="add members"
                >
                    Add
                </RetroButton>
            </div>
        </div >
    </div >;;
};

export default AddMemberModal;