import React from 'react';

export const RetroControllerContext = React.createContext({
    votesPerUser: 5,
    showAuthor: true,
    oneVotePerCard: false,
    anonymousComments: true,
    setShowAuthor: () => { },
    setVotesPerUser: () => { },
    setOneVotePerCard: () => { },
    setAnonymousComments: () => { },
});

export const RetroControllerProvider = props => {
    const { children, value } = props;
    return <RetroControllerContext.Provider value={value}>{children}</RetroControllerContext.Provider>
};
