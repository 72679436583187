import React from 'react';
import times from 'lodash/times';
import { BsPlusCircleFill } from "react-icons/bs";
import { IoCloseCircleOutline } from 'react-icons/io5';

import '../../styles/votingSection.css';

const VotingSection = (props) => {
    const {
        boardID,
        cardKey,
        colIndex,
        disabled,
        votingMode,
        voteCount = 0,
        updateTotalVotes,
    } = props;

    const vote = action => {
        const updatedVote = action === 'add' ? voteCount + 1 : voteCount - 1;
        updateTotalVotes({ action, boardID, cardKey, colIndex, cardVoteCount: updatedVote });
    };

    return (
        <>
            {votingMode && <div className="vote-container" data-fade-in>
                <div className="show-votes">
                    {times(voteCount, i => {
                        return <button
                            key={i}
                            className='vote-icons'
                            aria-label="remove vote"
                            onClick={() => vote('remove')}
                        ><IoCloseCircleOutline /></button>
                    }
                    )}
                </div>
                <div className="add-vote">
                    <button onClick={() => vote('add')} aria-label="add vote" disabled={disabled}><BsPlusCircleFill /></button>
                </div>
            </div>}
        </>
    );
};

export default VotingSection;