import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import TimerPopOver from '../PopOver/TimerPopOver';
import { AiFillStop, AiOutlineUserSwitch } from 'react-icons/ai';
import { RiTimerLine, RiListSettingsLine } from 'react-icons/ri';
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';
import '../../styles/retroController.css';

const RetroController = (props) => {
    const {
        goPrev,
        goNext,
        isLastStep,
        endOfRetro,
        closeRetro,
        activeIndex,
        disableLeft,
        timerRunning,
        showSettings,
        prevTitle = '',
        nextTitle = '',
        showTimerInput,
        setShowSettings,
        handleStartTimer,
        handleResetTimer,
        setShowTimerInput,
        showFacilitatorModal,
        facilitationRequested,
        setShowFacilitatorModal,
    } = props;

    const [timerActive, setTimerActive] = useState(false);

    const timerRef = useRef(null);
    const settingsRef = useRef(null);

    const prevButtonClasses = classnames('retro-controller', 'retro-controller__button--prev', 'tooltip', 'expand', 'tooltip-top');

    const timerClasses = classnames('retro-controls', 'vertical-divider', {
        'tooltip': !timerActive,
        'expand': !timerActive,
        'tooltip-top': !timerActive,
    });
    const settingsClasses = classnames('retro-controls', {
        'tooltip': !showSettings,
        'expand': !showSettings,
        'tooltip-top': !showSettings,
    });

    const nextButtonClasses = classnames('retro-controller', 'retro-controller__button--next', 'tooltip', 'expand', 'tooltip-top');
    const endButtonClasses = classnames('retro-controller', 'retro-controller__button--next', 'tooltip', 'expand', 'tooltip-top');

    useEffect(() => {
        if (!timerActive)
            timerRef.current.blur();
    }, [timerActive]);

    useEffect(() => {
        if (!showSettings)
            settingsRef.current.blur();
    }, [showSettings]);

    const getPrevTooltipContent = () => {
        return !disableLeft ?
            (isLastStep && activeIndex > 1 ? 'Prev Topic' : `Prev Phase: ${prevTitle}`) : 'Beginning of Retro';
    };

    return <div className="retro-controller__container">
        <div
            className={prevButtonClasses}
            data-tooltip-content={getPrevTooltipContent()}
        >
            <button
                onClick={goPrev}
                disabled={disableLeft}
                aria-label="go to previous step"
                className="retro-controller__button"
            >
                <BsCaretLeftFill />
            </button>
        </div>
        <div className="retro-controls__container">
            {facilitationRequested && <div className="retro-controls vertical-divider tooltip expand tooltip-top" data-tooltip-content="Facilitator Request">
                <button aria-label="change facilitator" onClick={() => setShowFacilitatorModal(!showFacilitatorModal)}>
                    <AiOutlineUserSwitch />
                </button>
            </div>}
            <div className={timerClasses} data-tooltip-content="Set Timer">
                <button
                    ref={timerRef}
                    aria-label="set timer"
                    onClick={() => setTimerActive(!timerActive)}
                >
                    <RiTimerLine />
                </button>
                {timerActive &&
                    <TimerPopOver
                        timerRunning={timerRunning}
                        showTimerInput={showTimerInput}
                        handleStartTimer={handleStartTimer}
                        handleResetTimer={handleResetTimer}
                        setShowTimerInput={setShowTimerInput}
                        closePopOver={() => setTimerActive(false)}
                    />
                }
            </div>
            <div className={settingsClasses} data-tooltip-content="Settings">
                <button
                    ref={settingsRef}
                    aria-label="board settings"
                    onClick={() => setShowSettings(!showSettings)}
                >
                    <RiListSettingsLine />
                </button>
            </div>
        </div>
        {endOfRetro ? <div
            data-tooltip-content="End Retro"
            className={endButtonClasses}
        >
            <button
                onClick={closeRetro}
                aria-label="end retro"
                className="retro-controller__button retro-controller__button--alert"
            >
                <AiFillStop />
            </button>
        </div> : <div
            data-tooltip-content={isLastStep ? 'Next Topic' : `Next Phase: ${nextTitle}`}
            className={nextButtonClasses}
        >
            <button
                onClick={goNext}
                aria-label="go to next step"
                className="retro-controller__button "
                disabled={isLastStep && endOfRetro}
            >
                <BsCaretRightFill />
            </button>
        </div>}
    </div>;
};

export default RetroController;