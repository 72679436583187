import React from 'react';

import '../../styles/memberIcon.css';

const MemberIcon = props => {
    // const { user } = props;

    return (
        <button className="members__status" aria-label="Member status">RK</button>
    );
};

export default MemberIcon;