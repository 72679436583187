import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { BiAddToQueue } from 'react-icons/bi';
import { AiOutlineSend } from 'react-icons/ai';
import { PUT } from '../../services/HTTPHandler';

const TextInput = props => {
    const {
        label,
        index,
        teamID,
        boardID,
        className,
        disabled,
        columnTheme,
        handleSubmit,
        chosenTeamMember,
        isAppreciationColumn,
        appreciationsInputError,
        setAppreciationsInputError,
        ...rest
    } = props;
    const columnCardInputRef = useRef(null);
    const [content, setContent] = useState('');
    const [isTypingActive, setTypingActive] = useState(false);
    const textInputClasses = classnames('input2', 'col-input', 'input-theme', 'comment-input', { 'col-has-val': content !== '' });

    const onSubmit = event => {
        if (event)
            event.preventDefault();
        if (content.trim() !== '') {
            let assignToMember = '';
            if (isAppreciationColumn) {
                assignToMember = chosenTeamMember;
                if (chosenTeamMember === '') {
                    setAppreciationsInputError(true);
                    return;
                } else setAppreciationsInputError(false);
            }
            handleSubmit(index, { content, cardTheme: columnTheme, assignToMember, isAppreciationColumn })(event);
        }
        setContent('');
    };

    useEffect(() => {
        if (index === 0)
            columnCardInputRef.current.focus();
    }, [index]);

    useEffect(() => {
        if (content !== '' && !isTypingActive)
            PUT('/activity/membersTyping', { boardID, membersTyping: true, teamID }).then(() => setTypingActive(true));
        else if (content === '' && isTypingActive)
            PUT('/activity/membersTyping', { boardID, membersTyping: false, teamID }).then(() => setTypingActive(false));
    }, [content]);

    return (
        <div
            className={classnames(className, 'wrap-input2', 'validate-input', {
                'alert-validate': appreciationsInputError,
            })}
            data-validate="Please choose a team member"
        >
            <form onSubmit={onSubmit}>
                <input
                    type="text"
                    value={content}
                    disabled={disabled}
                    ref={columnCardInputRef}
                    className={textInputClasses}
                    onChange={(event) => setContent(event.target.value)}
                    {...rest}
                />
                <label className="focus-input2" data-placeholder={label} />
                {isTypingActive ?
                    <AiOutlineSend data-fade-in className="input-icon" onClick={() => onSubmit()} />
                    : <BiAddToQueue data-fade-in className="input-icon" onClick={() => columnCardInputRef.current.focus()} />}
            </form>
        </div>
    );
};
//Change input component name
export default TextInput;