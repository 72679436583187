import React, { useContext } from 'react';
import Link from '../Form/Link';
import classNames from 'classnames';
import UpgradeButton from '../Form/UpgradeButton';
import ProfileButton from '../Dashboard/ProfileButton';
import Notifications from './Notifications';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import '../../styles/Navigation/navbar.css';

const Navbar = props => {
    const user = useContext(CurrentUserContext);
    const { notifications = {}, isSettingsPage, showUpgrade, showNotification, userStatus = {}, redirectLogoToDashboard } = props;
    const navbarLogoClasses = classNames('navbar__logo-section', {
        'navbar__logo-section--settings': isSettingsPage,
    });

    return (
        <nav className="navbar">
            <div className={navbarLogoClasses}>
                <a href={`${redirectLogoToDashboard ? '/dashboard' : '#'}`}>
                    <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/Logo.webp" alt="Logo" />
                </a>
            </div>
            <div className="navbar__profile-section">
                {showUpgrade && <UpgradeButton userStatus={userStatus} />}
                {showNotification && <Notifications
                    notifications={notifications}
                    isActive={Object.keys(notifications).length !== 0}
                />}
                {user.uid ?
                    <ProfileButton
                        photoURL={user.photoURL}
                        withBorder={isSettingsPage}
                        displayName={user.displayName}
                    />
                    : <div className="anonymous-user__wrapper">
                        <Link
                            linkAsButton
                            href="/login"
                            aria-label="login"
                        >
                            Login
                        </Link>
                        <Link
                            linkAsButton
                            theme="blue"
                            href="/signup"
                            aria-label="signup"
                        >
                            Try for FREE
                        </Link>
                    </div>
                }
            </div>
        </nav>
    );
};

export default Navbar;