import React, { useState } from 'react';
import Footer from '../components/Footer';
import SocialIcons from '../components/SocialIcons';
import ToggleSwitch from '../components/Form/ToggleSwitch';
import LandingNavbar from '../components/Navigation/LandingNavbar';
import TeamPlanContainer from '../components/Pricing/TeamPlanContainer';
import FreeTrialContainer from '../components/Pricing/FreeTrialContainer';
import MultipleTeamDiscount from '../components/Pricing/MultipleTeamDiscount';
import SingleUserPremiumContainer from '../components/Pricing/SingleUserPremiumContainer';

import '../styles/home.css';

const Home = () => {
	const [noOfTeams, setNoOfTeams] = useState(1);
	const [isAnnual, setIsAnnual] = useState(false);

	return (
		<>
			<LandingNavbar />
			<div className="main__content" id="landing__content">
				<section className="landing__section" id="home">
					<div className="section__content">
						<h1>Simple facilitated retrospectives for agile teams</h1>
						<h2>Retrospective tool that facilitates teams to asynchronously grow and stay agile.</h2>
						<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing.png" alt="landing"></img>
					</div>
				</section>
				<section className="landing__section" id="howItWorks">
					<div className="section__content">
						<h2 className="section__title">Simple 4 step process that works</h2>
					</div>
					<div className="feature__row odd-row">
						<div className="step__description">
							<h3>Comments</h3>
							<p>The first step in Retro's agile framework lets your scrum master take control of the Scrum/Kanban board through comments. Identifying key topics, talking points, and tasks that the team should prioritize. Cards can be added anonymously.</p>
						</div>
						<div className="step__image">
							<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/comments.svg" alt="comments" />
						</div>
					</div>
					<div className="feature__row connect">
						<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/leftToRight.svg" alt="connect" />
					</div>
					<div className="feature__row">
						<div className="step__description">
							<h3>Grouping</h3>
							<p>The second step encourages collaboration. Team members group the cards into thematic buckets. Cards of similar contexts from multiple varying themes can be grouped together. </p>
						</div>
						<div className="step__image">
							<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/group.svg" alt="group" />
						</div>
					</div>
					<div className="feature__row connect">
						<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/rightToLeft.svg" alt="connect" />
					</div>
					<div className="feature__row odd-row">
						<div className="step__description">
							<h3>Voting</h3>
							<p>Keeping in line with the scrum team dynamic, everyone gets a vote! Team members vote on topics, action items, and issues that should be discussed. Votes can be equally split per person or the scrum master can customize their distribution.</p>
						</div>
						<div className="step__image">
							<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/vote.svg" alt="vote" />
						</div>
					</div>
					<div className="feature__row connect">
						<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/leftToRight.svg" alt="connect" />
					</div>
					<div className="feature__row">
						<div className="step__description">
							<h3>Discuss</h3>
							<p>The final step is the discussion! Everyone can engage in project-related conversation. There is no confusion on the agenda and people can openly share opinions, feedback, and solutions. Topics are discussed in descending order of votes. As follow-up tasks come up during the discussion, create action items on the fly that can be assigned to any team member.</p>
						</div>
						<div className="step__image">
							<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/Discuss.svg" alt="discuss" />
						</div>
					</div>
				</section>
				<section className="landing__section" id="features">
					<div className="section__content">
						<h2 className="section__title">Features</h2>
						<div className="feature__row odd-row">
							<div className="feature__description">
								<h3>Asynchronous Retrospective</h3>
								<p>With Retro, team members interact on their own terms. Communication is back and forth, anytime, anywhere. Don't limit big decisions to one meeting. Maintain a continuous chain of dialogue.</p>
							</div>
							<div className="feature__image">
								<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/async.svg" alt="async" />
							</div>
						</div>
						<div className="feature__row">
							<div className="feature__description">
								<h3>Guided Facilitation</h3>
								<p>Stay on topic with the right structure. Our Guided Facilitation mechanisms designate themes or topics for each Retro Board. That way, your team stays engaged on the important subjects. Retro promotes healthy dialogue and streamlines results.</p>
							</div>
							<div className="feature__image">
								<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/design.svg" alt="guided" />
							</div>
						</div>
						<div className="feature__row odd-row">
							<div className="feature__description">
								<h3>Appreciations</h3>
								<p>Boost team morale by recognizing people who go above and beyond. Our appreciation columns capture highlights and individual achievements from each scrum sprint cycle. This trackable metric incentivizes collaborative behavior and improves performance within agile teams.</p>
							</div>
							<div className="feature__image">
								<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/appreciations.svg" alt="appreciations" />
							</div>
						</div>
						<div className="feature__row">
							<div className="feature__description">
								<h3>Extensive Customization</h3>
								<p>Every team is unique, your project management should reflect that. Retro offers an array of customization features like team member anonymity during the comments phase, card author display, voting limits, and much more. Teams have complete control over their board and how they communicate.</p>
							</div>
							<div className="feature__image">
								<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/settings.svg" alt="settings" />
							</div>
						</div>
						<div className="feature__row odd-row">
							<div className="feature__description">
								<h3>Action Items</h3>
								<p>Easily compile your tasks from different boards and teams, all in one place. It can be hard to keep track of your action items when you are on more than one project. Retro gives you the flexibility to manage all your action items in one place.</p>
							</div>
							<div className="feature__image">
								<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/collaborate.svg" alt="action items" />
							</div>
						</div>
					</div>
				</section>
				<section className="landing__section" id="pricing">
					<div className="section__content">
						<h2 className="section__title">Pricing</h2>
						<div className="plan-duration">
							<ToggleSwitch
								option2="Annual"
								option1="Monthly"
								spacing={false}
								value={isAnnual}
								switchKey="subscription-plan"
								onChange={() => setIsAnnual(!isAnnual)}
							/>
						</div>
						<MultipleTeamDiscount isAnnual={isAnnual} noOfTeams={noOfTeams} />
						<div className="row flex-start">
							<FreeTrialContainer />
							<SingleUserPremiumContainer isAnnual={isAnnual} />
							<TeamPlanContainer modifyPrice noOfTeams={noOfTeams} setNoOfTeams={setNoOfTeams} isAnnual={isAnnual} />
						</div>
					</div>
				</section>
				<section className="landing__section" id="contact">
					<div className="section__content">
						<h2 className="section__title">Contact</h2>
						<div className="feature__row odd-row">
							<div className="feature__description">
								<p>Reach out to <a href="mailTo:support@retrospective.pro"><b>support@retrospective.pro</b></a> with any questions or suggestions.</p>
							</div>
							<div className="feature__image rounded">
								<p className="designed-by">Developed by</p>
								<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/landing-page%20assets/me.jpeg" alt="me" />
								<SocialIcons />
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		</>
	);
};

export default Home;