import classNames from 'classnames';
import React, { useState } from 'react';
import Checkbox from '../Form/Checkbox';
import { BiErrorAlt } from 'react-icons/bi';

import './modal.css';
import ChipInput from '../Form/ChipInput';

const CreateTeamModal = props => {

    const { onSubmit, displayModal, errorStatus, onSuccessLoader, setErrorStatus } = props;
    const [emails, setEmails] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [memberEmail, setMemberEmail] = useState('');
    const [redirectOnSubmit, setRedirectOnSubmit] = useState(true);
    const [inviteTeamMembers, setInviteTeamMembers] = useState(false);

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    function closeModal(e) {
        e.stopPropagation();
        resetForm();
        props.closeModal();
    }

    function resetForm() {
        setEmails([]);
        setTeamName('');
        setMemberEmail('');
        setErrorStatus(-1);
        setNameError(false);
        setInviteTeamMembers(false);
        setRedirectOnSubmit(true);
    }

    function validateForm() {
        return teamName !== '';
    }

    function submitForm() {
        if (validateForm()) {
            setNameError(false);
            const teamSettings = {
                emails,
                redirectOnSubmit,
                membersInvitedOnCreate: inviteTeamMembers,
            };
            onSubmit({ teamName, teamSettings });
            resetForm();
        }
        else if (teamName === '') {
            setNameError(true);
        }
    };

    const getFormContent = () => {
        switch (errorStatus) {
            case 402: return <>
                <BiErrorAlt className="error__icon" />
                <h3>Error, Subscription required!</h3>
                <p>Please upgrade your plan to add more teams.</p>
            </>;
            case 426: return <>
                <BiErrorAlt className="error__icon" />
                <h3>Error, Subscription capacity reached!</h3>
                <p>Please upgrade your plan to add more teams.</p>
            </>;
            case -1:
            default: return <>
                <div
                    className={classNames('wrap-input2', 'validate-input', {
                        'alert-validate': nameError
                    })}
                    data-validate="Name is Required"
                >
                    <input
                        className={classNames('input2', 'input-theme', {
                            'has-val': teamName !== '',
                        })}
                        type="text"
                        name="teamName"
                        value={teamName}
                        aria-label="Team name"
                        onFocus={() => setNameError(false)}
                        onBlur={() => { if (!validateForm()) setNameError(true) }}
                        onChange={e => setTeamName(e.target.value)}
                        required
                    />
                    <span className="focus-input2" data-placeholder="Team Name" />
                </div>
                <div className="input-row">
                    <Checkbox
                        value={redirectOnSubmit}
                        onChange={setRedirectOnSubmit}
                        label="Redirect to Team Room on Submit"
                    />
                </div>
                <div className="input-row">
                    <Checkbox
                        value={inviteTeamMembers}
                        label="Invite Team Members"
                        onChange={setInviteTeamMembers}
                    />
                </div>
                {inviteTeamMembers && <div className="input-row" data-fade-in>
                    <ChipInput
                        emails={emails}
                        value={memberEmail}
                        setEmails={setEmails}
                        resetValue={() => setMemberEmail('')}
                        onChange={e => setMemberEmail(e.target.value)}
                    />
                </div>}
            </>;
        }
    };

    return (
        <div
            data-fade-in
            style={divStyle}
            className="modal"
            onClick={closeModal}
        >
            <div className="modal__content modal__content__maxWidth" onClick={e => e.stopPropagation()}>
                <div className="modal__header">
                    <div className="modal__back" />
                    <h3 className="modal_title">Create Team</h3>
                    <div className="close">
                        <button className="modal__header--controls" onClick={closeModal}>&times;</button>
                    </div>
                </div>
                <div className="modal__form">
                    {getFormContent()}
                </div>
                <div className="modal__submit">
                    <button onClick={submitForm} aria-label="submit form">{onSuccessLoader ? <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/button__loader.svg" alt="loader" /> : 'Submit'}</button>
                </div>
            </div >
        </div >
    );
}
export default CreateTeamModal;