import React from 'react';
import Link from './Form/Link';
import { BiErrorAlt } from 'react-icons/bi';
import Navbar from './Navigation/Navbar';

import '../styles/pageNotFound.css';

const PageNotFound = () => {
    return <>
        <Navbar redirectLogoToDashboard />
        <div className="invalid-order__container">
            <BiErrorAlt />
            <span className="status-code">404</span>
            <span className="error__description">Sorry, the page you're looking for cannot be found</span>
            <Link aria-label="dashboard redirect" href="/dashboard">Go to dashboard</Link>
        </div>
    </>;
};

export default PageNotFound;