import get from 'lodash/get';
import alertify from 'alertifyjs';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import VotingSection from './VotingSection';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { POST } from '../../services/HTTPHandler';
import { getFormattedName } from '../../utils/user';
import BorderlessInput from '../Form/BorderlessInput';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { RetroControllerContext } from '../../contexts/RetroControllerContext';

import '../../styles/card.css';

const Card = (props) => {
    const {
        vote,
        author,
        cardKey,
        content,
        members,
        boardID,
        isOwnwer,
        colIndex,
        retroStep,
        cardTheme,
        draggable,
        isExpanded,
        mergeGroup,
        votingMode,
        onDragEnd,
        onDragStart,
        onDragEnter,
        onDragLeave,
        baseKey = '',
        isMergedCard,
        maxVoteReached,
        updateTotalVotes,
        isAppreciationColumn,
    } = props;

    const user = useContext(CurrentUserContext);

    const { showAuthor, anonymousComments, oneVotePerCard } = useContext(RetroControllerContext);

    const cardClasses = classnames('card', {
        'merged-cards': mergeGroup,
        'card__no-icons': retroStep !== 0,
        'overflow__hidden': retroStep !== 0,
        'postion-realtive': isExpanded && mergeGroup,
        'postion-absolute': !isExpanded && mergeGroup,
        'blur': !isOwnwer && retroStep === 0 && anonymousComments,
    });
    const cardStausClasses = classnames('card-type__status', `column__theme--${cardTheme}`);

    const [editCard, setEditCard] = useState(false);
    const voteCount = get(vote, 'cardVoteCount', 0);
    const [locallyUpdatedContent, setLocallyUpdatedContent] = useState(content);
    const validateCardContent = cardContent => cardContent.trim() !== '';

    const submitCardContent = updatedContent => {
        if (validateCardContent(updatedContent) && updatedContent !== content)
            POST('/activity/updateCardContent', { boardID, colIndex, cardKey, content: updatedContent })
                .then(() => {
                    alertify.notify('Card Updated', 'success', 1.5);
                    setLocallyUpdatedContent(locallyUpdatedContent);
                    setEditCard(false);
                })
                .catch(err => {
                    alertify.notify('Unable to update', 'error', 3, function () { console.log(err) });
                });
    };

    const deleteCard = (boardID, colIndex, cardKey) => {
        POST('/activity/deleteCard', { boardID, colIndex, cardKey })
            .then(() => { alertify.notify('Card Deleted', 'success', 1.5) })
            .catch(err => {
                alertify.notify('Unable to Delete', 'error', 3, function () { console.log(err) });
            });
    };

    return (
        <>
            {!isMergedCard && !isAppreciationColumn && <VotingSection
                boardID={boardID}
                cardKey={cardKey}
                colIndex={colIndex}
                voteCount={voteCount}
                votingMode={votingMode}
                updateTotalVotes={updateTotalVotes}
                disabled={maxVoteReached || (oneVotePerCard && voteCount >= 1)}
            />}
            <div
                owner={author}
                cardkey={cardKey}
                basekey={baseKey}
                colindex={colIndex}
                cardtheme={cardTheme}
                draggable={draggable}
                onDragEnd={onDragEnd}
                className={cardClasses}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                content={!isOwnwer && retroStep === 0 && anonymousComments ? btoa(content) : content}
                onDragStart={(e) => onDragStart(e, { baseKey, cardKey, colIndex, owner: author, cardTheme, content })}
            >
                <div className="card-type"><div className={cardStausClasses} /></div>
                {retroStep === 0 && <div className="card-icons">
                    <button
                        data-tooltip-content="Edit Card"
                        onClick={() => setEditCard(!editCard)}
                        className="card-icon__edit tooltip expand"
                    >
                        <FaRegEdit />
                    </button>
                    <button
                        data-tooltip-content="Delete Card"
                        className="card-icon__delete tooltip expand"
                        onClick={() => deleteCard(boardID, colIndex, cardKey)}
                    >
                        <RiDeleteBin2Fill />
                    </button>
                </div>}
                {!editCard ? (!isOwnwer && retroStep === 0 && anonymousComments ? btoa(content) : content) :
                    <BorderlessInput
                        aria-label="Card content"
                        value={locallyUpdatedContent}
                        handleSubmit={submitCardContent}
                        setValue={setLocallyUpdatedContent}
                    />
                }
                {showAuthor && <div className="card__author-section">
                    <div className="profile__container tooltip expand tooltip-bottom" data-tooltip-content={getFormattedName(user.displayName)}>
                        <img src={get(members[author], ['photoURL'], '')} draggable={false} alt="user profile" />
                    </div>
                </div>}
            </div>
        </>
    );
};

export default Card;