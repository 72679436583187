export const getCardTypeSVGIcon = cardType => {
    switch (cardType) {
        case 'visa': return <img className="credit-card__icon" src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/flat/visa.svg" alt="visa icon" />;
        case 'mastercard': return <img className="credit-card__icon" src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/flat/mastercard.svg" alt="master icon" />;
        case 'american-express': return <img className="credit-card__icon" src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/flat/amex.svg" alt="amex icon" />;
        case 'diners-club': return <img className="credit-card__icon" src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/flat/diners.svg" alt="diners icon" />;
        case 'discover': return <img className="credit-card__icon" src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/flat/discover.svg" alt="discover icon" />;
        case 'jcb': return <img className="credit-card__icon" src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/flat/jcb.svg" alt="jcb icon" />;
        default: <></>;
    }
};