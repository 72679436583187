import { useMemo, useState } from "react";
import { sendPasswordResetEmail, signin } from '../../helpers/auth';
import { Input, Button, Link } from "@nextui-org/react";
import { IoWarningOutline } from "react-icons/io5";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { emailRegex } from "../../utils/regex";

const LoginForm = ({ setIsNewUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [signinError, setSigninError] = useState(null);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);

    const validateEmail = (value) => value.match(emailRegex);

    const isEmailInvalid = useMemo(() => {
        if (email === "") return false;

        return validateEmail(email) ? false : true;
    }, [email]);

    const sendResetPasswordLink = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(email);
    };

    const isLoginValid = () => {
        return email !== '' && password !== '';
    };

    const handleLoginSubmit = async e => {
        setIsLoading(true);
        e.preventDefault();
        setSigninError(null);
        if (isLoginValid())
            try {
                await signin(email, password);
            } catch (error) {
                setSigninError('Invalid Credentials');
            }
        setIsLoading(false);
    };

    return (
        <form className="login__form" onSubmit={handleLoginSubmit}>
            {(signinError && !forgotPassword) && <p className="form-error">
                <IoWarningOutline />
                {signinError}
            </p>}
            <Input
                isRequired
                isClearable
                type="email"
                label="Email"
                value={email}
                className="input__wrapper"
                onValueChange={setEmail}
                aria-label="Enter Email"
                isInvalid={signinError && isEmailInvalid}
                color={signinError && isEmailInvalid ? "danger" : "default"}
                errorMessage={signinError && isEmailInvalid && "Please enter a valid email"}
            />
            {!forgotPassword &&
                <Input
                    isRequired
                    label="Password"
                    value={password}
                    className="input__wrapper"
                    onValueChange={setPassword}
                    aria-label="Enter password"
                    type={isPasswordHidden ? "password" : "text"}
                    endContent={
                        <button type="button" onClick={() => setIsPasswordHidden(!isPasswordHidden)}>
                            {isPasswordHidden ? (
                                <IoEye className="text-2xl text-default-400 pointer-events-none" />
                            ) : (
                                <IoEyeOff className="text-2xl text-default-400 pointer-events-none" />
                            )}
                        </button>
                    }
                />
            }
            {!forgotPassword && <>
                <Button isLoading={isLoading} color="primary" type="submit" data-fade-in fullWidth>
                    Login
                </Button>
                <div data-fade-in className="signup">New Here? &nbsp;
                    <Link
                        color="primary"
                        variant="light"
                        underline="always"
                        aria-label="Go to Signup form"
                        onClick={() => setIsNewUser(true)}
                    >
                        Signup
                    </Link>
                </div>
                <Link data-fade-in size="sm" onClick={() => setForgotPassword(true)}>Forgot Password?</Link>
            </>}
            {forgotPassword && <>
                <Button color="primary" onClick={sendResetPasswordLink} data-fade-in fullWidth>
                    Reset Password
                </Button>
                <Link data-fade-in size="sm" onClick={() => setForgotPassword(false)}>Go back to Login</Link>
            </>}
        </form>
    );
};

export default LoginForm;