import React from 'react';

export const CurrentUserContext = React.createContext({
    uid: '',
    email: '',
    photoURL: '',
    displayName: '',
    emailVerified: false,
});

export const CurrentUserProvider = props => {
    const { children, displayName, email, photoURL, uid, emailVerified } = props;
    return <CurrentUserContext.Provider value={{ displayName, email, photoURL, uid, emailVerified }}>{children}</CurrentUserContext.Provider>
};