import React from 'react';
import classNames from 'classnames';
// import { SiSlack } from 'react-icons/si';
import { FaCreditCard } from 'react-icons/fa';
import { BiUserCircle } from 'react-icons/bi';
import { RiLockPasswordLine } from 'react-icons/ri';
import '../../styles/settingsSidebar.css';

const SettingsSidebar = props => {
    const { currentSetting, setCurrentSetting } = props;

    const settingsListClickHandler = type => {
        window.history.replaceState(null, "Settings - Retrospective", "/settings/" + type);
        setCurrentSetting(type);
    };

    return <div className="settings-sidebar">
        <div className="settings-sidebar__content">
            <ul>
                <li
                    className={classNames({ 'active': currentSetting === 'user' })}
                    onClick={() => settingsListClickHandler('user')}
                >
                    <BiUserCircle />
                    Profile
                </li>
                {/* <li
                    className={classNames({ 'active': currentSetting === 'slack' })}
                    onClick={() => setCurrentSetting('slack')}
                >
                    <SiSlack />
                    Slack Integration
                </li> */}
                <li
                    className={classNames({ 'active': currentSetting === 'password' })}
                    onClick={() => settingsListClickHandler('password')}
                >
                    <RiLockPasswordLine />
                    Manage Password
                </li>
                <li
                    className={classNames({ 'active': currentSetting === 'subscription' })}
                    onClick={() => settingsListClickHandler('subscription')}
                >
                    <FaCreditCard />Subscription
                </li>
            </ul>
        </div>
    </div>;
};

export default SettingsSidebar;