import React from 'react';
import map from 'lodash/map';
import classnames from 'classnames';
import '../../styles/template.css';

const Template = props => {
	const { template } = props;
	const { columns } = template;

	return <div className="template">
		{map(columns, (column, i) => {
			return <div key={i} className="template-column__container">
				<div className={classnames('template__column', `column__theme--${column.columnTheme}`)}>
					<div className="template-column__text">
						<div className="template-column__title">{column.columnTitle}</div>
						<div className="template-column__description">{column.columnDescription}</div>
					</div>
				</div>
				<div className="fading-column-effect" />
			</div>
		})}
	</div>
};

export default Template;