import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { POST } from '../services/HTTPHandler';
import Navbar from '../components/Navigation/Navbar';
import CreditCardForm from '../components/Checkout/CreditCardForm';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import SubscriptionInfo from '../components/Checkout/SubscriptionInfo';
import '../styles/checkout.css';

const Checkout = props => {
    document.title = 'Checkout - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
    const { upgradeTo = '' } = props;
    const { search } = useLocation();
    const isAnnualFromQuery = new URLSearchParams(search).get('isAnnual');
    const noOfTeamsFromQuery = new URLSearchParams(search).get('noOfTeams');

    const user = useContext(CurrentUserContext);
    const [isTeam, setIsTeam] = useState(upgradeTo === 'team');
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [creditCardFormError, setCreditCardFormError] = useState(false);
    const [isAnnual, setIsAnnual] = useState(isAnnualFromQuery === 'true');
    const [noOfTeams, setNoOfTeams] = useState(parseInt(noOfTeamsFromQuery) || 1);
    const [creditCardFormErrorMessage, setCreditCardFormErrorMessage] = useState('');

    const submitPayment = (card, billingInfo) => {
        setIsPaymentLoading(true);
        const payload = { card, billingInfo, options: { noOfTeams, isAnnual, isTeam } };
        // TODO : encrypt the payload
        POST('/order/subscribe', { payload })
            .then(response => {
                setIsPaymentLoading(false);
                // eslint-disable-next-line no-restricted-globals
                location.assign('/order/success');
                console.log(response);
            }).catch(err => {
                setIsPaymentLoading(false);
                setCreditCardFormError(true);
                setCreditCardFormErrorMessage(err.response.data.message);
                console.log(err.response);
            });
    };

    return <>
        <Navbar redirectLogoToDashboard />
        <div className="page-title">
            <h1>Checkout</h1>
        </div>
        <div className="checkout__container">
            <SubscriptionInfo
                isTeam={isTeam}
                isAnnual={isAnnual}
                noOfTeams={noOfTeams}
                setIsTeam={setIsTeam}
                setNoOfTeams={setNoOfTeams}
                togglePlanDuration={() => setIsAnnual(!isAnnual)}
            />
            <CreditCardForm
                submitPayment={submitPayment}
                isPaymentLoading={isPaymentLoading}
                creditCardFormError={creditCardFormError}
                setCreditCardFormError={setCreditCardFormError}
                creditCardFormErrorMessage={creditCardFormErrorMessage}
            />
        </div>
    </>;
};

export default Checkout;