import React from 'react';
import get from 'lodash/get';
import Link from '../Form/Link';

import '../../styles/orderConfirmed.css';

const OrderConfirmed = props => {
    const { invoiceInfo, entitlements, subscription } = props;

    return <div className="order-confirmed__container">
        <h1 className="title-color mg-50">Congrats, Your subcription is now <span className="color-green">ACTIVE!</span></h1>
        <div className="order-confirmed__box">
            <h2>Order Confirmed</h2>
            <div className="order-decription__price">
                ${invoiceInfo.amountPaid / 100}
            </div>
            <div className="order-decription">
                <div className="order-decription__row">
                    <div className="order-description__row--label">Invoice number</div>
                    <div className="order-description__row--value">{invoiceInfo.number}</div>
                </div>
                <div className="order-decription__row">
                    <div className="order-description__row--label">Payment date</div>
                    <div className="order-description__row--value">{new Date(invoiceInfo.invoiceDate * 1000).toDateString()}</div>
                </div>
                <div className="order-decription__row">
                    <div className="order-description__row--label">Next invoice date</div>
                    <div className="order-description__row--value">{new Date(subscription.nextInvoiceDate * 1000).toDateString()}</div>
                </div>
                <div className="order-decription__row">
                    <div className="order-description__row--label">Plan</div>
                    <div className="order-description__row--value">{entitlements.isTeam ? 'Team' : 'Single User'}</div>
                </div>
                <div className="order-decription__row">
                    <div className="order-description__row--label">Quantity</div>
                    <div className="order-description__row--value">{subscription.quantity}</div>
                </div>
                <div className="order-decription__row">
                    <div className="order-description__row--label">Billing Interval</div>
                    <div className="order-description__row--value">{get(subscription, ['plan', 'interval'], 'month') === 'month' ? 'Monthly' : 'Yearly'}</div>
                </div>
            </div>
            <Link
                openInNewTab
                className="margin-b-20"
                aria-label="View stripe invoice"
                href={invoiceInfo.hostedInvoiceUrl}
            >
                View invoice in Stripe
            </Link>
        </div>
        <div className="order-actions__row">
            <Link
                linkAsButton
                href="/settings/subscription"
                aria-label="redirect to settings page"
            >
                Manage Subscription
            </Link>
            <Link
                linkAsButton
                theme="blue"
                href="/dashboard"
                className="mg-lt__20"
                aria-label="redirect to dashboard"
            >
                Go to Dashboard
            </Link>
        </div>
    </div>;
};

export default OrderConfirmed;