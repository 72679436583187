import React, { useRef, useState } from 'react';
import { BsPlayFill, BsStopFill } from 'react-icons/bs';
// import { MdExposurePlus1 } from 'react-icons/md';
import PopOver from './PopOver';
import './popOver.css';

const TimerPopOver = props => {
    const {
        closePopOver,
        timerRunning,
        showTimerInput,
        handleStartTimer,
        handleResetTimer,
        setShowTimerInput,
    } = props;
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const minutesRef = useRef(null);
    const secondsRef = useRef(null);

    const handlePlayClick = () => {
        if (seconds !== 0 || minutes !== 0) {
            handleStartTimer(minutes, seconds, { start: true });
            setShowTimerInput(false);
        }
    };

    const onSecondsChange = e => {
        if (e.target.value > 59)
            setSeconds(59);
        else if (e.target.value < 0)
            setSeconds(0);
        else
            setSeconds(e.target.value)
    };

    const onMinutesChange = e => {
        if (e.target.value < 0)
            setMinutes(0);
        else
            setMinutes(e.target.value)
    }

    return <PopOver placement="top" closePopOver={closePopOver}>
        <div className="controls-wrapper tooltip expand" data-tooltip-content="Start Timer">
            <button disabled={timerRunning}><BsPlayFill onClick={handlePlayClick} /></button>
        </div>
        <div className="timer-input-wrapper">
            <div className="minutes-wrapper">
                <input
                    type="number"
                    value={minutes}
                    ref={minutesRef}
                    onChange={onMinutesChange}
                    disabled={!showTimerInput}
                    onFocus={() => minutesRef.current.select()}
                />
            </div>
            <b>MM</b>
            <span className="time-seperator">:</span>
            <div className="seconds-wrapper">
                <input
                    type="number"
                    value={seconds}
                    ref={secondsRef}
                    disabled={!showTimerInput}
                    onChange={onSecondsChange}
                    onFocus={() => secondsRef.current.select()}
                />
            </div>
            <b>SS</b>
        </div>
        <div className="controls-wrapper tooltip expand" data-tooltip-content="Stop Timer">
            <button onClick={() => handleResetTimer(true)} disabled={showTimerInput}>
                <BsStopFill />
            </button>
        </div>
        {/* <div className="controls-wrapper tooltip expand" data-tooltip-content="Add a minute">
            <button onClick={() => handleStartTimer(1, 0, { add: true })}>
                <MdExposurePlus1 />
            </button>
        </div> */}
    </PopOver>;
};

export default TimerPopOver;