import React from 'react';
import classNames from 'classnames';

import './link.css';

const Link = props => {
    const { href, className, children, linkAsButton, openInNewTab, theme, ...rest } = props;

    const classes = classNames(className, 'retro-link', {
        'retro-link__button': linkAsButton,
        'inverted-theme': theme === 'blue',
    });

    const target = openInNewTab ? '_blank' : '_self';

    return <a
        href={href}
        target={target}
        className={classes}
        {...rest}
    >
        {children}
    </a>;
};

export default Link;