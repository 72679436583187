export const getProfilePicture = (firstName = '', lastName = '') => {
    const name = `${firstName.replace(/\s/g, '')}+${lastName.replace(/\s/g, '')}`;
    const background = 'random';
    if (name !== '+')
        return `https://ui-avatars.com/api/?name=${name}&background=${background}&rounded=true`;
    return 'https://raw.githubusercontent.com/arvindrk/my-retro/master/user.svg';
};

export const getFirstName = (displayName = '') => (displayName.split('.')[0]);

export const getFormattedName = (displayName = '') => (displayName.split('.').join(' '));

export const getTrialDaysLeft = (trialEndDate) => {
    return Math.floor((trialEndDate * 1000 - Date.now()) / (1000 * 3600 * 24)) + 1;
};