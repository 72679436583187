import React, { useState } from 'react';
import classnames from 'classnames';
import LoginForm from '../components/User/LoginForm';
import SignupForm from '../components/User/SignupForm';
import '../styles/login.css';

const Login = props => {
	document.title = 'Login - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
	const { signUpURL = false } = props;
	const [isNewUser, setIsNewUser] = useState(signUpURL);	

	return (
		<div className="login__container">
			<div className={classnames("form-container", { 'signup-spacing': isNewUser })}>
				<div className="login__greeting" data-fade-in>
					<a href="/">
						<img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/Logo.webp" alt="Logo" />
					</a>
				</div>
				{!isNewUser ? <LoginForm setIsNewUser={setIsNewUser} /> : <SignupForm setIsNewUser={setIsNewUser} />}
			</div>
		</div>
	);
};

export default Login;