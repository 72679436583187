import React, { useEffect, useRef } from 'react';
import range from 'lodash/range';
import './rangeSlider.css';

const RangeSlider = props => {
    const { start, end, step, value, onChange } = props;
    const sliderValueRef = useRef(null);

    const updateValue = event => {
        const top = (event.target.value - step) / step * -40;
        sliderValueRef.current.style.marginTop = top + "px";
        onChange(event);
    };

    useEffect(() => {
        const top = (value - step) / step * -40;
        sliderValueRef.current.style.marginTop = top + "px";
    }, []);

    return <div className="range-input">
        <input aria-label="number of teams slider" type="range" min={start} max={end} value={value} step={step} onInput={updateValue} />
        <div className="value">
            <div ref={sliderValueRef}>
                {range(start, end + step, step).map(i => (<div key={i}>{i}</div>))}
            </div>
        </div>
    </div>;
};

export default RangeSlider;