import React, { useEffect, useRef, useState } from 'react';

const TitleInput = (props) => {
    const {
        updatedValue,
        handleSubmit,
        ...rest
    } = props;
    const [content, setContent] = useState(updatedValue);
    const groupTitleRef = useRef(null);

    useEffect(() => {
        if (updatedValue !== content)
            setContent(updatedValue);
    }, [updatedValue]);

    const onSubmit = event => {
        event.preventDefault();
        handleSubmit(content);
    };

    return (
        <form onSubmit={onSubmit}>
            <input
                type='text'
                value={content}
                ref={groupTitleRef}
                onBlur={() => handleSubmit(content)}
                onFocus={() => groupTitleRef.current.select()}
                onChange={e => setContent(e.target.value)}
                {...rest}
            />
        </form>
    );
};

export default TitleInput;