import React from 'react';
import RetroButton from './Form/RetroButton';
import { POST } from '../services/HTTPHandler';
import '../styles/requestFacilitation.css';

const RequestFacilitation = props => {
    const { boardID, status } = props;

    const requestToBeFacilitator = () => {
        POST('/activity/requestFacilitation', { boardID });
    };

    return <div className="request__facilitation">
        <RetroButton
            size="lg"
            theme="blue"
            onClick={requestToBeFacilitator}
            disabled={status === 'requested'}
            className="tooltip expand tooltip-top"
            aria-label="request to be board facilitator"
            data-tooltip-content="Request to take over Facilitation"
        >
            {status === 'requested' ? 'Requested' : 'Request Facilitation'}
        </RetroButton>
    </div>;
};

export default RequestFacilitation;