import React, { useEffect, useRef } from 'react';
import './borderlessInput.css'

const BorderlessInput = props => {
    const { value, setValue, handleSubmit, placeholder, ...rest } = props;

    const inputRef = useRef(null);

    const onSubmit = event => {
        event.preventDefault();
        handleSubmit(value);
    };

    const submitOnEnter = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSubmit(value);
        }
    };

    useEffect(() => {
        inputRef.current.addEventListener('input', autoResize, false);

        function autoResize() {
            this.style.height = 'auto';
            this.style.height = this.scrollHeight + 'px';
        }
        if (value !== '')
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';

        inputRef.current.select();
    }, []);

    return <form onSubmit={onSubmit}>
        <textarea
            type='text'
            value={value}
            ref={inputRef}
            onKeyDown={submitOnEnter}
            className='borderless-input'
            placeholder={placeholder}
            onBlur={() => handleSubmit(value)}
            onFocus={() => inputRef.current.select()}
            onChange={e => setValue(e.target.value)}
            {...rest}
        />
    </form>;
};

export default BorderlessInput;