import classNames from 'classnames';
import React from 'react';

import './popOver.css';

const PopOver = props => {
    const { className, children, closePopOver, placement } = props;
    const classes = classNames('popOver', className, {
        'popOver__top': placement === 'top',
        'popOver__bottom': placement === 'bottom',
    });

    return <>
        <div className="popOver__container" onClick={closePopOver} />
        <div className={classes}>
            {children}
        </div>
    </>;
};

export default PopOver;