import React, { useEffect, useState } from "react";
import Timer from "../Timer";
import DiscussionInfo from "./DiscussionInfo";
import RetroButton from "../Form/RetroButton";
import { POST } from "../../services/HTTPHandler";
import { BsFillInfoCircleFill } from "react-icons/bs";

const RetroHeader = props => {
    const {
        title,
        teamID,
        boardID,
        allTopics,
        totalTime,
        retroStep,
        isLastStep,
        startTimer,
        resetTimer,
        members = {},
        setStartTimer,
        completedVotes,
        remainingVotes,
        completedTopics,
        handleTimerDone,
        addRunningMinute,
        handleResetTimer,
        allUsersTotalVotes,
        isFinished = false,
        membersFinishedCount,
        overrideFinishedCommenting,
    } = props;

    const [undoHover, setUndoHover] = useState(false);
    const [finishedCommenting, setFinishedCommenting] = useState(isFinished);
    const [lockedToAvoidSpamClick, setLockedToAvoidSpamClick] = useState(false);

    const userFinished = () => {
        if (!lockedToAvoidSpamClick) {
            setLockedToAvoidSpamClick(true);
            POST('/activity/finishedCommenting', { boardID, finishedCommenting: !finishedCommenting, teamID })
                .then(() => {
                    setFinishedCommenting(!finishedCommenting);
                    setLockedToAvoidSpamClick(false);
                })
                .catch(err => console.log('POST Error ', err));
        }
    };

    const getTooltipInfo = () => {
        switch (retroStep) {
            case 0: return 'INFO - This is the Comments section which is 1 out of the 4 steps in this retrospective. Add cards for things you want to dicuss as a team.';
            case 1: return 'INFO - This is the Grouping section which is 2 out of the 4 steps in this retrospective. Group cards that are similar in context.';
            case 2: return 'INFO - This is the Voting section which is 3 out of the 4 steps in this retrospective. Vote to decide the order in which your team discusses the cards.';
            case 3: return 'INFO - This is the Discussion section which is the last step in this retrospective. The cards are ordered by Votes. Time to retrospect !';
            default: return '';
        }
    };

    useEffect(() => {
        setFinishedCommenting(isFinished);
    }, [isFinished])

    useEffect(() => {
        if (overrideFinishedCommenting && finishedCommenting)
            userFinished();
    }, [overrideFinishedCommenting]);

    return <div className="retro__header">
        <div className="retro__phase-title">
            <h2 data-fade-in>{title}</h2>
            <div
                data-tooltip-content={getTooltipInfo()}
                className="retro__phase-info tooltip info-tooltip-right expand"
            >
                <BsFillInfoCircleFill />
            </div>
            {isLastStep && <DiscussionInfo
                allTopics={allTopics}
                completedVotes={completedVotes}
                completedTopics={completedTopics}
                allUsersTotalVotes={allUsersTotalVotes}
            />}
        </div>
        {retroStep === 2 && <div>Vote(s) remaining: <strong>{remainingVotes}</strong></div>}
        <Timer
            reset={resetTimer}
            start={startTimer}
            timeLimit={totalTime}
            className="retro__timer"
            setStartTimer={setStartTimer}
            handleTimerDone={handleTimerDone}
            handleResetTimer={handleResetTimer}
            addRunningMinute={addRunningMinute}
        />
        <div
            data-fade-in
            className="finished-section"
            data-hide={retroStep !== 0}
        >
            {membersFinishedCount} / {Object.keys(members).length} Members Finished
            <RetroButton
                onClick={userFinished}
                aria-label="Finished section"
                successState={finishedCommenting}
                onMouseEnter={() => setUndoHover(true)}
                onMouseLeave={() => setUndoHover(false)}
            >
                {finishedCommenting ? (undoHover ? 'Undo' : 'Completed') : 'I\'m Done'}
            </RetroButton>
        </div>
    </div>;
};

export default RetroHeader;