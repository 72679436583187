const TEAM_MONTHLY = 20;
const TEAM_MONTHLY_1 = 18;
const TEAM_MONTHLY_2 = 16;
const SINGLE_USER_MONTHLY = 5;
const TEAM_REGULAR_ANNUAL = 240;
const SINGLE_USER_REGULAR_ANNUAL = 60;
const SINGLE_USER_DISCOUNTED_ANNUAL = 50;

export const getTeamCost = (isAnnual = false, noOfTeams = 1) => {
    const multiplier = isAnnual ? 10 : 1;
    if (noOfTeams > 7)
        return TEAM_MONTHLY_2 * multiplier;
    if (noOfTeams > 2)
        return TEAM_MONTHLY_1 * multiplier;
    return TEAM_MONTHLY * multiplier;
};

export const getPlanCost = ({ isAnnual = false, isTeam = false, noOfTeams = 1 }) =>
(isAnnual ? (isTeam ? getTeamCost(true, noOfTeams) * noOfTeams : SINGLE_USER_DISCOUNTED_ANNUAL) :
    (isTeam ? getTeamCost(false, noOfTeams) * noOfTeams : SINGLE_USER_MONTHLY));

export const getFullPlanCost = ({ isAnnual = false, isTeam = false, noOfTeams = 1 }) =>
    (isTeam ? (isAnnual ? TEAM_REGULAR_ANNUAL : TEAM_MONTHLY) * noOfTeams : SINGLE_USER_REGULAR_ANNUAL);

export const getDiscountCost = ({ isAnnual = false, isTeam = false, noOfTeams = 1 }) =>
    (getFullPlanCost({ isAnnual, isTeam, noOfTeams }) - getPlanCost({ isAnnual, isTeam, noOfTeams }));