import React, { useContext, useState } from 'react';
import get from 'lodash/get';
import alertify from 'alertifyjs';
import classNames from 'classnames';
import { MdPlusOne } from 'react-icons/md';
import { POST } from '../../services/HTTPHandler';
import { RetroControllerContext } from '../../contexts/RetroControllerContext';

const AppreciationRow = props => {
    const { anonymousComments } = useContext(RetroControllerContext);
    const { card, isDiscussion, members, cardKey, retroStep, boardID, forUser, uid } = props;
    const upVotedUsers = Object.keys(get(card, ['upVote'], {}));

    const [upVoteActive, setUpVoteActive] = useState(upVotedUsers.includes(uid));

    const upVote = key => {
        POST('/activity/upVote', { cardKey: key, boardID, forUser: forUser.uid, upVoteBy: uid, add: !upVoteActive })
            .then(() => {
                !upVoteActive ? alertify.notify('Appreciation Upvoted', 'success', 1.5) : alertify.notify('Upvote Removed', 'error', 1.5);
                setUpVoteActive(!upVoteActive);
            });
    };

    const rowClasses = classNames('user-cards__row', {
        'blur': card.owner !== uid && retroStep === 0 && anonymousComments
    });

    const upVoteButtonClasses = classNames('up-vote__button', {
        'active': upVoteActive
    });

    return <div className={rowClasses} key={card.timestamp}>
        <div className="user-cards__content">
            {card.owner !== uid && retroStep === 0 && anonymousComments ? btoa(card.content) : card.content}
        </div>
        <div className="user-cards__icons">
            {isDiscussion &&
                <div
                    data-tooltip-content={`${upVoteActive ? 'Remove' : 'Add'} Upvote`}
                    className="user-cards__up-vote tooltip expand tooltip-bottom"
                >
                    <button className={upVoteButtonClasses} onClick={() => upVote(cardKey)} ><MdPlusOne /></button>
                </div>
            }
            <div className="user-cards__reporter-image">
                <img src={get(members[card.owner], 'photoURL', '')} alt="User" />
            </div>
        </div>
    </div>
};

export default AppreciationRow;