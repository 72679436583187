import React, { useEffect } from 'react';
import Navbar from '../components/Navigation/Navbar';
import { GET } from '../services/HTTPHandler';

const AllBoards = () => {

    useEffect(() => {
        GET('/user/allBoards')
            .then(res => console.log(res))
    }, []);

    return <>
        <Navbar redirectLogoToDashboard showNotification />
    </>;
};

export default AllBoards;