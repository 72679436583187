import classNames from 'classnames';
import React from 'react';
import { FaUserCheck } from 'react-icons/fa';
import './retroButton.css';

const RetroButton = props => {
	const {
		theme,
		onClick,
		disabled,
		children,
		className,
		size = 'lg',
		successState,
		...rest
	} = props;

	const classes = classNames(className, 'retro-button', {
		'finished': successState,
		'inverted-theme': theme === 'blue',
		'retro-button__xs': size === 'xs',
		'retro-button__xl': size === 'xl',
		'retro-button__small': size === 'sm',
		'retro-button__large': size === 'lg',
		'retro-button--danger': theme === 'danger',
	})

	return <button
		onClick={onClick}
		className={classes}
		disabled={disabled}
		{...rest}
	>
		{children}
		{successState && <FaUserCheck className="user-check" />}
	</button>
};

export default RetroButton;