import React from 'react';
import { Navigate } from "react-router-dom";

function PrivateRoute(props) {
	const { component: Component, authenticated, redirectTo } = props;
	if(!authenticated) {
		return <Navigate to={"/login?redirectTo=" + redirectTo} />
	}
	return <Component {...props} />;
};

export default PrivateRoute;