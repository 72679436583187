import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import alertify from 'alertifyjs';
import filter from 'lodash/filter';
import Loader from '../components/Loader';
import { useParams } from 'react-router';
import { BiErrorAlt } from 'react-icons/bi';
import { GET, POST } from '../services/HTTPHandler';
import Navbar from '../components/Navigation/Navbar';
import MembersArea from '../components/TeamRoom/MembersArea';
import ActionItems from '../components/Dashboard/ActionItems';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import RetroCollection from '../components/Dashboard/RetroCollection';

import '../styles/teamRoom.css';

const TeamRoom = () => {
	const { id: teamID } = useParams();
	document.title = 'Team Room - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
	const user = useContext(CurrentUserContext);

	const [boards, setBoards] = useState({});
	const [members, setMembers] = useState({});
	const [teamName, setTeamName] = useState('');
	const [teamAdmin, setTeamAdmin] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [actionItems, setActionItems] = useState([]);
	const [errorStatus, setErrorStatus] = useState(-1);
	const [redirectLink, setRedirectLink] = useState('');
	const [defaultTemplate, setDefaultTemplate] = useState({});
	const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);
	const [displayBoardModal, setDisplayBoardModal] = useState(false);
	const [showAddMemberModal, setShowAddMemberModal] = useState(false);

	useEffect(() => {
		try {
			GET('/team/getTeamData', { teamID })
				.then(res => {
					setBoards(get(res, ['data', 'teamBoards'], {}));
					setActionItems(get(res, ['data', 'actionItems'], []));
					setTeamName(get(res, ['data', 'teamInfo', 'name'], ''));
					setMembers(get(res, ['data', 'teamInfo', 'members'], {}));
					setDefaultTemplate(get(res, ['data', 'defaultTemplate'], {}));
					setTeamAdmin(get(res, ['data', 'teamInfo', 'teamAdmin'], ''));
					setIsLoading(false);
				})
				.catch(err => {
					setErrorStatus(get(err, ['response', 'status'], -1));
					setIsLoading(false);
				});
		} catch (error) {
			alertify.notify(error.message, 'error', 3, function () { console.log(error) });
		}
	}, [teamID]);

	const addTeamMember = data => {
		POST('/team/addMember', { teamID, data })
			.then(res => {
				setShowAddMemberModal(false);
				setMembers(get(res, ['data', 'teamInfo', 'members'], {}));
				setTeamAdmin(get(res, ['data', 'teamInfo', 'teamAdmin'], ''));
			});
	};

	const removeUserFromMembersList = userToRemove => {
		setMembers(filter(members, member => userToRemove !== member.uid));
	};

	const updateTeamAdmin = userToMakeAdmin => {
		setTeamAdmin(userToMakeAdmin);
	};

	const createBoard = async (newBoard) => {
		try {
			POST(`/create/board/exist`, newBoard)
				.then(res => {
					const response = get(res, ['data'], {});
					if (response.redirectOnSubmit) {
						setRedirectLink(response.boardURL);
						setRedirectOnSubmit(true);
					}
					else {
						const newBoardID = get(response, ['board', 'boardData', 'boardID'], '');
						setBoards({ ...boards, [newBoardID]: response.board })
					}
					setDisplayBoardModal(false);
				})
				.catch(err => console.log('ERROR: ', err));
		} catch (error) {
			alertify.notify(error.message, 'error', 3, function () { console.log(error) });
		}
	};

	const getTeamMembersCount = () => {
		return Object.keys(members).length;
	};

	const getPageContent = () => {
		switch (errorStatus) {
			case 403: return <div className="teams-container authorization__error">
				<BiErrorAlt className="error__icon" />
				<h3>Error, User unauthorized!</h3>
				<p>You are not a member of this team.</p>
			</div>;
			case -1:
			default: return <div className="teams-container">
				<MembersArea
					user={user}
					teamID={teamID}
					members={members}
					teamName={teamName}
					teamAdmin={teamAdmin}
					addTeamMember={addTeamMember}
					updateTeamAdmin={updateTeamAdmin}
					showAddMemberModal={showAddMemberModal}
					setShowAddMemberModal={setShowAddMemberModal}
					removeUserFromMembersList={removeUserFromMembersList}
				/>
				<RetroCollection
					teamID={teamID}
					boards={boards}
					teamRoom={true}
					teamName={teamName}
					onSubmit={createBoard}
					redirectLink={redirectLink}
					title={`${teamName} Boards`}
					setBoardToDelete={() => (1)}
					removeBoardFromDash={() => (1)}
					displayModal={displayBoardModal}
					defaultTemplate={defaultTemplate}
					redirectOnSubmit={redirectOnSubmit}
					setDisplayModal={setDisplayBoardModal}
					getTeamMembersCount={getTeamMembersCount}
					boardEmpty={Object.keys(boards).length === 0}
				/>
				<ActionItems
					headless={true}
					actionItems={actionItems}
					title={`${teamName} Action Items`}
				/>
			</div>;
		}
	};

	return (isLoading ? <Loader /> :
		<>
			<Navbar redirectLogoToDashboard showNotification />
			{getPageContent()}
		</>
	);
};

export default TeamRoom;