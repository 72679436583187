import React, { useEffect, useState } from 'react';
import times from 'lodash/times';
import classNames from 'classnames';
import RetroButton from '../Form/RetroButton';
import FormDropdown from '../Form/FormDropdown';
import { numbersOnlyRegex } from '../../utils/regex';
import { getCardTypeSVGIcon } from '../../utils/creditCard';
import creditCardType, { getTypeInfo } from 'credit-card-type';
import '../../styles/paymentInfo.css';

const EXPIRY_MONTHS = [{ label: '01', value: 1 }, { label: '02', value: 2 }, { label: '03', value: 3 }, { label: '04', value: 4 }, { label: '05', value: 5 }, { label: '06', value: 6 }, { label: '07', value: 7 }, { label: '08', value: 8 }, { label: '09', value: 9 }, { label: 10, value: 10 }, { label: 11, value: 11 }, { label: 12, value: 12 }];
const EXPIRY_YEARS = times(15, i => ({ value: new Date().getFullYear() + i, label: new Date().getFullYear() + i }));

const CreditCardForm = props => {
    const { submitPayment, creditCardFormError, setCreditCardFormError, isPaymentLoading, creditCardFormErrorMessage } = props;
    const [zipcode, setZipcode] = useState('');
    const [cvvNumber, setCvvNumber] = useState('');
    const [cardIcon, setCardIcon] = useState(<></>);
    const [expiryYear, setExpiryYear] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryError, setExpiryError] = useState(false);
    const [zipcodeError, setZipcodeError] = useState(false);
    const [cardProperties, setCardProperties] = useState({});
    const [cvvNumberError, setCvvNumberError] = useState(false);
    const [creditCardNumber, setCreditCardNumber] = useState('');

    const handleCreditCardInput = e => {
        const cardType = creditCardType(e.target.value)[0] || {};
        const prettyCard = prettyCardNumber(e.target.value.replace(/\s/g, ''), cardType.type);
        setCreditCardNumber(prettyCard);
        setCardProperties(cardType);
    };

    useEffect(() => {
        creditCardNumber === '' ? setCardIcon(<></>) : setCardIcon(getCardTypeSVGIcon(cardProperties.type));
    }, [cardProperties, creditCardNumber]);

    const validateForm = () => {
        const truncatedCard = creditCardNumber.replace(/\s/g, '');
        if (!numbersOnlyRegex.test(truncatedCard) || !cardProperties.lengths.includes(truncatedCard.length)) {
            setCreditCardFormError(true);
            return false;
        }
        if (!numbersOnlyRegex.test(cvvNumber) || cvvNumber.length !== cardProperties.code.size) {
            setCvvNumberError(true);
            return false;
        }
        if (expiryYear === '' || expiryMonth === '') {
            setExpiryError(true);
            return false;
        }
        if (!numbersOnlyRegex.test(zipcode)) {
            setZipcodeError(true);
            return false;
        }
        return true;
    };

    const submitPaymentHandler = () => {
        if (validateForm()) {
            submitPayment({
                cvc: cvvNumber,
                exp_year: expiryYear,
                exp_month: expiryMonth,
                number: creditCardNumber.replace(/\s/g, ''),
            }, { zipcode });
        }
    };

    const prettyCardNumber = (cardNumber, cardType) => {
        const card = getTypeInfo(cardType);
        if (card) {
            const offsets = [].concat(0, card.gaps, cardNumber.length);
            const components = [];

            for (let i = 0; offsets[i] < cardNumber.length; i++) {
                const start = offsets[i];
                const end = Math.min(offsets[i + 1], cardNumber.length);
                components.push(cardNumber.substring(start, end));
            }
            return components.join(" ");
        }
        return cardNumber;
    };

    const truncate = str => (str.substring(0, 4));

    return <div className="flex">
        <div className="payment__container">
            <div className="credit-card__container">
                <h2>Card Details</h2>
            </div>
            <div className="credit-card__form">
                <div className="credit-card__row" >
                    <div className="number__section">
                        <div className={classNames('wrap-input2', 'validate-input', {
                            'alert-validate': creditCardFormError
                        })}
                            data-validate="Credit card number is Invalid"
                        >
                            <input
                                className={classNames('input2', 'input-theme', {
                                    'has-val': creditCardNumber !== '',
                                })}
                                type="text"
                                name="credit-card"
                                value={creditCardNumber}
                                aria-label="Credit card info"
                                onChange={handleCreditCardInput}
                                onFocus={() => setCreditCardFormError(false)}
                                required
                            />
                            <>{cardIcon}</>
                            <span className="focus-input2" data-placeholder="Credit Card Number" />
                        </div>
                    </div>
                    <div className="cvv__section">
                        <div className={classNames('wrap-input2', 'validate-input', {
                            'alert-validate': cvvNumberError
                        })}
                            data-validate="CVV is Invalid"
                        >
                            <input
                                className={classNames('input2', 'input-theme', {
                                    'has-val': cvvNumber !== '',
                                })}
                                name="cvv"
                                type="number"
                                aria-label="cvv"
                                value={cvvNumber}
                                onChange={e => setCvvNumber(truncate(e.target.value))}
                                onFocus={() => setCvvNumberError(false)}
                                required
                            />
                            <span className="focus-input2" data-placeholder="CVV" />
                        </div>
                    </div>
                </div>
                <div className="credit-card__row">
                    <div className="expiry__section">
                        <FormDropdown
                            label="MM"
                            options={EXPIRY_MONTHS}
                            aria-label="expiry month"
                            selectedOption={expiryMonth}
                            onFocus={() => setExpiryError(false)}
                            onDropdownChange={(e) => setExpiryMonth(e.target.value)}
                        />
                        <FormDropdown
                            label="YYYY"
                            options={EXPIRY_YEARS}
                            aria-label="expiry year"
                            selectedOption={expiryYear}
                            onFocus={() => setExpiryError(false)}
                            onDropdownChange={(e) => setExpiryYear(e.target.value)}
                        />
                    </div>
                    <div className="zipcode__section">
                        <div className={classNames('wrap-input2', 'validate-input', {
                            'alert-validate': zipcodeError
                        })}
                            data-validate="Zipcode is Invalid"
                        >
                            <input
                                className={classNames('input2', 'input-theme', {
                                    'has-val': zipcode !== '',
                                })}
                                type="text"
                                name="zipcode"
                                value={zipcode}
                                aria-label="zipcode"
                                onChange={e => setZipcode(e.target.value)}
                                onFocus={() => setZipcodeError(false)}
                                required
                            />
                            <span className="focus-input2" data-placeholder="Zipcode" />
                        </div>
                    </div>
                </div>
            </div>
            {expiryError && <span className="form-error">Please choose expiry MM/YYYY</span>}
            <span data-visually-hidden={!creditCardFormError} className="form-error">{creditCardFormErrorMessage}</span>
            <RetroButton
                theme="blue"
                aria-label="submit payment"
                className="submit__payment mg-25"
                onClick={submitPaymentHandler}
            >
                {isPaymentLoading ? <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/icons/svg/button__loader.svg" alt="loader" /> : 'Start Subscription'}
            </RetroButton>
        </div>
        <div className="stripe__container">
            <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/images/stripe/Powered%20by%20Stripe%20-%20blurple.svg" alt="stripe" />
        </div>
    </div>;
};

export default CreditCardForm;