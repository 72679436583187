import React, { useContext, useEffect, useRef, useState } from 'react';
import Card from './Card';
import get from 'lodash/get';
import VotingSection from './VotingSection';
import TitleInput from '../Form/TitleInput';
import { POST } from '../../services/HTTPHandler';
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

import '../../styles/mergedCards.css';

const STEP_TOP = 8;
const STEP_SIDE = 4;
const STEP_TOP_OFFSET = 10;

const MergedCards = (props) => {
    const {
        vote,
        cards,
        author,
        members,
        boardID,
        baseKey,
        colIndex,
        groupTitle,
        votingMode,
        onDragEnd,
        onDragStart,
        onDragEnter,
        onDragLeave,
        discussMode,
        isMergedCard,
        expandOverride,
        maxVoteReached,
        updateTotalVotes,
    } = props;
    const mergedCardsRef = useRef(null);
    const user = useContext(CurrentUserContext);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (!expandOverride)
            mergeCards();
    }, []);

    useEffect(() => {
        if (!expandOverride)
            mergeCards();
    }, [cards]);

    const mergeCards = () => {
        if (mergedCardsRef) {
            let top = 0, containerHeight = 0;
            const mergedCardsContainers = mergedCardsRef.current;
            const totalCards = mergedCardsContainers.children.length;
            let width = 100 - totalCards * STEP_SIDE;
            Array.from(mergedCardsContainers.children).forEach((card, i) => {
                // Hide cards underneath the stack with smaller heights
                if (i < totalCards - 1) {
                    card.style.height = '50px';
                    containerHeight += STEP_TOP_OFFSET;
                }
                else containerHeight += card.offsetHeight;
                card.style.top = `${top}px`;
                card.style.width = `${width}%`
                top += STEP_TOP;
                width += STEP_SIDE;
            });
            mergedCardsContainers.style.height = `${containerHeight}px`;
        }
    };

    const expandCards = () => {
        if (mergedCardsRef) {
            const mergedCardsContainers = mergedCardsRef.current;
            mergedCardsContainers.style.height = 'auto';
            Array.from(mergedCardsContainers.children).forEach(card => {
                card.style.height = 'auto';
                card.style.width = `100%`
            });
        }
    };

    const toggleCards = () => {
        isExpanded ? mergeCards() : expandCards();
        setIsExpanded(!isExpanded);
    };

    const updateGroupTitle = (groupTitle) => {
        if (groupTitle !== 'Edit Group Title')
            POST('/activity/groupTitle', { boardID, groupTitle, baseKey, colIndex });
    };

    return (
        <div className='merged-cards__section'>
            <div className='merged-cards__header'>
                {!discussMode && <>
                    <button
                        data-tooltip-content="Expand Cards"
                        className="merged-cards__expand-button tooltip expand"
                        onClick={toggleCards} aria-label="Expand merged cards"
                    >
                        {isExpanded ? <AiOutlineShrink /> : <AiOutlineExpandAlt />}
                    </button>
                    <div className='merged-cards__group-title'>
                        <TitleInput
                            draggable={false}
                            aria-label="group title"
                            updatedValue={groupTitle}
                            handleSubmit={updateGroupTitle}
                        />
                    </div>
                </>}
                <VotingSection
                    boardID={boardID}
                    cardKey={baseKey}
                    colIndex={colIndex}
                    votingMode={votingMode}
                    disabled={maxVoteReached}
                    updateTotalVotes={updateTotalVotes}
                    voteCount={get(vote, 'cardVoteCount', 0)}
                />
            </div>
            <div
                ref={mergedCardsRef}
                onDragEnd={onDragEnd}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                className='merged-cards__container'
            >
                {cards && Object.entries(cards).map(([key, card]) => {
                    return (<Card
                        key={key}
                        author={author}
                        members={members}
                        mergeGroup={true}
                        baseKey={baseKey}
                        draggable={isExpanded}
                        content={card.content}
                        cardKey={card.cardKey}
                        onDragEnd={onDragEnd}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        colIndex={card.colIndex}
                        onDragStart={onDragStart}
                        cardTheme={card.cardTheme}
                        isMergedCard={isMergedCard}
                        isOwnwer={card.owner === user.uid}
                        isExpanded={isExpanded || expandOverride}
                    />);
                })}
            </div>
        </div>
    );
};

export default MergedCards;