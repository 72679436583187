import React, { useEffect } from 'react';
import { POST } from '../services/HTTPHandler';
import Loader from './Loader';
import { useLocation, useParams } from 'react-router';

const JoinRetro = () => {
    const { search } = useLocation();
    const { id: boardID } = useParams();
    const token = new URLSearchParams(search).get('token');
    const isTeam = new URLSearchParams(search).get('isTeam');

    useEffect(() => {
        POST('/board/modifyAccess', { boardID, token, isTeam: isTeam === 'true' })
            // eslint-disable-next-line no-restricted-globals
            .then(() => location.assign(`/retro/${boardID}`));
    }, [boardID, token, isTeam]);

    return <Loader />;
};

export default JoinRetro;