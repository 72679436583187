import React, { useContext, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from "../../services/firebase";
import { POST } from '../../services/HTTPHandler';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle,
    Link,
    Avatar,
    Button,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownTrigger,
} from "@nextui-org/react";

import '../../styles/landingNavbar.css'
import UpgradeButton from '../Form/UpgradeButton';
import Notifications from './Notifications';
import classNames from 'classnames';

const menuItems = [
    {label: "How it works", href: "#howItWorks"},
    {label: "Features", href: "#features"},
    {label: "Pricing", href: "#pricing"},
    {label: "Contact", href: "#contact"},
];

const LandingNavbar = props => {
    const { 
        userStatus,
        showUpgrade,
        notifications,
        navigateToHome,
        showNotification,
    } = props;
    const user = useContext(CurrentUserContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(!!user.uid);
    const [activeMenuItem, setActiveMenuItem] = useState(0);

    const logoutHandler = () => {
        signOut(auth);
        POST('/activity/logout');
        setIsSignedIn(false);
    };

    return (
        <Navbar
            maxWidth="full"
            className={classNames({
                'bg-base-background': showNotification,
            })}
            onMenuOpenChange={setIsMenuOpen}
        >
            <NavbarBrand className="logo">
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
                <Link href={navigateToHome ? '/' : '#home'} aria-label="go to home" onClick={() => setActiveMenuItem(-1)}>
                    <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/Logo.webp" alt="Logo" />
                </Link>
            </NavbarBrand>

            {!showNotification  && <NavbarContent className="hidden sm:flex gap-4" justify="center">
                {menuItems.map((item, index) => {
                    return <NavbarItem key={`${item}-${index}`} isActive={activeMenuItem === index}>
                        <Link href={item.href} onClick={() => setActiveMenuItem(index)}>
                            {item.label}
                        </Link>
                    </NavbarItem>
                })}
            </NavbarContent>}
            
            <NavbarContent as="div" justify="end">
                <NavbarItem>
                    {showUpgrade && <UpgradeButton userStatus={userStatus} />}
                </NavbarItem>
                <NavbarItem>
                    {showNotification && <Notifications
                        notifications={notifications}
                        count={Object.keys(notifications).length}
                    />}
                </NavbarItem>
                {user.uid && isSignedIn ? <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                        <Avatar
                            isBordered
                            as="button"
                            className="transition-transform"
                            color="secondary"
                            name={user.displayName.split('.')[0]}
                            size="sm"
                            src={user.photoURL}
                        />
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant="flat">
                        <DropdownItem key="profile" className="h-14 gap-2">
                            <p className="font-semibold">Signed in as</p>
                            <p className="font-semibold">{user.email}</p>
                        </DropdownItem>
                        <DropdownItem key="dashboard" href="/dashboard">My Dashboard</DropdownItem>
                        <DropdownItem key="settings" href="/settings/user">My Profile</DropdownItem>
                        <DropdownItem key="settings" href="/settings/subscription">Manage Subscription</DropdownItem>
                        <DropdownItem key="help_and_feedback">Help & Feedback</DropdownItem>
                        <DropdownItem key="logout" color="danger" onClick={logoutHandler}>
                            Log Out
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown> : <>
                        <NavbarItem className="md:flex">
                            <Link href="/login">Login</Link>
                        </NavbarItem>
                        <NavbarItem className="md:flex">
                            <Button as={Link} color="primary" href="/signup" variant="flat">
                                Try for Free
                            </Button>
                        </NavbarItem>
                </>}
            </NavbarContent>
            <NavbarMenu>
                {menuItems.map((item, index) => (
                    <NavbarMenuItem key={`${item}-${index}`}>
                        <Link
                            color={
                                index === activeMenuItem ? "primary" : "foreground"
                            }
                            className="w-full"
                            href={item.href}
                            size="sm"
                            onClick={() => {setActiveMenuItem(index); setIsMenuOpen(false)}}
                        >
                            {item.label}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>
        </Navbar>
    );
};

export default LandingNavbar;