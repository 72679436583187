import alertify from 'alertifyjs';
import classNames from 'classnames';
import React, { useState } from 'react';
import RetroButton from '../Form/RetroButton';
import FormDropdown from '../Form/FormDropdown';
import { POST } from '../../services/HTTPHandler';
import BorderlessInput from '../Form/BorderlessInput';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';



const getDropdownOptions = options => options.map((option, i) => ({ label: option, value: i }));
const feedbackOptions = ['Feature Request', 'Report a Bug', 'Product Feedback', 'General Feedback'];

const FeedbackModal = props => {
    const {
        closeModal,
        displayModal,
        failureButtonAriaLabel,
        successButtonAriaLabel,
    } = props;

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    const [feedback, setFeedback] = useState('');
    const [likeRetro, setLikeRetro] = useState(null)
    const [feedbackOption, setFeedbackOption] = useState('');
    const [feedbackError, setFeedbackError] = useState(false);

    const resetFeedbackForm = () => {
        setFeedbackError(false);
        setFeedback('');
        setLikeRetro(null);
        setFeedbackOption('');
    };

    const submitFeedback = event => {
        event.preventDefault();
        if (feedback !== '' && feedbackOption !== '') {
            POST('/feedback/submit', { feedback, likeRetro: !!likeRetro, feedbackOption: feedbackOptions[feedbackOption], userSubmitted: true })
                .then(() => {
                    alertify.notify('Feedback submitted', 'success', 2);
                    resetFeedbackForm();
                }).catch(err => {
                    alertify.notify('Error submitting feedback', 'error', 2);
                });
        } else if (feedback === '' && feedbackOption !== '') {
            setFeedbackError(true);
            return;
        }
        resetFeedbackForm();
        closeModal();
    };

    const checkValuesBeforeClosing = () => {
        if (feedback.trim() !== '' || feedbackOption !== '' || likeRetro !== null) {
            POST('/feedback/submit', { feedback, likeRetro, feedbackOption: feedbackOptions[feedbackOption], userSubmitted: false })
                .then(() => resetFeedbackForm())
                .catch(err => console.log(err));
        }
        closeModal();
    };

    return <div
        data-fade-in
        style={divStyle}
        className="modal"
        onClick={checkValuesBeforeClosing}
    >
        <div className="modal__alert top-60" onClick={e => e.stopPropagation()}>
            <div className="modal__header">
                <div className="modal__back width0" />
                <h3 className="modal_title">Feedback</h3>
                <div className="close">
                    <button className="modal__header--controls" onClick={checkValuesBeforeClosing}>&times;</button>
                </div>
            </div>
            <div className="modal__form modal__form--alert">
                <div className="feedback__icons">
                    <button onClick={() => setLikeRetro(true)} className="expand tooltip" data-tooltip-content="Like Retro" aria-label="like">
                        <FiThumbsUp className={classNames({ active: likeRetro })} />
                    </button>
                    <button onClick={() => setLikeRetro(false)} className="expand tooltip" data-tooltip-content="Dislike Retro" aria-label="dislike">
                        <FiThumbsDown className={classNames({ active: likeRetro === false })} />
                    </button>
                </div>
                <FormDropdown
                    aria-label="feedback dropdown"
                    selectedOption={feedbackOption}
                    label="--Choose type of feedback--"
                    onFocus={() => setFeedbackError(false)}
                    options={getDropdownOptions(feedbackOptions)}
                    onDropdownChange={e => setFeedbackOption(e.target.value)}
                />
                <div className="wrap-input2 mt-20">
                    <BorderlessInput
                        className={classNames('input2', 'textarea-theme', 'actionItemInput', {
                            'has-val': feedback !== ''
                        })}
                        value={feedback}
                        aria-label="feedback"
                        setValue={setFeedback}
                        handleSubmit={() => (1)}
                        placeholder="Enter feedback"
                    />
                </div>
                <div data-hide={!feedbackError}>
                    Please enter feedback
                </div>
            </div>
            <div className="modal__alert--actions">
                <RetroButton
                    onClick={checkValuesBeforeClosing}
                    aria-label={failureButtonAriaLabel}
                >
                    Cancel
                </RetroButton>
                <RetroButton
                    theme="blue"
                    onClick={submitFeedback}
                    aria-label={successButtonAriaLabel}
                >
                    Submit
                </RetroButton>
            </div>
        </div >
    </div >;
};

export default FeedbackModal;