import React from 'react';

import './checkbox.css';

const Checkbox = props => {
    const { label, onChange, value } = props;
    return <>
        <input
            value={value}
            type="checkbox"
            checked={value}
            aria-label={label}
            className="plus-minus"
            onChange={(e) => onChange(e.target.checked)}
        />
        <label>{label}</label>
    </>;
};

export default Checkbox;