import React, { useEffect, useRef, useState, useReducer, useContext } from 'react';
import get from 'lodash/get';
import every from 'lodash/every';
import alertify from 'alertifyjs';
import { useParams } from 'react-router';
import Loader from '../components/Loader';
import { sync } from '../services/firebase';
import { onValue, ref } from 'firebase/database';
import { getFormattedName } from '../utils/user';
import Discuss from '../components/Retro/Discuss';
import Comments from '../components/Retro/Comments';
import { GET, POST } from '../services/HTTPHandler';
import BoardNotFound from '../components/BoardNotFound';
import RetroNav from '../components/Navigation/RetroNav';
import AlertModal from '../components/Modals/AlertModal';
import RetroHeader from '../components/Retro/RetroHeader';
import FreeTialExpired from '../components/FreeTialExpired';
import UnAuthorizedUser from '../components/UnAuthorizedUser';
import MemberActivity from '../components/Retro/MemberActivity';
import RetroController from '../components/Retro/RetroController';
import RequestFacilitation from '../components/RequestFacilitation';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { RetroControllerProvider } from '../contexts/RetroControllerContext';
import BoardSettingsSideDrawer from '../components/SideDrawer/BoardSettingsSideDrawer';

import '../styles/retro.css';
import 'alertifyjs/build/css/alertify.css';

const RETRO_TITLES = ['Comments', 'Group', 'Vote', 'Discuss'];
const DEFAULT_MAX_VOTE = 5, DEFAULT_DIVISION = 100;

const getDefaultState = () => ({
	teamID: '',
	members: {},
	columns: [],
	boardData: {},
	boardOptions: {},
});

const reducer = (state, action) => {
	switch (action.type) {
		case 'hydrate':
			return {
				teamID: get(action.payload, ['teamID'], ''),
				members: get(action.payload, ['members'], {}),
				teamInfo: get(action.payload, ['teamInfo'], {}),
				columns: get(action.payload, ['board', 'columns'], []),
				boardData: get(action.payload, ['board', 'boardData'], {}),
				actionItems: get(action.payload, ['board', 'actionItems'], {}),
				boardOptions: get(action.payload, ['board', 'boardData', 'board', 'boardSettings'], {}),
			};
		case 'updateColumns':
			return {
				teamID: state.teamID,
				members: state.members,
				columns: action.payload,
				teamInfo: state.teamInfo,
				boardData: state.boardData,
				actionItems: state.actionItems,
				boardOptions: state.boardSettings,
			};
		case 'updateActionItems':
			return {
				teamID: state.teamID,
				members: state.members,
				columns: state.columns,
				teamInfo: state.teamInfo,
				boardData: state.boardData,
				actionItems: action.payload,
				boardOptions: state.boardSettings,
			};
		case 'updateTeamMembers':
			return {
				teamID: state.teamID,
				columns: state.columns,
				members: action.payload,
				teamInfo: state.teamInfo,
				boardData: state.boardData,
				actionItems: state.actionItems,
				boardOptions: state.boardSettings,
			};
		default: throw new Error();
	};
};

const Retro = () => {
	const { id: boardID } = useParams();
	document.title = 'Active Board - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
	const user = useContext(CurrentUserContext);

	const [state, dispatch] = useReducer(reducer, getDefaultState());

	const scrollableDiv = useRef(null);
	const [totalTime, setTotalTime] = useState(0);
	const [retroStep, setRetroStep] = useState(0);
	const [translate, setTranslate] = useState(0);
	const [allTopics, setAllTopics] = useState(0);
	const [totalVotes, setTotalVotes] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [hasAccess, setHasAccess] = useState(false);
	const [activeIndex, setActiveIndex] = useState(1);
	const [facilitator, setFacilitator] = useState({});
	const [retroEnded, setRetroEnded] = useState(false);
	const [endOfRetro, setEndOfRetro] = useState(false);
	const [startTimer, setStartTimer] = useState(false);
	const [resetTimer, setResetTimer] = useState(false);
	const [membersOnline, setMembersOnline] = useState({});
	const [completedVotes, setCompletedVotes] = useState(0);
	const [timerRunning, setTimerRunning] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [percentComplete, setPercentComplete] = useState(0);
	const [boardNotFound, setBoardNotFound] = useState(false);
	const [completedTopics, setCompletedTopics] = useState(0);
	const [showTimerInput, setShowTimerInput] = useState(true);
	const [maxVoteReached, setMaxVoteReached] = useState(false);
	const [votingReEnabled, setVotingReEnabled] = useState(false);
	const [paymentRequired, setPaymentRequired] = useState(false);
	const [membersTypingList, setMembersTypingList] = useState({});
	const [allUsersTotalVotes, setAllUsersTotalVotes] = useState(0);
	const [addRunningMinute, setAddRunningMinute] = useState(false);
	const [membersFinishedList, setMembersFinishedList] = useState({});
	const [membersFinishedCount, setMembersFinishedCount] = useState(0);
	const [showFacilitatorModal, setShowFacilitatorModal] = useState(false);
	const [facilitationRequested, setFacilitationRequested] = useState(false);
	const [discussionPercentDivision, setDiscussionPercentDivision] = useState(0);
	const [memberVotesRemainingPercent, setMemberVotesRemainingPercent] = useState({});
	const [overrideFinishedCommenting, setOverrideFinishedCommenting] = useState(false);
	const [displayMembersUnfinishedConfirmModal, setDisplayMembersUnfinishedConfirmModal] = useState(false);

	// State values for board settings context updates
	const [votesPerUser, setVotesPerUser] = useState(DEFAULT_MAX_VOTE);
	const [showAuthor, setShowAuthor] = useState(get(state, ['boardOptions', 'showAuthor'], false));
	const [oneVotePerCard, setOneVotePerCard] = useState(get(state, ['boardOptions', 'oneVotePerCard'], false));
	const [autoSwitchPhases, setAutoSwitchPhases] = useState(get(state, ['boardOptions', 'autoSwitchPhases'], false));
	const [anonymousComments, setAnonymousComments] = useState(get(state, ['boardOptions', 'anonymousComments'], false));
	const value = {
		showAuthor, setShowAuthor,
		votesPerUser, setVotesPerUser,
		oneVotePerCard, setOneVotePerCard,
		autoSwitchPhases, setAutoSwitchPhases,
		anonymousComments, setAnonymousComments
	};

	let root = document.documentElement;

	useEffect(() => {
		try {
			GET('/board/boardData', { boardID })
				.then(response => {
					if (response.data) {
						const payload = get(response, ['data'], {});
						setFacilitator(get(payload, ['board', 'boardData', 'facilitator'], ''));
						const boardSettings = get(payload, ['board', 'boardData', 'board', 'boardSettings'], {});
						setShowAuthor(boardSettings.showAuthor);
						setVotesPerUser(boardSettings.votesPerUser);
						setOneVotePerCard(boardSettings.oneVotePerCard);
						setAutoSwitchPhases(boardSettings.autoSwitchPhases);
						setAnonymousComments(boardSettings.anonymousComments);
						dispatch({ type: 'hydrate', payload });
						setTotalVotes(payload.totalVotes || 0);
						if (payload.totalVotes >= votesPerUser)
							setMaxVoteReached(true);
						setHasAccess(true);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setIsLoading(false);
					const errorCode = get(error, ['response', 'status'], false);
					if (errorCode === 402)
						setPaymentRequired(true);
					else if (errorCode === 404)
						setBoardNotFound(true);
				});

			onValue(ref(sync, `boards/${boardID}/activity/`), snapshot => {
				const response = snapshot.val();
				if (response) {
					setMembersTypingList(response.membersTyping);
					setMemberVotesRemainingPercent(response.votesRemainingPercent);
					if (get(response, ['membersTyping', user.uid], false))
						setOverrideFinishedCommenting(true);
					else setOverrideFinishedCommenting(false);
					const finished = response.finishedCommenting;
					setMembersFinishedList(finished);
					const allVotes = get(response, ['vote', 'singleUserTotal'], {});
					setAllUsersTotalVotes(getVotesSum(allVotes));
					let finishedCount;
					if (finished) {
						finishedCount = get(Object.keys(finished).filter(key => finished[key] === true), 'length', 0);
						setMembersFinishedCount(finishedCount);
						// if (autoSwitchPhases && response.retroStep === 0 && finishedCount === Object.keys(state.members).length)
						// 	alertify.notify('All members finished. Auto switching phases!', 'success', 3, () => goNext());
					}
					if ((response.retroStep && retroStep !== response.retroStep) || response.retroStep === 0)
						setRetroStep(response.retroStep);
					if (response.percentComplete || response.percentComplete === 0)
						moveDiscussionCards(response);
					if (response.retroEnded)
						setRetroEnded(response.retroEnded);
				}
			});
			onValue(ref(sync, `boards/${boardID}/columns`), data => {
				if (data.val())
					dispatch({ type: 'updateColumns', payload: data.val() });
			});
			onValue(ref(sync, `boards/${boardID}/actionItems`), data => {
				if (data.val())
					dispatch({ type: 'updateActionItems', payload: data.val() });
			});
			onValue(ref(sync, `boards/${boardID}/boardData/facilitator`), data => {
				const resposne = data.val();
				if (resposne) {
					if (resposne.currentFacilitator === user.uid && resposne.status === 'requested') {
						setFacilitationRequested(true);
						setShowFacilitatorModal(true);
					} else if (resposne.status !== 'requested')
						setFacilitationRequested(false);
					if (resposne.requestBy === user.uid) {
						if (resposne.status === 'accept')
							alertify.notify('Request Accepted', 'success', 3, () => (1));
						else if (resposne.status === 'deny')
							alertify.notify('Request Denied', 'error', 3, () => (0));
					}
					setFacilitator(resposne);
				}
			});
			onValue(ref(sync, `boards/${boardID}/boardData/board/boardSettings`), data => {
				if (data.val()) {
					const boardSettings = data.val();
					setShowAuthor(boardSettings.showAuthor);
					setVotesPerUser(boardSettings.votesPerUser);
					setOneVotePerCard(boardSettings.oneVotePerCard);
					setAnonymousComments(boardSettings.anonymousComments);
				}
			});
		} catch (error) {
			console.log(error.message);
			alertify.notify(error.message, 'error', 3, function () { console.log(error) });
		}
	}, []);

	useEffect(() => {
		if (retroEnded)
			redirectRetro();
	}, [retroEnded]);

	const getVotesSum = allVotes => {
		let sum = 0;
		every(allVotes, vote => sum += vote);
		return sum;
	};

	useEffect(() => {
		if (state.teamID) {
			onValue(ref(sync, `teams/${state.teamID}/activity/`), snapshot => {
				const response = snapshot.val();
				if(response)
					setMembersOnline(response.membersOnline);
			});
			onValue(ref(sync, `teams/${state.teamID}/teamInfo/members`), data => {
				if (data.val())
					dispatch({ type: 'updateTeamMembers', payload: data.val() });
			});
		}
	}, [state.teamID]);

	const moveDiscussionCards = response => {
		if ((response.translate || response.translate === 0) &&
			response.activeIndex >= 1 && response.translate <= 0) {
			setActiveIndex(response.activeIndex);
			setCompletedTopics(response.activeIndex);
			root.style.setProperty('--translate', `${response.translate}%`);
			setTranslate(response.translate);
		}
		setPercentComplete(response.percentComplete);
		root.style.setProperty('--percentComplete', `${response.percentComplete}%`);
	};

	const postCard = (index, card) => {
		return async function () {
			if (card.content !== '') {
				try {
					POST('/activity/addCard', { boardID, index, card, boardName: state.boardData.board.name });
				} catch (error) {
					console.log(error.message);
				}
			}
		}
	};

	const goNext = ({ override = false }) => {
		if (!override && retroStep === 0 && membersFinishedCount < Object.keys(state.members).length) {
			setDisplayMembersUnfinishedConfirmModal(true);
			return;
		}
		if (retroStep >= 0 && retroStep < 3) {
			POST('/activity/nextStep', {
				boardID,
				teamID: state.teamID,
				retroStep: retroStep + 1,
				percentComplete: percentComplete + 20,
			}).then(() => { if (retroStep !== 3) setRetroStep(retroStep + 1) })
				.catch(err => console.log(err));
		}
		else {
			POST('/activity/activeIndex', {
				boardID,
				teamID: state.teamID,
				activeIndex: activeIndex + 1,
				translate: translate - DEFAULT_DIVISION,
				percentComplete: percentComplete + discussionPercentDivision,
			}).catch(err => console.log(err));
		}
	};

	const goPrev = () => {
		if (retroStep > 0 && retroStep <= 3 && activeIndex === 1) {
			POST('/activity/prevStep', {
				boardID,
				teamID: state.teamID,
				retroStep: retroStep - 1,
				percentComplete: percentComplete - 20,
			})
				.then(() => { if (retroStep !== 0) setRetroStep(retroStep - 1) })
				.catch(err => console.log(err));
		}
		else {
			POST('/activity/activeIndex', {
				boardID,
				teamID: state.teamID,
				activeIndex: activeIndex - 1,
				translate: translate + DEFAULT_DIVISION,
				percentComplete: percentComplete - discussionPercentDivision,
			}).catch(err => console.log(err));
		}
	};

	const redirectRetro = () => {
		setIsLoading(true);
		// eslint-disable-next-line no-restricted-globals
		location.assign(`/retro/${boardID}/end`);
	};


	const closeRetro = () => {
		POST('/activity/endRetro', { teamID: state.teamID, boardID })
			.then(() => redirectRetro());
	};

	const updateRequestStatus = status => {
		POST('/activity/facilitationStatus', { boardID, status, requestBy: facilitator.requestBy })
			.then(() => setShowFacilitatorModal(false));
	};

	const addMinute = () => {
		timerRunning ? setAddRunningMinute(true) : setTotalTime(60);
	};

	const handleStartTimer = (minutes, seconds, options) => {
		if (options.start)
			setTotalTime((parseInt(minutes) * 60) + parseInt(seconds));
		if (options.add)
			addMinute();
		setStartTimer(true);
		setTimerRunning(true);
		setShowTimerInput(false);
	};

	const handleTimerDone = () => {
		setTotalTime(0);
		setStartTimer(false);
		setTimerRunning(false);
		setShowTimerInput(true);
	};

	const overrideRetroState = event => {
		setDisplayMembersUnfinishedConfirmModal(false);
		goNext({ override: true });
	};

	return (isLoading ? <Loader /> :
		(hasAccess ? <>
			<RetroNav members={state.members} boardData={state.boardData} />
			<RetroControllerProvider value={value}>
				<div ref={scrollableDiv} className="board__container">
					<MemberActivity
						boardID={boardID}
						teamID={state.teamID}
						retroStep={retroStep}
						members={state.members}
						totalVotes={totalVotes}
						votesPerUser={votesPerUser}
						membersOnline={membersOnline}
						membersTypingList={membersTypingList}
						membersFinishedList={membersFinishedList}
						memberVotesRemainingPercent={memberVotesRemainingPercent}
					/>
					<div className="board__content">
						<RetroHeader
							boardID={boardID}
							retroStep={retroStep}
							totalTime={totalTime}
							teamID={state.teamID}
							allTopics={allTopics}
							members={state.members}
							startTimer={startTimer}
							resetTimer={resetTimer}
							isLastStep={retroStep === 3}
							setStartTimer={setStartTimer}
							completedVotes={completedVotes}
							title={RETRO_TITLES[retroStep]}
							handleResetTimer={setResetTimer}
							handleTimerDone={handleTimerDone}
							completedTopics={completedTopics}
							addRunningMinute={addRunningMinute}
							allUsersTotalVotes={allUsersTotalVotes}
							remainingVotes={votesPerUser - totalVotes}
							membersFinishedCount={membersFinishedCount}
							isFinished={get(membersFinishedList, [user.uid], '')}
							overrideFinishedCommenting={overrideFinishedCommenting}
						/>
						<Comments
							boardID={boardID}
							retroStep={retroStep}
							teamID={state.teamID}
							columns={state.columns}
							members={state.members}
							totalVotes={totalVotes}
							handleSubmit={postCard}
							isLastStep={retroStep === 3}
							setTotalVotes={setTotalVotes}
							setMaxVoteReached={setMaxVoteReached}
							setVotingReEnabled={setVotingReEnabled}
							maxVoteReached={totalVotes >= votesPerUser || maxVoteReached}
						/>
						<Discuss
							boardID={boardID}
							members={state.members}
							teamInfo={state.teamInfo}
							activeIndex={activeIndex}
							isHidden={retroStep !== 3}
							setAllTopics={setAllTopics}
							isLastStep={retroStep === 3}
							setEndOfRetro={setEndOfRetro}
							actionItems={state.actionItems}
							votingReEnabled={votingReEnabled}
							setCompletedVotes={setCompletedVotes}
							setVotingReEnabled={setVotingReEnabled}
							setDiscussionPercentDivision={setDiscussionPercentDivision}
						/>
					</div>
				</div>
				{facilitator.currentFacilitator === user.uid ? <RetroController
					goNext={goNext}
					goPrev={goPrev}
					endOfRetro={endOfRetro}
					closeRetro={closeRetro}
					activeIndex={activeIndex}
					showSettings={showSettings}
					timerRunning={timerRunning}
					isLastStep={retroStep === 3}
					disableLeft={retroStep === 0}
					showTimerInput={showTimerInput}
					handleResetTimer={setResetTimer}
					setShowSettings={setShowSettings}
					handleStartTimer={handleStartTimer}
					setShowTimerInput={setShowTimerInput}
					prevTitle={RETRO_TITLES[retroStep - 1]}
					nextTitle={RETRO_TITLES[retroStep + 1]}
					showFacilitatorModal={showFacilitatorModal}
					facilitationRequested={facilitationRequested}
					setShowFacilitatorModal={setShowFacilitatorModal}
				/> : <RequestFacilitation boardID={boardID} status={facilitator.status} />}
				<AlertModal
					successTheme="blue"
					failureMessage="Cancel"
					className="bottom-margin"
					title="Unfinished Members"
					successMessage="Continue anyway"
					successHandler={overrideRetroState}
					failureButtonAriaLabel="close modal"
					successButtonAriaLabel="delete member"
					displayModal={displayMembersUnfinishedConfirmModal}
					closeModal={() => setDisplayMembersUnfinishedConfirmModal(false)}
					failureHandler={() => setDisplayMembersUnfinishedConfirmModal(false)}
					message={`${Object.keys(state.members).length - membersFinishedCount} Members are yet to complete`}
				/>
				<AlertModal
					successTheme="blue"
					failureMessage="Deny"
					successMessage="Accept"
					title="Facilitator Request"
					displayModal={showFacilitatorModal}
					closeModal={() => setShowFacilitatorModal(false)}
					failureButtonAriaLabel="deny facilitation request"
					failureHandler={() => updateRequestStatus('deny')}
					successButtonAriaLabel="accept facilitation request"
					successHandler={() => updateRequestStatus('accept')}
					message={`${getFormattedName(get(state.members, [facilitator.requestBy, 'displayName'], ''))} is requesting to facilitate`}
				/>
				<BoardSettingsSideDrawer
					boardID={boardID}
					showSettings={showSettings}
					setShowSettings={setShowSettings}
				/>
			</RetroControllerProvider>
		</> : boardNotFound ? <BoardNotFound /> :
			(paymentRequired ? <FreeTialExpired /> : <UnAuthorizedUser boardID={boardID} />))
	);
};

export default Retro;