import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from "../../services/firebase";
import { getFirstName } from '../../utils/user';
import { POST } from '../../services/HTTPHandler';
import FeedbackModal from '../Modals/FeedbackModal';

import '../../styles/profileButton.css';

const ProfileButton = (props) => {
    const { withBorder, photoURL, displayName, onSignOut } = props;

    const logoutHandler = () => {
        signOut(auth);
        POST('/activity/logout');
        if (onSignOut)
            onSignOut();
    };

    const buttonClasses = classnames('my-profile', { 'my-profile__border': withBorder });
    const dropdownClasses = classnames('dropdown', { 'dropdown__border': withBorder });

    const [showFeedback, setShowFeedback] = useState(false);

    return <>
        <div className="my-profile__container">
            <div className="hide-dropdown" aria-hidden="true" />
            <button className={buttonClasses} aria-label="user profile">
                <span>{getFirstName(displayName)}</span>
                <img src={photoURL} alt="user profile" />
            </button>
            <ul className={dropdownClasses}>
                <li onClick={() => setShowFeedback(true)} role="button" aria-label="feedback" tabIndex="0">Feedback</li>
                <Link tabIndex="-1" to="/settings/user" id="settings__anchor"><li role="link" aria-label="settings" tabIndex="0">Settings</li></Link>
                <li role="button" aria-label="logout" tabIndex="0" onClick={logoutHandler}>Logout</li>
            </ul>
        </div>
        <FeedbackModal
            displayModal={showFeedback}
            failureButtonAriaLabel="cancel"
            successButtonAriaLabel="send feedback"
            closeModal={() => setShowFeedback(false)}
        />
    </>
};

export default ProfileButton;