import React from 'react';
import map from 'lodash/map';
import classNames from 'classnames';
import ActionItemRow from './ActionItemRow';

const ActionItemsContainer = props => {
    const { actionItems = {}, isHidden, isEndOfRetro, setBoardUpdatedAfterCompletion } = props;
    const classname = classNames("action-items__container", { 'end-of-retro': isEndOfRetro });

    return <div className={classname} data-hide={isHidden} aria-hidden={isHidden} data-fade-in>
        {Object.keys(actionItems).length === 0 ? <div className="action-items__empty">
            <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/EmptyActionItems.webp" alt="empty action items" />
        </div> : map(actionItems, (item, key) => (
            <ActionItemRow
                key={key}
                item={item}
                isEndOfRetro={isEndOfRetro}
                setBoardUpdatedAfterCompletion={setBoardUpdatedAfterCompletion}
            />
        ))}
    </div>;
};

export default ActionItemsContainer;