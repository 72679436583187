import React from 'react';
import classnames from 'classnames';
import './settingsInput.css';

const SettingsInput = props => {
    const { label, className, isError, ...rest } = props;

    const inputClasses = classnames(className, 'input-1', {
        'inputError': isError,
    })

    return <>
        <label className="settings__label">
            {label}
        </label>
        <input
            className={inputClasses}
            {...rest}
        />
    </>
};

export default SettingsInput;