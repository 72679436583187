import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import classnames from 'classnames';
import { CgClose } from 'react-icons/cg';
import { useParams } from 'react-router';
import { FcCheckmark } from 'react-icons/fc';
import { sync } from '../services/firebase';
import { onValue, ref } from 'firebase/database';
import { getFormattedName } from '../utils/user';
import { GET, POST } from '../services/HTTPHandler';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import RetroButton from '../components/Form/RetroButton';
import FormDropdown from '../components/Form/FormDropdown';
import BorderlessInput from '../components/Form/BorderlessInput';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import ActionItemsContainer from '../components/Dashboard/ActionItemsContainer';

import '../styles/retroCompleted.css';

const getDefaultState = () => ({
    teamInfo: {},
    boardData: {},
    actionItems: {}
});

const reducer = (state, action) => {
    switch (action.type) {
        case 'hydrate':
            return {
                teamInfo: action.payload.teamInfo,
                boardData: action.payload.boardData,
                actionItems: action.payload.actionItems,
            };
        case 'update':
            return {
                teamInfo: state.teamInfo,
                boardData: state.boardData,
                actionItems: action.payload,
            };
        default: throw new Error();
    }
};

const RetroCompleted = () => {
    const { id: boardID } = useParams();
    const user = useContext(CurrentUserContext);
    document.title = 'Board Completed - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
    const [state, dispatch] = useReducer(reducer, getDefaultState());

    const [loading, setLoading] = useState(true);
    const [actionItem, setActionItem] = useState('');
    const [selectedMember, setSelectedMember] = useState('');
    const [filterByMember, setFilterByMember] = useState('');
    const [dropdownError, setDropdownError] = useState(false);
    const [actionItemError, setActionItemError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [boardUpdatedAfterCompletion, setBoardUpdatedAfterCompletion] = useState(false);

    useEffect(() => {
        GET('/board/retroCompleted', { boardID })
            .then(response => {
                const payload = response.data;
                dispatch({ type: 'hydrate', payload });
                setLoading(false);
            });
        onValue(ref(sync, `boards/${boardID}/actionItems`), data => {
            if (data.val()) {
                dispatch({ type: 'update', payload: data.val() });
                setBoardUpdatedAfterCompletion(true);
            }
        });
    }, [boardID]);

    const submitActionItem = () => {
        if (actionItem.trim() !== '' && selectedMember !== '')
            POST('/actionItem/add', {
                teamID: state.teamInfo.teamID,
                boardID,
                actionItem,
                isAssignedToTeam: selectedMember === state.teamInfo.teamID,
                assignTo: {
                    boardID,
                    uid: selectedMember,
                    teamID: state.teamInfo.teamID,
                    name: getName(selectedMember),
                    photoURL: getProfilePicture(selectedMember),
                },
            })
                .then(() => { setBoardUpdatedAfterCompletion(true); clearActionItem(); });
        else if (actionItem === '') {
            setActionItemError(true);
            setValidationMessage('Please Enter Action Item');
        }
        else {
            setDropdownError(true);
            setValidationMessage('Please choose an option from the dropdown');
        }
    };

    const clearActionItem = () => {
        setActionItem('');
        setSelectedMember('');
        setDropdownError(false);
        setActionItemError(false);
    };

    const getProfilePicture = id => {
        if (state.teamInfo.members[id])
            return state.teamInfo.members[id].photoURL;
        return `https://ui-avatars.com/api/?name=${state.teamInfo.name}&background=random&rounded=true`;
    };

    const getName = id => {
        if (state.teamInfo.members[id])
            return getFormattedName(state.teamInfo.members[id].displayName);
        return state.teamInfo.name;
    };

    const getFacilitatorPhoto = () => {
        return state.teamInfo.members[get(state, ['boardData', 'facilitator', 'currentFacilitator'], '')].photoURL;
    };

    const getMembersDropdown = members => map(members, member => ({ value: member.uid, label: member.displayName }));

    const filterUsers = users => {
        if (filterByMember !== '')
            return filter(users, { assignTo: { uid: filterByMember } });
        return users;
    };

    const redirectToDashboard = () => {
        // ANYONE IN THE TEAM CAN ADD ACTION ITEMS HERE.
        if (user.uid === get(state, ['boardData', 'facilitator', 'currentFacilitator'], ''))
            POST('/board/newUpdates', { boardID, teamID: state.teamInfo.teamID, boardUpdatedAfterCompletion })
                .catch(err => console.log(err));
        // eslint-disable-next-line no-restricted-globals
        location.assign('/dashboard');
    };

    return !loading && <>
        <div className="end-retro__container">
            <div className="board-data__container">
                <div className="end-retro__title">
                    <span className="invert">&#x1F389;</span> Retro Completed &#x1F389;
                </div>
                <div className="board-info">
                    <h1>{state.boardData.board.name}</h1>
                    {state.boardData.board.description !== "" && <p>{state.boardData.board.description}</p>}
                    <p>{new Date(state.boardData.board.createdOn).toDateString()}</p>
                    <div className="tooltip expand tooltip-right" data-tooltip-content="Board facilitator">
                        <img
                            alt="facilitator"
                            src={getFacilitatorPhoto()}
                        />
                    </div>
                    <RetroButton
                        theme="blue"
                        className="mg-tp__20"
                        onClick={redirectToDashboard}
                        aria-label="redirect to dashboard"
                    >
                        Go to Dashboard
                    </RetroButton>
                </div>
            </div>
            <div className="end-retro__title">
                Add More Action Items
            </div>
            <div className="action-items__container action-items__input-container">
                <div className="action-items__input-section">
                    <div
                        className={classnames('wrap-input2', 'validate-input', 'no-margin', {
                            'alert-validate': actionItemError || dropdownError
                        })}
                        data-validate={validationMessage}
                    >
                        <BorderlessInput
                            className={classnames('input2', 'textarea-theme', 'actionItemInput', {
                                'has-val': actionItem !== ''
                            })}
                            value={actionItem}
                            setValue={setActionItem}
                            aria-label="Action Item"
                            placeholder="Enter Action Item here"
                            handleSubmit={item => setActionItem(item)}
                        />
                    </div>
                </div>
                <div className="action-items__dropdown-section">
                    <div className="input-row actionItems__dropdown">
                        <FormDropdown
                            optionZero={state.teamInfo.name}
                            optionZeroValue={state.teamInfo.teamID}
                            aria-label="members dropdown"
                            label="--Choose Team Member--"
                            selectedOption={selectedMember}
                            options={getMembersDropdown(state.teamInfo.members)}
                            onDropdownChange={e => setSelectedMember(e.target.value)}
                        />
                    </div>
                </div>
                <div className="action-items__icon-section">
                    <div className="input-row actionItems__icons">
                        <button
                            onClick={submitActionItem}
                            className="addActionItems retro-end tooltip tooltip-bottom expand"
                            data-tooltip-content="Submit action item"
                        >
                            <FcCheckmark />
                        </button>
                        <button
                            onClick={clearActionItem}
                            className="addActionItems retro-end tooltip tooltip-bottom expand"
                            data-tooltip-content="Clear action item"
                        >
                            <CgClose />
                        </button>
                    </div>
                </div>
            </div>
            <div className="action-items__end-retro-title--container">
                <div className="end-retro__title">
                    Action Items Created
                </div>
                <div className="end-retro__action-items--dropdown">
                    <FormDropdown
                        optionZero={state.teamInfo.name}
                        optionZeroValue={state.teamInfo.teamID}
                        aria-label="members dropdown"
                        label="--Filter by user--"
                        selectedOption={filterByMember}
                        options={getMembersDropdown(state.teamInfo.members)}
                        onDropdownChange={e => setFilterByMember(e.target.value)}
                    />
                </div>
            </div>
            <div className="action-item-box">
                <ActionItemsContainer
                    isEndOfRetro
                    title="Action Items Created"
                    actionItems={filterUsers(state.actionItems)}
                    setBoardUpdatedAfterCompletion={setBoardUpdatedAfterCompletion}
                />
            </div>
        </div>
    </>;
};

export default RetroCompleted;