import alertify from 'alertifyjs';
import React, { useContext, useState } from 'react';
import RetroButton from '../Form/RetroButton';
import { POST } from '../../services/HTTPHandler';
import SettingsInput from '../Form/SettingsInput';
import { getProfilePicture } from '../../utils/user';
import { nameRegex, emailRegex } from '../../utils/regex';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

const DEFAULT_USER = {
    email: '',
    photoURL: '',
    displayName: '',
};

const UserUpdate = () => {
    const user = useContext(CurrentUserContext) || DEFAULT_USER;
    const [email, setEmail] = useState(user.email);
    const [photoURL, setPhotoURL] = useState(user.photoURL);
    const [lastName, setLastName] = useState(user.displayName.split('.')[1]);
    const [firstName, setFirstName] = useState(user.displayName.split('.')[0]);
    const [errMsg, setErrMsg] = useState({ fname: null, lname: null, email: null });

    const validateForm = () => {
        const errorMessages = {};
        if (firstName.trim() === '')
            errorMessages['fname'] = 'Required';
        else if (!nameRegex.test(firstName) && firstName !== user.displayName.split('.')[0])
            errorMessages['fname'] = 'Invalid';

        if (lastName.trim() === '')
            errorMessages['lname'] = 'Required';
        else if (!nameRegex.test(lastName) && lastName !== user.displayName.split('.')[1])
            errorMessages['lname'] = 'Invalid';

        if (email.trim() === '')
            errorMessages['email'] = 'Required';
        else if (!emailRegex.test(email) && email !== user.email)
            errorMessages['email'] = 'Invalid';
        setErrMsg({ ...errMsg, ...errorMessages });
        return Object.keys(errorMessages).length === 0;
    };

    const updateProfile = e => {
        e.preventDefault();
        if (validateForm())
            POST('/user/updateProfile', { firstName, lastName, email, photoURL: getProfilePicture(firstName, lastName) })
                .then(() => {
                    setPhotoURL(getProfilePicture(firstName, lastName));
                    alertify.notify('Profile Updated', 'success', 3)
                });
    };

    return <div className="settings__content--container">
        <div className="settings__form settings-padding" data-fade-in>
            <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Public avatar</h3>
                    <p className="mt-0">You can change your avatar here or remove the current avatar to revert to ui-avatars.com</p>
                </div>
                <div className="settings__row--value">
                    <img src={photoURL} alt="Profile pic" />
                    <div className="displayImage">
                        <p className="display-image__text mt-0">Upload new avatar</p>
                        <RetroButton
                            disabled
                            size="sm"
                            className="upload__button"
                        >
                            Choose file...
                        </RetroButton>
                        <p className="display-image__text mt-0">The maximum file size allowed is 200KB.</p>
                        <RetroButton
                            disabled
                            size="xs"
                            theme="danger"
                            className="upload__button"
                        >
                            Remove Avatar
                        </RetroButton>
                    </div>
                </div>
            </div>
            <div className="settings__row">
                <div className="settings__row--description">
                    <h3 className="mt-0">Main settings</h3>
                    <p className="mt-0">This information will appear on your profile</p>
                </div>
                <div className="settings__row--value">
                    <form className="signup__form settings-input__wrapper w-100" onSubmit={updateProfile} data-fade-in>
                        <SettingsInput
                            type="text"
                            value={firstName}
                            label="Firstname"
                            autoComplete="off"
                            aria-label="firstname"
                            placeholder="Enter Firstname"
                            isError={errMsg.fname !== null}
                            onChange={e => setFirstName(e.target.value)}
                            onFocus={e => setErrMsg({ ...errMsg, fname: null })}
                        />
                        <SettingsInput
                            type="text"
                            value={lastName}
                            label="Lastname"
                            autoComplete="off"
                            aria-label="Lastname"
                            placeholder="Enter Lastname"
                            isError={errMsg.lname !== null}
                            onChange={e => setLastName(e.target.value)}
                            onBlur={() => getProfilePicture(firstName, lastName)}
                            onFocus={e => setErrMsg({ ...errMsg, lname: null })}
                        />
                        <SettingsInput
                            type="email"
                            value={email}
                            label="Email"
                            autoComplete="off"
                            aria-label="Enter Email"
                            placeholder="Enter Email"
                            isError={errMsg.email !== null}
                            onChange={e => setEmail(e.target.value)}
                            onFocus={e => setErrMsg({ ...errMsg, email: null })}
                        />
                    </form>
                </div>
            </div>
            <div className="profile__submit">
                <RetroButton
                    size="lg"
                    aria-label="save changes"
                    onClick={updateProfile}
                >
                    Save Changes
                </RetroButton>
            </div>
        </div>
    </div>;
};

export default UserUpdate;