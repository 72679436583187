import React from 'react';
import Navbar from './Navigation/Navbar';
import { BiErrorAlt } from 'react-icons/bi';
import Link from './Form/Link';

const BoardNotFound = () => {

    return <>
        <Navbar redirectLogoToDashboard />
        <div className="board__404--conatiner">
            <div className="board__404--staging flex-center">
                <BiErrorAlt />
                <h1>404</h1>
                <h2>Oops, Board not found</h2>
                <Link
                    linkAsButton
                    theme="blue"
                    href="/dashboard"
                    aria-label="navigate to dashboard"
                >Go to Dashboard</Link>
            </div>
        </div>
    </>;
};

export default BoardNotFound;