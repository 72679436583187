import React from 'react';
import times from 'lodash/times';
import '../../styles/voteDisplay.css';

const VoteDisplay = props => {
    const { voteCount } = props;

    return (
        <div className="display-vote__container">
            {times(voteCount, (i) => <span key={i} className="display-vote" />)}
        </div>
    );
};

export default VoteDisplay;