import React, { useEffect, useState } from 'react';
import { GET } from '../services/HTTPHandler';
import Navbar from '../components/Navigation/Navbar';
import PageNotFound from '../components/PageNotFound';
import OrderConfirmed from '../components/Checkout/OrderConfirmed';
import Loader from '../components/Loader';
import { get } from 'lodash';

const OrderCompleted = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [entitlements, setEntitlements] = useState({});
    const [subscription, setSubscription] = useState({});
    const [invoiceUnavailable, setInvoiceUnavailable] = useState(false);

    useEffect(() => {
        document.title = 'Order Confirmation - Retrospective Tool for Agile Teams | Scrum Sprint Retrospective';
        GET('/order/invoice')
            .then(response => {
                setIsLoading(false);
                setInvoiceInfo(get(response, ['data', 'invoiceInfo'], {}));
                setSubscription(get(response, ['data', 'subscription'], {}));
                setEntitlements(get(response, ['data', 'entitlements'], {}));
            })
            .catch(error => {
                setIsLoading(false);
                setInvoiceUnavailable(true);
            });
    }, []);

    return isLoading ? <Loader /> : (!invoiceUnavailable ? <>
        <Navbar redirectLogoToDashboard />
        <OrderConfirmed
            invoiceInfo={invoiceInfo}
            subscription={subscription}
            entitlements={entitlements}
        />
    </> : <PageNotFound />);
};

export default OrderCompleted;