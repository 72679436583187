import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import SettingsInput from './SettingsInput';
import './settingsPasswordInput.css';

const SettingsPasswordInput = props => {
    const { isError, checkPassword, onChange, strengthIndicator, ...rest } = props;

    const weakConditionRef = useRef(null);
    const mediumConditionRef = useRef(null);
    const strongConditionRef = useRef(null);

    const [conditions, setConditions] = useState({});

    const handleChange = e => {
        setConditions(checkPassword(e.target.value));
        onChange(e);
    };

    const isConditionWeak = conditions => (conditions.lowercase);

    const isConditionMedium = conditions => (conditions.lowercase && conditions.length && conditions.uppercase);

    const isConditionStrong = conditions => (conditions.all);

    const getProgressBarText = conditions => {
        if (weakConditionRef && mediumConditionRef && strongConditionRef)
            if (isConditionStrong(conditions)) {
                weakConditionRef.current.style.backgroundColor = '#2DAF7D';
                mediumConditionRef.current.style.backgroundColor = '#2DAF7D';
                strongConditionRef.current.style.backgroundColor = '#2DAF7D';
                return 'Strong Password';
            }
            else if (isConditionMedium(conditions)) {
                weakConditionRef.current.style.backgroundColor = '#F9AE35';
                mediumConditionRef.current.style.backgroundColor = '#F9AE35';
                strongConditionRef.current.style.backgroundColor = 'transparent';
                return 'Medium Strength';
            }
            else if (isConditionWeak(conditions)) {
                weakConditionRef.current.style.backgroundColor = '#FF4B47';
                mediumConditionRef.current.style.backgroundColor = 'transparent';
                strongConditionRef.current.style.backgroundColor = 'transparent';
                return 'Weak Password';
            }
        return 'Password is Blank';
    }

    return <div className="settings-input__wrapper">
        <SettingsInput
            type="password"
            autoComplete="off"
            onChange={handleChange}
            {...rest}
        />
        {strengthIndicator && <>
            <div className="progress-bar_wrap">
                <div ref={weakConditionRef} className={classnames('progress-bar_item', 'progress-bar_item-1', {
                    'active': isConditionWeak(conditions),
                })} />
                <div ref={mediumConditionRef} className={classnames('progress-bar_item', 'progress-bar_item-2', {
                    'active': isConditionMedium(conditions),
                })} />
                <div ref={strongConditionRef} className={classnames('progress-bar_item', 'progress-bar_item-3', {
                    'active': isConditionStrong(conditions),
                })} />
            </div>
            <span className="progress-bar_text">{getProgressBarText(conditions)}</span>
        </>}
    </div>;
};

export default SettingsPasswordInput;