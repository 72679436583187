import React from 'react';
import { getTrialDaysLeft } from '../../utils/user';
import { Button, Link } from '@nextui-org/react';
import { IoWarningOutline } from "react-icons/io5";

const UpgradeButton = props => {
    const { userStatus } = props;
    const daysLeft = getTrialDaysLeft(userStatus.trialEndDate);
    return <>
        <span className="freeTrial__text">
            <IoWarningOutline className='text-2xl' />
            {daysLeft > 0 ? `${daysLeft} day(s) left in your free trial` :
                'Free trial expired'}
            <Button
                as={Link}
                size="sm"
                color="primary"
                variant="ghost"
                href="/upgrade/single"
                aria-label="Subscribe premium plan"
            >
                Upgrade
            </Button>
        </span>
    </>;
};

export default UpgradeButton;