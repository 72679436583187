import React, { useContext, useReducer, useState } from 'react';
import alertify from 'alertifyjs';
import classnames from 'classnames';
import RetroButton from '../Form/RetroButton';
import { POST } from '../../services/HTTPHandler';
import { reAuthenticateUsers } from '../../services/firebase';
import SettingsPasswordInput from '../Form/SettingsPasswordInput';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import {
    numberRegex,
    passwordRegex,
    uppercaseRegex,
    lowercaseRegex,
    specialCharRegex,
} from '../../utils/regex';

const getDefaultState = () => ({
    conditions: {},
    passwordError: false,
    passwordMatchFailed: false,
    currentPasswordEmpty: true,
    passwordConditionsFailed: false,
});

const reducer = (state, action) => {
    switch (action.type) {
        case 'emptyCurrentPassword':
            return {
                passwordError: true,
                passwordMatchFailed: true,
                currentPasswordEmpty: true,
                conditions: state.conditions,
                passwordConditionsFailed: false,
            };
        case 'newPasswordFailed':
            return {
                passwordError: false,
                passwordMatchFailed: false,
                currentPasswordEmpty: false,
                passwordConditionsFailed: true,
                conditions: checkPassword(action.payload),
            };
        case 'confirmPasswordFailed':
            return {
                passwordError: false,
                passwordMatchFailed: false,
                currentPasswordEmpty: false,
                passwordConditionsFailed: true,
                conditions: checkPassword(action.payload),
            };
        case 'passwordMatchFailed':
            return {
                passwordError: true,
                passwordMatchFailed: true,
                currentPasswordEmpty: false,
                conditions: state.conditions,
                passwordConditionsFailed: false,
            };
        case 'validated':
            return {
                passwordError: false,
                passwordMatchFailed: false,
                currentPasswordEmpty: false,
                conditions: state.conditions,
                passwordConditionsFailed: false,
            };
        default: throw new Error();
    }
};

const checkPassword = password => ({
    length: password.length >= 8,
    all: passwordRegex.test(password),
    number: numberRegex.test(password),
    uppercase: uppercaseRegex.test(password),
    lowercase: lowercaseRegex.test(password),
    special: specialCharRegex.test(password),
});

const PasswordUpdate = () => {
    const user = useContext(CurrentUserContext);
    const [state, dispatch] = useReducer(reducer, getDefaultState());
    const [newPassword, setNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPasswordFailed, setCurrentPasswordFailed] = useState(false);

    const validateAllPasswords = () => {
        if (currentPassword.length === 0) {
            dispatch({ type: 'emptyCurrentPassword' });
            return false;
        }
        if (!checkPassword(newPassword).all) {
            dispatch({ type: 'newPasswordFailed', payload: newPassword });
            return false;
        }
        if (!checkPassword(confirmPassword).all) {
            dispatch({ type: 'newPasswordFailed', payload: confirmPassword });
            return false;
        }
        if (newPassword !== confirmPassword) {
            dispatch({ type: 'passwordMatchFailed' });
            return false;
        }
        dispatch({ type: 'validated' });
        return true;
    };

    const getErrorCondition = () => {
        if (state.currentPasswordEmpty) return 'Please Enter Current Password';
        if (state.passwordMatchFailed) return 'Passwords don\'t match';
        if (currentPasswordFailed) return 'Current Password is Invalid';
    };

    const passwordUpdateSuccess = () => {
        setNewPassword('');
        setCurrentPassword('');
        setConfirmPassword('');
        setCurrentPasswordFailed(false);
        alertify.notify('Password Updated', 'success', 1.5);
    };

    const updatePassword = e => {
        e.preventDefault();
        if (validateAllPasswords()) {
            reAuthenticateUsers(currentPassword).then(() => {
                // TODO : Encrypt the password
                POST('/user/updatePassword', { newPassword })
                    .then(() => passwordUpdateSuccess());
            }).catch(err => {
                console.log(err);
                if (err.code === 'auth/wrong-password')
                    setCurrentPasswordFailed(true);
            });
        }
    };

    return <div className="settings__content--container">
        <form className="signup__form settings-padding" onSubmit={updatePassword} data-fade-in>
            <SettingsPasswordInput
                value={currentPassword}
                label="Current Password"
                strengthIndicator={false}
                aria-label="Enter password"
                checkPassword={checkPassword}
                onChange={e => setCurrentPassword(e.target.value)}
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            />
            <SettingsPasswordInput
                value={newPassword}
                label="New Password"
                strengthIndicator={true}
                aria-label="Enter password"
                checkPassword={checkPassword}
                onChange={e => setNewPassword(e.target.value)}
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            />
            <SettingsPasswordInput
                value={confirmPassword}
                strengthIndicator={true}
                aria-label="Enter password"
                label="Confirm  New Password"
                checkPassword={checkPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            />
            {state.passwordError && <div className="password-rules">
                <div className="condition-wrapper">
                    <p>{getErrorCondition()}</p>
                </div>
            </div>}
            {state.passwordConditionsFailed && <div className="password-rules">
                <div className="condition-wrapper">
                    <p>Password must contain:</p>
                </div>
                <div className="condition-wrapper">
                    <p className={classnames({ 'condition-passed': state.conditions.number })}>Atleast 1 Number</p>
                </div>
                <div className="condition-wrapper">
                    <p className={classnames({ 'condition-passed': state.conditions.length })}>Atleast 8 Characters</p>
                </div>
                <div className="condition-wrapper">
                    <p className={classnames({ 'condition-passed': state.conditions.special })}>Atleast 1 Special Character</p>
                </div>
                <div className="condition-wrapper">
                    <p className={classnames({ 'condition-passed': state.conditions.uppercase })}>Atleast 1 Uppercase alphabet</p>
                </div>
                <div className="condition-wrapper">
                    <p className={classnames({ 'condition-passed': state.conditions.lowercase })}>Atleast 1 Lowercase alphabet</p>
                </div>
            </div>}
            <RetroButton
                size="lg"
                className="mt-20"
                aria-label="update password"
                onClick={updatePassword}
            >
                Update Password
            </RetroButton>
        </form>
    </div>;
};

export default PasswordUpdate;