import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Avatar } from "@nextui-org/react";
import { signup } from '../../helpers/auth';
import { IoEye, IoEyeOff } from "react-icons/io5";
import { getProfilePicture } from '../../utils/user';
import { Input, Button, Link } from "@nextui-org/react";
import {
    numberRegex,
    passwordRegex,
    uppercaseRegex,
    lowercaseRegex,
    specialCharRegex,
    emailRegex,
} from '../../utils/regex';
import { IoWarningOutline } from "react-icons/io5";

const SignupForm = ({ setIsNewUser }) => {
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [conditions, setConditions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [signupEmail, setSignupEmail] = useState('');

    const [signupError, setSignupError] = useState(null);
    const [couponCode, setCouponCode] = useState(undefined);
    const [signupPassword, setSignupPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const [photoURL, setPhotoURL] = useState('https://raw.githubusercontent.com/arvindrk/my-retro/master/user.svg');

    const validateEmail = (value) => value.match(emailRegex);

    const isEmailInvalid = useMemo(() => {
        if (signupEmail === "") return false;

        return !validateEmail(signupEmail);
    }, [signupEmail]);

    const validatePassword = (value) => value.match(passwordRegex);

    const isPasswordInvalid = useMemo(() => {
        if (signupPassword === "") return false;

        return !validatePassword(signupPassword);
    }, [signupPassword]);

    const isFormValid = () => {
        return !isEmailInvalid && !isPasswordInvalid;
    };

    const updatePasswordConditions = pass => {
        const updatedConditions = {
            length: pass.length >= 8,
            all: passwordRegex.test(pass),
            number: numberRegex.test(pass),
            uppercase: uppercaseRegex.test(pass),
            lowercase: lowercaseRegex.test(pass),
            special: specialCharRegex.test(pass),
        };
        setConditions(updatedConditions);
    };

    const updatePassword = e => {
        setSignupPassword(e.target?.value);
        updatePasswordConditions(e.target?.value);
    }

    const handleSignup = async e => {
        setIsLoading(true);
        e.preventDefault();
        setSignupError(null);
        if (isFormValid()) {
            try {
                setPasswordError(false);
                await signup({
                    photoURL,
                    lastName,
                    firstName,
                    couponCode,
                    email: signupEmail,
                    password: signupPassword,
                })
            } catch (error) {
                const errorCode = error.response.status;
                errorCode === 409 ? setSignupError('Account already exists. Please login') : setSignupError('Invalid User Details');
            }
        } else {
            setPasswordError(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setPhotoURL(getProfilePicture(firstName, lastName));
    }, [firstName, lastName]);

    return <>
        <div data-fade-in className="signup__image">
            <Avatar showFallback isBordered src={photoURL} size="md" />
        </div>
        <form className="signup__form" onSubmit={handleSignup}>
            {signupError && <p className="form-error">
                <IoWarningOutline />
                {signupError}
            </p>}
            <div className="signup__name" data-fade-in>
                <Input
                    isRequired
                    label="First Name"
                    value={firstName}
                    onValueChange={setFirstName}
                    aria-label="Enter First Name"
                    />
                <Input
                    isRequired
                    label="Last Name"
                    value={lastName}
                    onValueChange={setLastName}
                    aria-label="Enter Last Name"
                />
            </div>
            <Input
                isRequired
                isClearable
                type="email"
                label="Email"
                value={signupEmail}
                aria-label="Enter Email"
                onValueChange={setSignupEmail}
                isInvalid={signupError && isEmailInvalid}
                color={signupError && isEmailInvalid ? "danger" : "default"}
                errorMessage={signupError && isEmailInvalid && "Please enter a valid email"}
            />
            <Input
                isRequired
                label="Password"
                value={signupPassword}
                aria-label="Enter password"
                onChange={e => updatePassword(e)}
                isInvalid={signupError && isPasswordInvalid}
                color={passwordError ? "danger" : "default"}
                type={isPasswordHidden ? "password" : "text"}
                endContent={
                    <button type="button" onClick={() => setIsPasswordHidden(!isPasswordHidden)}>
                        {isPasswordHidden ? (
                            <IoEye className="text-2xl text-default-400 pointer-events-none" />
                        ) : (
                            <IoEyeOff className="text-2xl text-default-400 pointer-events-none" />
                        )}
                    </button>
                }
            />
            <Input
                label="Coupon code (Optional)"
                value={couponCode}
                onValueChange={setCouponCode}
                aria-label="Enter Coupon Code"
            />
            {passwordError && !conditions.all && <div className="password-rules" data-fade-in>
                <div className="condition-wrapper">
                    <p>Password must contain:</p>
                </div>
                <div className="condition-wrapper">
                    <p data-fade-in className={classnames({ 'condition-passed': conditions.number })}>Atleast 1 Number</p>
                </div>
                <div className="condition-wrapper">
                    <p data-fade-in className={classnames({ 'condition-passed': conditions.length })}>Atleast 8 Characters</p>
                </div>
                <div className="condition-wrapper">
                    <p data-fade-in className={classnames({ 'condition-passed': conditions.special })}>Atleast 1 Special Character</p>
                </div>
                <div className="condition-wrapper">
                    <p data-fade-in className={classnames({ 'condition-passed': conditions.uppercase })}>Atleast 1 Uppercase alphabet</p>
                </div>
                <div className="condition-wrapper">
                    <p data-fade-in className={classnames({ 'condition-passed': conditions.lowercase })}>Atleast 1 Lowercase alphabet</p>
                </div>
            </div>}
            <Button isLoading={isLoading} color="primary" type="submit" data-fade-in fullWidth>
                Signup
            </Button>
            <div className="goto-login">
                Already a Member?{' '}
                <Link
                    color="primary"
                    variant="light"
                    underline="always"
                    aria-label="Go to Login form"
                    onClick={() => setIsNewUser(false)}
                >
                    Login
                </Link>
            </div>
        </form>
    </>;
};

export default SignupForm;