import React from 'react';
import { FaUser } from 'react-icons/fa';
import FeatureBox from '../Checkout/FeatureBox';
import Link from '../Form/Link';

const SingleUserPremiumContainer = props => {
    const { isAnnual } = props;
    return <div className="premium__container">
        <div className="icon__container">
            <FaUser data-fade-in />
        </div>
        <h2 className="plan__description">Single User Plan</h2>
        <div className="plan__cost">
            {isAnnual && <div className="previous-price" data-fade-in>$60</div>}
            {isAnnual ? '$50' : '$5'}
        </div>
        <p className="plan__term" data-fade-in>{isAnnual ? '/ year' : '/ month'}</p>
        <FeatureBox />
        <Link
            theme="blue"
            linkAsButton={true}
            href="/upgrade/single"
            aria-label="Subscribe premium plan"
        >
            Go Premium
        </Link>
    </div>;
};

export default SingleUserPremiumContainer;
