import NotificationsPopOver from '../PopOver/NotificationsPopOver';
import { MdNotifications } from 'react-icons/md';
import '../../styles/Navigation/notifications.css';
import {Popover, PopoverTrigger, PopoverContent, Button, Badge} from "@nextui-org/react";

const Notifications = props => {
    const { notifications = {}, count } = props;

    return <div className="notifications">
        <Popover placement="bottom-end">
            <PopoverTrigger>
                <Button
                    isIconOnly
                    variant="light"
                    aria-label="notitifications"
                >
                    <Badge isInvisible={!count} content={count} shape="circle" color="danger">
                        <MdNotifications className='text-2xl' />
                    </Badge>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[240px]">
                <NotificationsPopOver
                    notifications={notifications}
                />
            </PopoverContent>
        </Popover>
    </div>;
};

export default Notifications;