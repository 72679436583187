import alertify from 'alertifyjs';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { POST } from '../../services/HTTPHandler';
import React, { useState, useContext } from 'react';
import BorderlessInput from '../Form/BorderlessInput';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

import '../../styles/actionItemCard.css';

const ActionItemCard = props => {
    const { uid: userID } = useContext(CurrentUserContext);
    const { content, boardID, teamID, profilePicture, actionItemKey, isAssignedToTeam } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [locallyUpdatedContent, setLocallyUpdatedContent] = useState(content);
    // TODO: Replace with some kind of delete confirmation
    const confirmDelete = true;

    const deleteActionItem = deleteKey => {
        if (confirmDelete)
            POST('/actionItem/delete', { boardID, teamID, deleteKey, isAssignedToTeam, userID });
    };

    const validateActionItem = item => item.trim() !== '';

    const submitActionItemContent = updatedItem => {
        if (validateActionItem(updatedItem) && updatedItem !== content) {
            POST('/actionItem/edit', { 
                teamID,
                userID,
                boardID,
                actionItemKey,
                isAssignedToTeam,
                content: updatedItem,
            }).then(() => {
                setLocallyUpdatedContent(updatedItem);
                setIsEditing(false);
            })
            .catch(err => alertify.notify('Unable to update', 'error', 3, function () { console.log(err) }));
        }
    };

    return <div className="actionItem__card">
        <img src={profilePicture} alt="User Profile" />
        <div className="actionItem__content">{!isEditing ? content :
            <BorderlessInput
                aria-label="Card content"
                value={locallyUpdatedContent}
                setValue={setLocallyUpdatedContent}
                handleSubmit={submitActionItemContent}
            />
        }</div>
        <div className="actionItem__icons">
            <button onClick={() => setIsEditing(true)}><FaRegEdit /></button>
            <button onClick={() => deleteActionItem(actionItemKey)}><RiDeleteBin2Fill /></button>
        </div>
    </div>;
};

export default ActionItemCard;