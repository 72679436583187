import React from 'react';

const MultipleTeamDiscount = props => {
    const { isAnnual, noOfTeams } = props;

    return <div className="plan__multiple-teams">
        <div className={`team-cost__container ${noOfTeams < 3 ? 'active' : ''}`}>
            <h2 className="team-cost">$20</h2>
            <p>per team / {isAnnual ? 'year' : 'month'}</p>
            <p className="team-cost__count">1 - 2 Teams</p>
        </div>
        <div className={`team-cost__container ${(noOfTeams > 2 && noOfTeams < 8) ? 'active' : ''}`}>
            <h2 className="team-cost">$18</h2>
            <p>per team / {isAnnual ? 'year' : 'month'}</p>
            <p className="team-cost__count">3 - 7 Teams</p>
        </div>
        <div className={`team-cost__container ${noOfTeams > 7 ? 'active' : ''}`}>
            <h2 className="team-cost">$16</h2>
            <p>per team / {isAnnual ? 'year' : 'month'}</p>
            <p className="team-cost__count">7+ Teams</p>
        </div>
    </div>;
};

export default MultipleTeamDiscount;