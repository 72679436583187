import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import classnames from 'classnames';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { getProfilePicture } from '../../utils/user';
import CreateTeamModal from '../Modals/CreateTeamModal';

import '../../styles/teams.css';

const Teams = props => {
    const {
        teams,
        onSubmit,
        emptyTeams,
        userStatus,
        errorStatus,
        displayModal,
        setErrorStatus,
        setDisplayModal,
        onSuccessLoader,
    } = props;

    const addTeamClasses = classnames('dashboard-title__create', 'tooltip', 'expand', 'tooltip-right');

    const getAddTeamTooltip = () => (userStatus.ownedTeamCount >= userStatus.allowedTeamCount ? 'Upgrade plan to add more teams' : 'Add team');

    return (
        <>
            <div className="dashboard-title">
                Your Teams
                <button
                    aria-label="add teams"
                    className={addTeamClasses}
                    onClick={() => setDisplayModal(true)}
                    data-tooltip-content={getAddTeamTooltip()}
                    disabled={userStatus.ownedTeamCount >= userStatus.allowedTeamCount}
                >
                    <AiOutlinePlusCircle />
                </button>
            </div>
            <div className="user-teams__container">
                {!emptyTeams && teams ? map(teams, team => {
                    team = get(team, 'teamInfo', {});
                    return (
                        <a key={team.teamID} href={team.teamRoomURL} className="team__card">
                            <div className="team__title">
                                <img src={getProfilePicture(team.name)} alt="team" />
                                {team.name}
                            </div>
                        </a>
                    );
                }) :
                    <div className="user-teams__empty">
                        <img src="https://raw.githubusercontent.com/arvindrk/my-retro/master/teams.svg" alt="Empty teams" />
                    </div>
                }
            </div>
            <CreateTeamModal
                onSubmit={onSubmit}
                errorStatus={errorStatus}
                displayModal={displayModal}
                setErrorStatus={setErrorStatus}
                onSuccessLoader={onSuccessLoader}
                closeModal={() => (setDisplayModal(!displayModal))}
            />
        </>
    );
};

export default Teams;