import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';
import "core-js/stable";
import "regenerator-runtime/runtime";
import './styles/tooltip.css';
import Home from './pages/Home';
import Retro from './pages/Retro';
import alertify from 'alertifyjs';
import Login from './pages/Login';
import Cancel from './pages/Cancel';
import TeamRoom from './pages/TeamRoom';
import Settings from './pages/Settings';
import Checkout from './pages/Checkout';
import Loader from './components/Loader';
import Dashboard from './pages/Dashboard';
import AllBoards from './pages/AllBoards';
import 'alertifyjs/build/css/alertify.css';
import { auth } from './services/firebase';
import JoinRetro from './components/JoinRetro';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import smoothscroll from 'smoothscroll-polyfill';
import { onAuthStateChanged } from 'firebase/auth';
import RetroCompleted from './pages/RetroCompleted';
import OrderCompleted from './pages/OrderCompleted';
// import PageNotFound from './components/PageNotFound';
import TermsAndConditions from './components/TermsAndConditions';
import { CurrentUserProvider } from './contexts/CurrentUserContext';

smoothscroll.polyfill();
alertify.set('notifier', 'position', 'bottom-right');

function App() {
	const [currentUser, setCurrentUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [authenticated, setAuthenticated] = useState(false);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setAuthenticated(true);
				const { displayName, email, photoURL, uid, emailVerified } = user
				setCurrentUser({ displayName, email, photoURL, uid, emailVerified });
				setLoading(false);
			} else {
				setAuthenticated(false);
				setLoading(false);
			}
		})
	}, []);

	return loading === true ? <Loader /> : (
		<Router>
			<CurrentUserProvider {...currentUser}>
				<Routes>
					<Route exact path="/" element={<Home/>} />
					<Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
					<Route exact path="/login" element={<PublicRoute authenticated={authenticated} component={Login} />} />
					<Route exact path="/signup" element={<PublicRoute authenticated={authenticated} component={Login} signUpURL={true} />} />
					<Route exact path="/retro/:id" element={<PrivateRoute redirectTo="dashboard" authenticated={authenticated} component={Retro} />} />
					<Route exact path="/room/:id" element={<PrivateRoute redirectTo="dashboard" authenticated={authenticated} component={TeamRoom} />} />
					<Route exact path="/settings/:type" element={<PrivateRoute redirectTo="settings/user" authenticated={authenticated} component={Settings} />} />
					<Route exact path="/retro/:id/join" element={<PrivateRoute redirectTo="dashboard" authenticated={authenticated} component={JoinRetro} />} />
					<Route exact path="/dashboard" element={<PrivateRoute redirectTo="dashboard" authenticated={authenticated} component={Dashboard} />} />
					<Route exact path="/order/cancel" element={<PrivateRoute redirectTo="dashboard" authenticated={authenticated} component={Cancel} />} />
					<Route exact path="/boards/all" element={<PrivateRoute redirectTo="boards/all" authenticated={authenticated} component={AllBoards} />} />
					<Route exact path="/order/success" element={<PrivateRoute redirectTo="order/success" authenticated={authenticated} component={OrderCompleted} />} />
					<Route exact path="/retro/:id/end" element={<PrivateRoute redirectTo="dashboard" authenticated={authenticated} component={RetroCompleted} />} />
					<Route exact path="/upgrade/team" element={<PrivateRoute redirectTo="upgrade/team" authenticated={authenticated} component={Checkout} upgradeTo="team" />} />
					<Route exact path="/upgrade/single" element={<PrivateRoute redirectTo="upgrade/single" authenticated={authenticated} component={Checkout} upgradeTo="single" />} />
				</Routes>
			</CurrentUserProvider>
		</Router>
	);
}

export default App;
