import React, { useContext } from 'react';
import map from 'lodash/map';
import AppreciationRow from './AppreciationRow';
import { getFormattedName } from '../../utils/user';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

import '../../styles/appreciationCard.css';

const AppreciationCard = props => {
    const user = useContext(CurrentUserContext);
    const { boardID, userCards, forUser = {}, members, retroStep, isDiscussion } = props;

    return (
        <div className="card card__appreaciation">
            <div className="user-cards__owner">
                <div className="user-cards__owner-image">
                    <img src={forUser.photoURL} alt="User" />
                </div>
                <div className="user-cards__owner-name">{getFormattedName(forUser.displayName)}</div>
            </div>
            <div className="user-cards__container">
                {map(userCards, (card, key) => {
                    return <AppreciationRow
                        key={key}
                        card={card}
                        cardKey={key}
                        uid={user.uid}
                        boardID={boardID}
                        forUser={forUser}
                        members={members}
                        retroStep={retroStep}
                        isDiscussion={isDiscussion}
                    />
                })}
            </div>
        </div>
    );
};

export default AppreciationCard;