import React from 'react';
import Link from '../Form/Link';
import { RiTeamFill } from 'react-icons/ri';
import RangeSlider from '../Form/RangeSlider';
import FeatureBox from '../Checkout/FeatureBox';
import { getFullPlanCost, getPlanCost } from '../../utils/price';

const TeamPlanContainer = props => {
    const { isAnnual, modifyPrice = false, noOfTeams, setNoOfTeams } = props;

    return <div className="team__container">
        <div className="icon__container">
            <RiTeamFill data-fade-in />
        </div>
        <h2 className="plan__description">Team Plan</h2>
        <p className="plan__team-size" data-fade-in>{noOfTeams} Team{`${noOfTeams > 1 ? 's' : ''}`}</p>
        <div className="plan__cost">
            {(isAnnual || noOfTeams > 2) && <div className="previous-price" data-fade-in>{'$' + getFullPlanCost({ isAnnual, isTeam: true, noOfTeams })}</div>}
            {'$' + getPlanCost({ isAnnual, isTeam: true, noOfTeams })}
        </div>
        <p className="plan__term" data-fade-in>{isAnnual ? '/ year' : '/ month'}</p>
        {modifyPrice && <div className="plan__team-count" data-fade-in>
            <RangeSlider start={1} end={10} step={1} value={noOfTeams} onChange={e => setNoOfTeams(e.target.value)} />
        </div>}
        <FeatureBox isTeam isComparison />
        <Link
            theme="blue"
            href="/upgrade/team"
            linkAsButton={true}
            aria-label="Subscribe teams plan"
        >
            Get Team
        </Link>
    </div>;
};

export default TeamPlanContainer;