import alertify from 'alertifyjs';
import { POST } from '../services/HTTPHandler';
import { auth } from '../services/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth';

export function signup(user) {
	// TODO: encrypt user data
	return POST('/user/signup', { user }, { skipToken: true }).then(() => {
		signInWithEmailAndPassword(auth, user.email, user.password)
			// .then((userCredential) => userCredential.user.sendEmailVerification());
	});
};

export function signin(email, password) {
	return signInWithEmailAndPassword(auth, email, password)
		.then(() => POST('/user/signin'));
};

export function sendPasswordResetEmail(email) {
	return sendPasswordResetEmail(auth, email)
		.then(() => alertify.notify('Reset email sent successfully', 'success', 3))
		.catch((error) => {
			var errorCode = error.code;
			if (errorCode === 'auth/user-not-found')
				alertify.notify('Email account not found!', 'error', 3);
		});
}