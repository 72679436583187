import React from 'react';
import Link from './Form/Link';
import { CgWebsite } from 'react-icons/cg';
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';


const SocialIcons = () => <div className="social__icons">
    <Link
        openInNewTab
        aria-label="github"
        href="https://github.com/arvindrk"
    >
        <AiFillGithub />
    </Link>
    <Link
        openInNewTab
        aria-label="linkedin"
        href="https://www.linkedin.com/in/rkarvind/"
    >
        <AiFillLinkedin />
    </Link>
    <Link
        openInNewTab
        aria-label="personal website"
        href="https://arvindrk.com"
    >
        <CgWebsite />
    </Link>
</div>;

export default SocialIcons;