import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import classnames from 'classnames';
import SideDrawer from './SideDrawer';
import { CgClose } from 'react-icons/cg';
import { FcCheckmark } from 'react-icons/fc';
import FormDropdown from '../Form/FormDropdown';
import { POST } from '../../services/HTTPHandler';
import { getFormattedName } from '../../utils/user';
import ActionItemCard from '../Retro/ActionItemCard';
import BorderlessInput from '../Form/BorderlessInput';

import '../../styles/addActionItem.css';

const ActionItemSideDrawer = props => {
    const { active, members, boardID, actionItems, teamInfo = {}, setShowActionItemsDrawer } = props;
    const { name: teamName, teamID } = teamInfo;

    const [actionItem, setActionItem] = useState('');
    const [selectedMember, setSelectedMember] = useState('');
    const [dropdownError, setDropdownError] = useState(false);
    const [actionItemError, setActionItemError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const submitActionItem = () => {
        if (actionItem.trim() !== '' && selectedMember !== '')
            POST('/actionItem/add', {
                teamID,
                boardID,
                actionItem,
                isAssignedToTeam: selectedMember === teamID,
                assignTo: {
                    teamID,
                    boardID,
                    uid: selectedMember,
                    name: getName(selectedMember),
                    photoURL: getProfilePicture(selectedMember),
                },
            })
                .then(() => clearActionItem());
        else if (actionItem === '') {
            setActionItemError(true);
            setValidationMessage('Please Enter Action Item');
        }
        else {
            setDropdownError(true);
            setValidationMessage('Please choose an option');
        }
    };

    const getProfilePicture = id => {
        if (members[id])
            return members[id].photoURL;
        return `https://ui-avatars.com/api/?name=${teamName}&background=random&rounded=true`;
    };

    const getName = id => {
        if (members[id])
            return getFormattedName(members[id].displayName);
        return teamName;
    };

    const clearActionItem = () => {
        setActionItem('');
        setSelectedMember('');
    };

    useEffect(() => {
        if (actionItem !== '')
            setActionItemError(false);
        if (selectedMember !== '')
            setDropdownError(false);
    }, [actionItem, selectedMember]);

    const getMembersDropdown = members => map(members, member => ({ value: member.uid, label: getFormattedName(member.displayName) }));

    return <SideDrawer title="Actions Items" active={active} containerClickHandler={() => setShowActionItemsDrawer(false)}>
        <div
            className={classnames('wrap-input2', 'validate-input', {
                'alert-validate': actionItemError || dropdownError
            })}
            data-validate={validationMessage}
        >
            <BorderlessInput
                className={classnames('input2', 'textarea-theme', 'actionItemInput', {
                    'has-val': actionItem !== ''
                })}
                value={actionItem}
                setValue={setActionItem}
                aria-label="Action Item"
                placeholder="Enter Action Item here"
                handleSubmit={item => setActionItem(item)}
            />
        </div>
        <div className="input-row actionItems__dropdown">
            <FormDropdown
                optionZero={teamName}
                optionZeroValue={teamID}
                aria-label="members dropdown"
                label="--Choose Team Member--"
                selectedOption={selectedMember}
                options={getMembersDropdown(members)}
                onDropdownChange={e => setSelectedMember(e.target.value)}
            />
        </div>
        <div className="input-row actionItems__icons">
            <button
                onClick={submitActionItem}
                className="addActionItems submit-action-item tooltip tooltip-bottom expand"
                data-tooltip-content="Submit action item"
            >
                <FcCheckmark />
            </button>
            <button
                onClick={clearActionItem}
                className="addActionItems clear-action-item tooltip tooltip-bottom expand"
                data-tooltip-content="Clear form"
            >
                <CgClose />
            </button>
        </div>
        <hr className="side-drawer__divider" />
        <div className="scrollable-y">
            {map(actionItems, (item, key) => <ActionItemCard
                key={key}
                teamID={teamID}
                boardID={boardID}
                actionItemKey={key}
                content={item.actionItem}
                isAssignedToTeam={item.isAssignedToTeam}
                profilePicture={getProfilePicture(get(item, ['assignTo', 'uid'], ''))}
            />)}
        </div>
    </SideDrawer>;
};

export default ActionItemSideDrawer;