import React from 'react';

const EndOfRetroCard = props => {
    const { isHidden } = props;

    return <div
        className="card-group__conatiner endOfRetro"
        aria-hidden={isHidden}
    >
        <img src='https://raw.githubusercontent.com/arvindrk/my-retro/master/done2.gif' alt="end retro" />
    </div>;
};

export default EndOfRetroCard;