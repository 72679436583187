import React, { useState } from 'react';
import ToggleSwitch from '../Form/ToggleSwitch';
import { getTrialDaysLeft } from '../../utils/user';

import './pricingModal.css';
import FreeTrialContainer from '../Pricing/FreeTrialContainer';
import SingleUserPremiumContainer from '../Pricing/SingleUserPremiumContainer';
import TeamPlanContainer from '../Pricing/TeamPlanContainer';

const PricingModal = props => {
    const { displayModal, closeModal, userStatus = {} } = props;

    const [isAnnual, setIsAnnual] = useState(false);

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    const daysLeft = getTrialDaysLeft(userStatus.trialEndDate);

    return <div
        data-fade-in
        style={divStyle}
        className="modal"
        onClick={closeModal}
    >
        <div className="modal__content pricing-modal__min-width" onClick={e => e.stopPropagation()}>
            <div className="modal__header">
                <div className="modal__back width0" />
                <h3 className="modal_title">Retro Plans</h3>
                <div className="close">
                    <button className="modal__header--controls" onClick={closeModal}>&times;</button>
                </div>
            </div>
            <div className="modal__form">
                {daysLeft > 0 ? <> <span className="free-trial__days">{daysLeft}</span>
                    <p className="free-trial__reminder">
                        Day(s) left in your Free Trial
                    </p> </> : <p className="free-trial__reminder--expired">
                    Free Trial has Expired
                </p>}
                <div className="plan-duration">
                    <ToggleSwitch
                        option2="Annual"
                        option1="Monthly"
                        spacing={false}
                        value={isAnnual}
                        switchKey="subscription-plan"
                        onChange={() => setIsAnnual(!isAnnual)}
                    />
                </div>
                <div className="pricingContainer">
                    {daysLeft > 0 && <FreeTrialContainer closeModal={closeModal} />}
                    <SingleUserPremiumContainer isAnnual={isAnnual} />
                    <TeamPlanContainer isAnnual={isAnnual} />
                </div>
            </div>
        </div >
    </div >;
};

export default PricingModal;