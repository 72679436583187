import React from 'react';

import '../../styles/cardGroupStatusBar.css';

const CardGroupStatusBar = props => {
    const { success, error, warning } = props;

    return <div className="statusBar__container">
        <div className="statusBar expand tooltip tooltip-bottom" style={{
            background: `linear-gradient(to right, #5ce5a1 0% ${success}%, #e5a15c ${success}% ${warning}%, #e55c5c ${warning}% ${error}%, #a15ce5 ${error}%)`
        }} data-tooltip-content="Avg status for this merged group" />
    </div>;
};

export default CardGroupStatusBar;