import React, { useContext, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import Column from './Column';
import alertify from 'alertifyjs';
import { POST } from '../../services/HTTPHandler';
import { getFormattedName } from '../../utils/user';
import { RetroControllerContext } from '../../contexts/RetroControllerContext';

import '../../styles/comments.css';
import 'alertifyjs/build/css/alertify.css';

const Comments = (props) => {
    const {
        teamID,
        boardID,
        members,
        retroStep,
        isLastStep,
        totalVotes,
        handleSubmit,
        columns = [],
        setTotalVotes,
        maxVoteReached,
        setMaxVoteReached,
        setVotingReEnabled,
    } = props;

    const [sourceCardAttr, setSourceCardAttr] = useState({});
    const [sourceMoveType, setSourceMoveType] = useState('');
    const { votesPerUser } = useContext(RetroControllerContext);
    const [sourceCardSaved, setSourceCardSaved] = useState(false);

    const isDropable = DOM => {
        return !Array.from(DOM.classList).includes('cards') &&
            (((Array.from(DOM.classList).includes('card') || Array.from(DOM.classList).includes('card__author-section')) &&
                sourceCardAttr.cardKey !== get(DOM, ['attributes', 'cardKey', 'value'], '')) ||
                (Array.from(DOM.classList).includes('merged-cards__container') &&
                    sourceCardAttr.baseKey !== get(DOM, ['children', '0', 'attributes', 'baseKey', 'value'], '')));
    };

    const validateVotes = (total, card) =>
        ((total >= 0 && total <= votesPerUser) && (card >= 0 && card <= votesPerUser));

    const updateTotalVotes = ({ action, boardID, cardKey, colIndex, cardVoteCount }) => {
        if (totalVotes <= votesPerUser || action === 'remove') {
            const totalVoteCount = action === 'add' ? totalVotes + 1 : totalVotes - 1;
            if (validateVotes(totalVoteCount, cardVoteCount) || action === 'remove') {
                POST('/activity/vote', { cardKey, boardID, colIndex, totalVoteCount, cardVoteCount, action, teamID })
                    .then(() => {
                        setTotalVotes(totalVoteCount);
                        if (totalVoteCount === votesPerUser) {
                            setMaxVoteReached(true);
                            alertify.notify('Voting Completed', 'success', 3);
                        } else if (totalVoteCount < votesPerUser) {
                            setMaxVoteReached(false);
                            setVotingReEnabled(true);
                        }
                    })
                    .catch(err => alertify.notify('Failed to update Vote', 'error', 3, () => console.log(err.message)));
            }
        }
    };

    const typeOfMove = DOMnode => {
        if (Array.from(DOMnode.classList).includes('merged-cards'))
            return 'MERGED_CARD';
        else if (Array.from(DOMnode.classList).includes('merged-cards__container'))
            return 'MERGED_CARD_CONTAINER';
        else if (Array.from(DOMnode.classList).includes('card'))
            return 'SINGLE';
    };

    const onDragStart = (event, card) => {
        // Look into DataTransfer event objects for source card data
        if (!sourceCardSaved) {
            setSourceMoveType(typeOfMove(event.target));
            setSourceCardAttr(card);
            setSourceCardSaved(true);
        }
    };

    const onDragEnd = (event) => {
        event.target.classList.remove('dragging');
        event.target.classList.remove('invalid-dragging');
        setSourceMoveType(null);
        setSourceCardAttr(null);
        setSourceCardSaved(false);
    };

    const onDragEnter = (event) => {
        const targetNode = event.target;
        if (targetNode.attributes.cardKey && sourceCardAttr.cardKey === targetNode.attributes.cardKey.value) {
            // Source card same as Target drop - No position change
            targetNode.classList.add('invalid-dragging')
        } else if (Array.from(targetNode.classList).includes('merged-cards__section')){
            // Target node is grouped merge cards section (group header and cards)
            targetNode.classList.add('dragging');
        } else if (Array.from(targetNode.classList).includes('merged-cards__container') || 
            Array.from(targetNode.classList).includes('merged-cards__header')){
                // Target node is grouped cards container within the merged section
                targetNode.parentNode.classList.add('dragging');
        } else if (Array.from(targetNode.classList).includes('merged-cards')) {
            // Target node is a merged card
            targetNode.parentNode.parentNode.classList.add('dragging');
        }

        targetNode.classList.add('dragging');
    };

    const onDragLeave = (event) => {
        const targetNode = event.target;
        if (targetNode.attributes.cardKey && sourceCardAttr.cardKey === targetNode.attributes.cardKey.value) {
            // Source card same as Target drop - No position change
            targetNode.classList.remove('invalid-dragging');
        } else if (Array.from(targetNode.classList).includes('merged-cards__section')) {
            // Target node is grouped merge cards section (group header and cards)
            targetNode.classList.remove('dragging');
        } else if (Array.from(targetNode.classList).includes('merged-cards__container') ||
            Array.from(targetNode.classList).includes('merged-cards__header')) {
                // Target node is grouped cards container within the merged section
                targetNode.parentNode.classList.remove('dragging');
        } else if (Array.from(targetNode.classList).includes('merged-cards')) {
            // Target node is a merged card
            targetNode.parentNode.parentNode.classList.remove('dragging');
        }
        
        targetNode.classList.remove('dragging');
    };

    const getTargetCardValuesFromEvent = (target, options) => {
        let targetCardAttr;
        if (options.fromCard)
            targetCardAttr = get(target, ['attributes'], {});
        else if (options.fromContainer)
            targetCardAttr = get(target, ['children', '0', 'attributes'], {});
        return {
            owner: get(targetCardAttr, ['owner', 'value'], ''),
            baseKey: get(targetCardAttr, ['basekey', 'value'], ''),
            content: get(targetCardAttr, ['content', 'value'], ''),
            cardKey: get(targetCardAttr, ['cardkey', 'value'], ''),
            colIndex: get(targetCardAttr, ['colindex', 'value'], ''),
            fromColumn: get(targetCardAttr, ['colindex', 'value'], ''),
            cardTheme: get(targetCardAttr, ['cardTheme', 'value'], ''),
        };
    };

    const onDrop = event => {
        try {
            if (isDropable(event.target)) {
                let targetDOM = event.target;
                targetDOM.classList.remove('dragging');
                if (Array.from(event.target.classList).includes('merged-cards')){
                    targetDOM = event.target.parentNode.parentNode;
                    targetDOM.classList.remove('dragging');
                }
                const targetMoveType = typeOfMove(targetDOM);
                const targetFromCard = getTargetCardValuesFromEvent(targetDOM, { fromCard: true });
                const targetFromContainer = getTargetCardValuesFromEvent(targetDOM, { fromContainer: true });
                POST('/activity/mergeCards', { boardID, targetMoveType, sourceMoveType, sourceCardAttr, targetFromCard, targetFromContainer })
                    .then(() => onDragEnd(event));
            } else {
                onDragEnd(event);
            }
        } catch (err) {
            console.log('Error: ', err);
        }
    };

    const getMembersDropdown = members => map(members, member => ({ value: member.uid, label: getFormattedName(member.displayName) }));

    return (
        <>
            <div className="column-container" data-hide={isLastStep} aria-hidden={isLastStep} data-fade-in>
                {map(columns, (column, i) => {
                    return (<Column
                        key={i}
                        index={i}
                        teamID={teamID}
                        onDrop={onDrop}
                        boardID={boardID}
                        members={members}
                        cards={column.cards}
                        retroStep={retroStep}
                        onDragEnd={onDragEnd}
                        onDragStart={onDragStart}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        handleSubmit={handleSubmit}
                        draggable={retroStep === 1}
                        votingMode={retroStep === 2}
                        disableInput={retroStep !== 0}
                        maxVoteReached={maxVoteReached}
                        aria-label='Enter card content'
                        updateTotalVotes={updateTotalVotes}
                        membersOption={getMembersDropdown(members)}
                        columnTitle={get(column, 'columnData.columnTitle', '')}
                        columnTheme={get(column, 'columnData.columnTheme', '')}
                        columnDescription={get(column, 'columnData.columnDescription', '')}
                        isAppreciationColumn={get(column, 'columnData.columnTitle', '') === 'Appreciations'}
                    />);
                })}
            </div>
        </>
    );
};

export default Comments;